// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbox_container__IXfeL {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.8rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: text;
    color: #6B768C;
    overflow-x: hidden;
}

.searchbox_input__vWyDp {
    outline: none;
    border: none;
    font-size: inherit;
    background-color: transparent;
    cursor: text;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    padding: 10px;
    color: #000;
}

.searchbox_searchIcon__xWamr {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/ajonjolib/inputs/searchbox/searchbox.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    position: relative;\n    user-select: none;\n    font-weight: 500;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-right: 0.8rem;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background-color: #fff;\n    cursor: text;\n    color: #6B768C;\n    overflow-x: hidden;\n}\n\n.input {\n    outline: none;\n    border: none;\n    font-size: inherit;\n    background-color: transparent;\n    cursor: text;\n    white-space: nowrap;\n    width: 100%;\n    text-align: left;\n    padding: 10px;\n    color: #000;\n}\n\n.searchIcon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `searchbox_container__IXfeL`,
	"input": `searchbox_input__vWyDp`,
	"searchIcon": `searchbox_searchIcon__xWamr`
};
export default ___CSS_LOADER_EXPORT___;
