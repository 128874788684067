// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer {
    width: calc(100% - var(--sidebar-width));
    background-color: #f7f8fa;
    border-radius: 40px 0 0 40px;
    position: absolute;
    right: 0;
    padding: 150px 46px;
    transition: width 0.3s ease-in-out;
    /*overflow-y: scroll;*/
}`, "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,yBAAyB;IACzB,4BAA4B;IAC5B,kBAAkB;IAClB,QAAQ;IACR,mBAAmB;IACnB,kCAAkC;IAClC,sBAAsB;AAC1B","sourcesContent":[".mainContainer {\n    width: calc(100% - var(--sidebar-width));\n    background-color: #f7f8fa;\n    border-radius: 40px 0 0 40px;\n    position: absolute;\n    right: 0;\n    padding: 150px 46px;\n    transition: width 0.3s ease-in-out;\n    /*overflow-y: scroll;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
