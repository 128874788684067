import React from "react";
import styles from './modal_password.module.css';

export default function ModalPassword({mail, children, close}) {
    return (
        <div className={styles.container}>
            <div className={styles.backdrop} onClick={close}/>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <div>Restablecer la contraseña</div>
                    </div>
                    <div>
                        <img src={'/branding/tracks_dark.png'} alt={'tracks'} height={'106px'}/>
                    </div>
                </div>
                <div style={{
                    color: '#FFFFFF',
                    fontWeight: '600',
                    fontSize: '15px',
                    padding: '24px 32px',
                    textAlign: 'left'
                }}>
                    <div>Se enviará un enlace para el reestablecimiento de contraseña, para que el usuario con correo   <span className={styles.mail}>{mail}</span>   pueda volver a ingresar a su cuenta.</div>
                    {children}
                </div>
            </div>
        </div>
    );
}