import React, {useEffect, useState, useContext} from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import styles from "../projects/projects.module.css";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faUserLock} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/modal/modal";
import {Select, Text} from "../../../ajonjolib/inputs/ajonjolinput";
import Switch from "../../../ajonjolib/inputs/switch/switch";
import ModalBottom from "../../../components/modal_bottom/modal_bottom";
import {axiosInstance} from "../../../AxiosInstance";
import {toast, ToastTypes} from "../../../ajonjolib/toasts/toast/toast";
import { ScreenType, SettingsScreenType } from "../../../ScreenType";

export default function Enterprises() {
    const [showModal, setShowModal] = useState(false);
    const [create, setCreate] = useState(true);
    const [form, setForm] = useState({});
    const [projects, setProjects] = useState([]);
    
    const {roleManager} = useContext(RosterContext);

    const shouldShowActions = roleManager.getSettingsEnterprisesPermission() == PermissionType.WRITE;

    const cols = [
        {title: 'Estado', internal: 'active', code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }}/>
                )
            }},
        {title: 'Empresa', internal: 'name'},
        {title: 'RUC', internal: 'ruc'},
        //Change for address
        {title: 'Contacto', internal: 'contact'},
        {title: 'Correo', internal: 'email'},
    ]

    if (shouldShowActions) {
        cols.push(
            {title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-start'}>
                        <FontAwesomeIcon color={'#6A86C8'} icon={faPenToSquare} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                            let copy = row;
                            setShowModal(true);
                            setCreate(false);
                            setForm(copy);
                        }}/>
                    </div>
                )
            }}
        )
    }

    const submit = () => {
        const handleResponse = (response) => {
            console.log(response);
            if (response.response) {
                response = response.response;
            }
            if (response.status == 400) {
                console.log(response.data);
                let message = 'Ocurrió un error';
                if (response.data.non_field_errors?.length > 0) {
                    message = response.data.non_field_errors[0];
                }
                toast(message, ToastTypes.ERROR);
            } else {
                setShowModal(false);
                window.location.reload();
            }
        };
    
        const saveEnterprise = (form, create) => {
            const request = create ? axiosInstance.post('enterprise/', form) : axiosInstance.patch(`enterprise/${form.id}/`, form);
            request.then(handleResponse).catch(handleResponse);
        };
    
        if (!form['name'] || !form['ruc'] || !form['status']) {
            toast('Por favor, complete todos los campos obligatorios', ToastTypes.ERROR);
            return;
        } else {
            saveEnterprise(form, create);
        }
    };

    return (
        <div>
            <Sidebar selected={ScreenType.SETTINGS} selectedSubmenu={SettingsScreenType.ENTERPRISES}/>

            {showModal &&
                <Modal title={'Empresas'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowModal(false)}>
                    <div style={{textAlign: 'left'}}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Nombre de Empresa*</div>
                                <Text value={form['name']} onChange={(val) => setForm({...form, name: val})}/>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Estado</div>
                                <Switch value={form['status']} onChange={(value) => {
                                    setForm({...form, status: value});
                                }}/>
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>RUC*</div>
                                <Text disabled={!create} value={form['ruc']} onChange={(val) => setForm({...form, ruc: val})}/>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Contacto*</div>
                                <Text value={form['contact']} onChange={(val) => setForm({...form, contact: val})}/>
                            </div>
                        </div>
                        
                        <div className={'d-flex justify-content-between mb-3'}>
                        <div style={{flexBasis: '100%'}}>
                                <div className={styles.requiredInput}>Email*</div>
                                <Text value={form['email']} onChange={(val) => setForm({...form, email: val})}/>
                        </div>
                        </div>

                        <ModalBottom submit={submit}/>
                    </div>
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Empresas'} menu={'Configuración'}/>
                <Table model={'enterprise'} cols={cols} withIndex={true} create={() => {
                    setShowModal(true);
                    setCreate(true);
                    setForm({});
                }}
                    shouldShowActions={shouldShowActions}
                />
            </div>
        </div>
    )
}