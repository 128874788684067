// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout_button_container__kOprs {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #1A2A4D 31.35%, #375395 133.07%);
}

.logout_button_loginContainer__2jSwN {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 40px 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logout_button_inputField__islCg {
    width: 370px;
    margin-bottom: 2rem;
}

.logout_button_inputField__islCg input {
    width: 100%;
    background-color: #F4F4F4;
    border: #E6E6E6 1px solid;
    height: 43px;
    font-size: 0.8rem;
    padding-left: 0.8rem;
}

.logout_button_inputField__islCg input:focus {
    width: 100%;
    background-color: #F4F4F4;
    border: #E6E6E6 1px solid;
    height: 43px;
    outline: none !important;
}

.logout_button_loginButton__eNn7Z {
    background-color: #F32735;
    color: #FFFFFF;
    font-size: 13px;
    padding: 18px 75px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 15%) 0 11px 11px 0;
    margin-bottom: 20px;
    cursor: pointer;
}

.logout_button_forgotPassword__Y4jXi {
    font-size: 12px;
    color: #F32735;
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/logout_button/logout_button.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oEAAoE;AACxE;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,yBAAyB;IACzB,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,4CAA4C;IAC5C,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(180deg, #1A2A4D 31.35%, #375395 133.07%);\n}\n\n.loginContainer {\n    height: 100%;\n    width: 50%;\n    position: absolute;\n    top: 0;\n    right: 0;\n    background-color: #FFFFFF;\n    border-radius: 40px 0 0 40px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.inputField {\n    width: 370px;\n    margin-bottom: 2rem;\n}\n\n.inputField input {\n    width: 100%;\n    background-color: #F4F4F4;\n    border: #E6E6E6 1px solid;\n    height: 43px;\n    font-size: 0.8rem;\n    padding-left: 0.8rem;\n}\n\n.inputField input:focus {\n    width: 100%;\n    background-color: #F4F4F4;\n    border: #E6E6E6 1px solid;\n    height: 43px;\n    outline: none !important;\n}\n\n.loginButton {\n    background-color: #F32735;\n    color: #FFFFFF;\n    font-size: 13px;\n    padding: 18px 75px;\n    border-radius: 8px;\n    box-shadow: rgba(0, 0, 0, 15%) 0 11px 11px 0;\n    margin-bottom: 20px;\n    cursor: pointer;\n}\n\n.forgotPassword {\n    font-size: 12px;\n    color: #F32735;\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `logout_button_container__kOprs`,
	"loginContainer": `logout_button_loginContainer__2jSwN`,
	"inputField": `logout_button_inputField__islCg`,
	"loginButton": `logout_button_loginButton__eNn7Z`,
	"forgotPassword": `logout_button_forgotPassword__Y4jXi`
};
export default ___CSS_LOADER_EXPORT___;
