// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file_container__XMwkr {

}

.file_placeholder__WhXtK {
    background-color: #FFFFFF;
    border: #D6E0F4 1px solid;
    border-radius: 10px;
    display: flex;
    -webkit-user-select: none;
            user-select: none;
    color: #D6E0F4;
    align-items: center;
    justify-content: space-around;
    padding: 30px 10px;
}

.file_button__rS\\+0c {
    cursor: pointer;
    background-color: #D6E0F4;
    color: #666666;
    border: none;
    border-radius: 9px;
    padding: 10px 30px;
    font-size: 11px;
}

.file_file__JUco2 {
    -webkit-user-select: none;
            user-select: none;
    background-color: #D6E0F4;
    border: none;
    color: #666666;
    font-size: 11px;
    display: flex;
    align-items: center;
    border-radius: 9px;
    padding: 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/ajonjolib/inputs/file/file.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,yBAAiB;YAAjB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".container {\n\n}\n\n.placeholder {\n    background-color: #FFFFFF;\n    border: #D6E0F4 1px solid;\n    border-radius: 10px;\n    display: flex;\n    user-select: none;\n    color: #D6E0F4;\n    align-items: center;\n    justify-content: space-around;\n    padding: 30px 10px;\n}\n\n.button {\n    cursor: pointer;\n    background-color: #D6E0F4;\n    color: #666666;\n    border: none;\n    border-radius: 9px;\n    padding: 10px 30px;\n    font-size: 11px;\n}\n\n.file {\n    user-select: none;\n    background-color: #D6E0F4;\n    border: none;\n    color: #666666;\n    font-size: 11px;\n    display: flex;\n    align-items: center;\n    border-radius: 9px;\n    padding: 10px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `file_container__XMwkr`,
	"placeholder": `file_placeholder__WhXtK`,
	"button": `file_button__rS+0c`,
	"file": `file_file__JUco2`
};
export default ___CSS_LOADER_EXPORT___;
