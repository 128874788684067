import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import ToastContainer from "./ajonjolib/toasts/toast/toast_container";
import SidebarButton from "./components/sidebar_button/sidebar_button";
import React from "react";
import { ContextProvider } from "./RosterContext";
import { FullPageLoaderProvider } from './contexts/fullpage_loader_context';

function App() {
    return (
        <div className="App">
            <FullPageLoaderProvider>
                <ContextProvider>
                    <Router>
                        <RouteContainer />
                    </Router>
                    <ToastContainer />
                    <SidebarButton />
                </ContextProvider>
            </FullPageLoaderProvider>
        </div>
    );
}

export default App;
