import { Routes, Route, Navigate } from "react-router-dom";
import {useEffect, useContext } from "react";
import Login from "./screens/login/login";
import Home from "./screens/home/home";
import RosterContext from "./RosterContext";
import Projects from "./screens/settings/projects/projects";
import Users from "./screens/users/users";
import Roles from "./screens/settings/roles/roles";
import Roster from "./screens/roster/roster";
import ChangePasswordMail from "./screens/change_password/mail";
import ChangePasswordPassword from "./screens/change_password/password";
import ChangePasswordSuccess from "./screens/change_password/success";
import UsersAndPermissions from "./screens/settings/users_and_permissions/users_and_permissions";
import Balances from "./screens/settings/balances/balances";
import Guards from "./screens/settings/guards/guards";
import Fronts from "./screens/settings/fronts/fronts";
import BoardingZones from "./screens/settings/boarding_zones/boarding_zones";
import Enterprises from "./screens/settings/enterprises/enterprises";
import Notifications from "./screens/settings/notifications/notifications";
import { PermissionType } from "./PermissionType";

function LoginRedirect() {
    useEffect(() => {
        window.location.replace('/login');
    }, []);
    return (<div></div>);
}
function ProtectedRouteWrapper({ element: Component, permissionCheck, ...rest }) {
    const { roleManager } = useContext(RosterContext);

    return permissionCheck(roleManager) ? <Component {...rest} /> : <Navigate to="/login" />;
}

const hasDashboardPermission = (roleManager) => roleManager.getDashboardPermission() !== PermissionType.NOT_ALLOWED;
const hasUsersPermission = (roleManager) => roleManager.getUsersPermission() !== PermissionType.NOT_ALLOWED;
const hasRosterPermission = (roleManager) => roleManager.getRosterPermission() !== PermissionType.NOT_ALLOWED;
const hasRosterApprovePermission = (roleManager) => roleManager.getRosterApprovePermission() !== PermissionType.NOT_ALLOWED;
const hasRosterBalancesPermission = (roleManager) => roleManager.getRosterBalancesPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsRosterDateLimitPermission = (roleManager) => roleManager.getSettingsRosterDateLimitPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsGuardsPermission = (roleManager) => roleManager.getSettingsGuardsPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsFrontsPermission = (roleManager) => roleManager.getSettingsFrontsPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsBoardingZonesPermission = (roleManager) => roleManager.getSettingsBoardingZonesPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsEnterprisesPermission = (roleManager) => roleManager.getSettingsEnterprisesPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsRolesPermission = (roleManager) => roleManager.getSettingsRolesPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsUsersRolesPermission = (roleManager) => roleManager.getSettingsUsersRolesPermission() !== PermissionType.NOT_ALLOWED;
const hasSettingsNotificationsPermission = (roleManager) => roleManager.getSettingsNotificationsPermission() !== PermissionType.NOT_ALLOWED;

export default function RouteContainer() {
    let authenticated = localStorage.getItem('access_token') !== null;

    return (
        <Routes>
            {authenticated && (
                <>
                    <Route path="/" element={<ProtectedRouteWrapper element={Home} permissionCheck={hasDashboardPermission} />} />
                    <Route path="/roster" element={<ProtectedRouteWrapper element={Roster} permissionCheck={hasRosterPermission} />} />
                    <Route path="/roster/approve" element={<ProtectedRouteWrapper element={Roster} permissionCheck={hasRosterApprovePermission} />} />
                    <Route path="/settings/users" element={<ProtectedRouteWrapper element={Users} permissionCheck={hasUsersPermission} />} />
                    <Route path="/settings/users-and-permissions" element={<ProtectedRouteWrapper element={UsersAndPermissions} permissionCheck={hasSettingsUsersRolesPermission} />} />
                    <Route path="/settings/roles" element={<ProtectedRouteWrapper element={Roles} permissionCheck={hasSettingsRolesPermission} />} />
                    <Route path="/settings/projects" element={<ProtectedRouteWrapper element={Projects} permissionCheck={hasSettingsRosterDateLimitPermission} />} />
                    <Route path="/settings/balances" element={<ProtectedRouteWrapper element={Balances} permissionCheck={hasRosterBalancesPermission} />} />
                    <Route path="/settings/guards" element={<ProtectedRouteWrapper element={Guards} permissionCheck={hasSettingsGuardsPermission} />} />
                    <Route path="/settings/enterprises" element={<ProtectedRouteWrapper element={Enterprises} permissionCheck={hasSettingsEnterprisesPermission} />} />
                    <Route path="/settings/fronts" element={<ProtectedRouteWrapper element={Fronts} permissionCheck={hasSettingsFrontsPermission} />} />
                    <Route path="/settings/boarding-zones" element={<ProtectedRouteWrapper element={BoardingZones} permissionCheck={hasSettingsBoardingZonesPermission} />} />
                    <Route path="/settings/notifications" element={<ProtectedRouteWrapper element={Notifications} permissionCheck={hasSettingsNotificationsPermission} />} />
                </>
            )}

            <Route path="/login" element={<Login />} />
            <Route path="/password_reset/mail" element={<ChangePasswordMail />} />
            <Route path="/password_reset/:uidb64/:token" element={<ChangePasswordPassword />} />
            <Route path="/password_reset/success" element={<ChangePasswordSuccess />} />
            <Route path="*" element={<LoginRedirect />} />
        </Routes>
    );
}