// Histogram.js
import React, { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

// Registrando los componentes necesarios
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Histogram = ({labels, dataHiredStaff, dataOnSiteStaff}) => {
  const createDatasetConfig = ({label, backgroundColor = '', borderColor = 'rgba(188, 209, 63, 1)', data = []}) => {
    return {
      label,
      backgroundColor,
      borderColor,
      data,
      fill: true,
      tension: 0.4,
    }
  }
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels,
    datasets: [
      createDatasetConfig({ label: 'Personal Contratado' }),
      createDatasetConfig({ label: 'Personal On-Site', borderColor: 'rgba(106, 134, 200, 1)' })
    ]
  });

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradientGreen = ctx.createLinearGradient(0, 0, 0, Math.round(chart.height * 0.6));
      gradientGreen.addColorStop(0, 'rgba(188, 209, 63, 0.7)');
      gradientGreen.addColorStop(1, 'rgba(188, 209, 63, 0)');

      const gradientBlue = ctx.createLinearGradient(0, 0, 0, Math.round(chart.height * 0.8));
      gradientBlue.addColorStop(0, 'rgba(106, 134, 200, 0.7)');
      gradientBlue.addColorStop(1, 'rgba(106, 134, 200, 0)');

      setChartData({
        labels,
        datasets: [
          createDatasetConfig({label: 'Personal Contratado', data: dataHiredStaff, backgroundColor: gradientGreen}),
          createDatasetConfig({label: 'Personal On-Site', data: dataOnSiteStaff, backgroundColor: gradientBlue, borderColor: 'rgba(106, 134, 200, 1)'}),
        ],
      });
    }
  }, [labels, dataHiredStaff, dataOnSiteStaff, chartRef]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Fecha',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Cantidad',
        },
        suggestedMin: 0,
        suggestedMax: 4,
      },
    },
  };

  return (
    <div style={{ width: '100%', height: 'auto'}}>
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default Histogram;
