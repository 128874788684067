// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change_password_container__upzFy {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #1A2A4D 31.35%, #375395 133.07%);
    font-family: "Lexend Variable", sans-serif;
    position: relative;
}

.change_password_innerContainer__n5eez {
    padding-top: 200px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    flex-direction: row;
}

.change_password_straconLogo__hhRHK {
    position: absolute;
    top: 45px;
    left: 45px;
    display: flex;
    justify-content: start;
}`, "",{"version":3,"sources":["webpack://./src/screens/change_password/change_password.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oEAAoE;IACpE,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;IAC9B,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(180deg, #1A2A4D 31.35%, #375395 133.07%);\n    font-family: \"Lexend Variable\", sans-serif;\n    position: relative;\n}\n\n.innerContainer {\n    padding-top: 200px;\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n    color: #FFFFFF;\n    font-size: 36px;\n    font-weight: 700;\n    line-height: 38px;\n    flex-direction: row;\n}\n\n.straconLogo {\n    position: absolute;\n    top: 45px;\n    left: 45px;\n    display: flex;\n    justify-content: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `change_password_container__upzFy`,
	"innerContainer": `change_password_innerContainer__n5eez`,
	"straconLogo": `change_password_straconLogo__hhRHK`
};
export default ___CSS_LOADER_EXPORT___;
