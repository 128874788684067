import React from "react";
import styles from './upload.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUpload, faFileExcel} from "@fortawesome/free-solid-svg-icons";

export default function UserPermissionsUpload({setShowExcelModal, setShowCloudModal, shouldShowActions}) {
    return (
        <div className="d-flex flex-row-reverse" style={{ marginRight: "50px" }}>
            <div className={styles.sleeveUp} style={{backgroundColor: '#217345'}} onClick={() => {
                setShowExcelModal(true);
            }}>
                <p>Exportar</p>
            </div>
            {
                shouldShowActions &&
                <div className={styles.sleeveUp} style={{backgroundColor: '#62A0CC'}} onClick={() => {
                    setShowCloudModal(true);
                }}>
                    <p>Importar</p>
                </div>
            }
        </div>
    )
}