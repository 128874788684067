const stats = [
    {name: "Trabajo de día", slug: "D", color: "#239290"},
    {name: "Trabajo de noche", slug: "N", color: "#FFAA00"},
    {name: "Trabajo de día feriado", slug: "DF", color: "#512DA8"},
    {name: "Trabajo de noche feriado", slug: "NF", color: "#0000FF"},
    {name: "Inducción / Capacitación", slug: "IN", color: "#7F6000"},
    {name: "Trabajo subida", slug: "S", color: "#274E13"},
    {name: "Bajada día", slug: "BD", color: "#741B47"},
    {name: "Bajada noche", slug: "BN", color: "#FF9333"},
    {name: "Cuarentena en proyecto", slug: "QP", color: "#AEABAB"},
    {name: "Cuarentena fuera de proyecto", slug: "QF", color: "#CCCCCC"},
    {name: "Comisión de trabajo", slug: "CO", color: "#1C4587"},
    {name: "Días libres", slug: "L", color: "#7CADED"},
    {name: "Días libres subida", slug: "LS", color: "#FF0000"},
    {name: "Días libres bajada", slug: "LB", color: "#FF00FF"},
    {name: "Licencia con goce", slug: "LCG", color: "#009900"},
    {name: "Licencia por fallecimiento", slug: "LF", color: "#FFE598"},
    {name: "Licencia por maternidad", slug: "LM", color: "#CC0099"},
    {name: "Licencia por paternidad", slug: "LP", color: "#990099"},
    {name: "Licencia sin goce", slug: "LSG", color: "#00B050"},
    {name: "Licencia Sindical", slug: "LSI", color: "#B7E1CD"},
    {name: "Vacaciones", slug: "V", color: "#333333"},
    {name: "Home office", slug: "HO", color: "#6D4C41"},
    {name: "Home office en feriado", slug: "HOF", color: "#E22146"},
    {name: "Días libres home office", slug: "HOL", color: "#096CDD"},
    {name: "Descanso médico en proyecto", slug: "DMP", color: "#AAD08E"},
    {name: "Descanso médico fuera de proyecto", slug: "DMF", color: "#980000"},
    {name: "Subsidio", slug: "SUB", color: "#FFFF00"},
    {name: "Suspensión perfecta de labores", slug: "SPL", color: "#7030A0"},
    {name: "Suspensión", slug: "SUS", color: "#000000"},
    {name: "Falta", slug: "F", color: "#CCECFF"},
    {name: "Cesado", slug: "C", color: "#CC3300"},
]

export default stats;