import React, {useEffect, useState} from "react";
import styles from "../../../roster/roster.module.css";
import {axiosInstance} from "../../../../AxiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSave} from "@fortawesome/free-solid-svg-icons";
import {Button, File} from "../../../../ajonjolib/inputs/ajonjolinput";
import {eventBus, toast, ToastTypes} from "../../../../ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/modal/modal";

export default function ExcelModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});

    useEffect(() => {
        eventBus.subscribe('open_balance_excel', (data) => {
            setIsOpen(true);
        });
        eventBus.subscribe('close_balance_excel', (data) => {setIsOpen(false)});
    }, []);

    return (
        <>{isOpen &&
            <Modal title={'Importar'} subtitle={'Balances y Saldos'} close={() => setIsOpen(false)}>
                <div>
                    <div className={'d-flex justify-content-between'}>
                        <div style={{textAlign: 'left', flexBasis: '45%'}}>
                            <div style={{fontSize: '13px', color: '#666666'}}>Descargar Plantilla</div>
                            <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                La plantilla a descargar considera los usuarios seleccionados en la pantalla principal de Programaciones y Saldos
                            </div>
                        </div>
                        <div style={{flexBasis: '45%'}}>
                            <div className={styles.templateButton} onClick={() => {
                                axiosInstance.get(`days_off/`, {
                                    responseType: 'blob'
                                }).then((response) => {
                                    if(response.status === 200) {
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'plantilla_saldos.xlsx');
                                        document.body.appendChild(link);
                                        link.click();
                                    }
                                });
                            }}>
                                <FontAwesomeIcon className={'pe-2'} icon={faFileExcel} color={'#FFF'} size={'lg'}/>
                                <div>Descargar Plantilla</div>
                            </div>
                        </div>
                    </div>
                    <div className={'mt-3'}/>
                    <div style={{fontSize: '11px', color: '#666666', textAlign: 'left'}}>Subir Saldos</div>
                    <File onChange={(files) => {
                        setForm({...form, file: files[0]});
                    }}/>
                    <div className={'d-flex mt-3'} style={{justifyContent: 'flex-end'}}>
                        <Button variant={'success'} onSubmit={() => {
                            let formData = new FormData();
                            formData.append('file', form['file'])
                            axiosInstance.post('days_off/', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then((response) => {
                                setIsOpen(false);
                                if(response.status === 200) {
                                    toast('Saldos subidos exitosamente', ToastTypes.SUCCESS);
                                    window.location.reload();
                                } else {
                                    toast('No se ha procesado los registros, recuerde respetar todos los campos obligatorios (*)', ToastTypes.ERROR);
                                    console.log('User:import:error', response.data);
                                }
                            });
                        }}>
                            <div className={'d-flex align-items-center'}>
                                <FontAwesomeIcon className={'pe-2'} icon={faSave} color={'#FFF'}/>
                                <div>Guardar</div>
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal>
        }</>
    );
}