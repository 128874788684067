const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

const parseDateFromString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
};

const formatDateToFiles = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-indexados
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}_${month}_${day}_${hours}${minutes}${seconds}`;
}

export { formatDate, parseDateFromString, formatDateToFiles };
