import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrando los componentes necesarios
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HorizontalBar = ({ label, labels, data, backgroundColor, borderColor, direction = 'ltr' }) => {
  const chartData = {
    labels: direction === 'rtl' ? labels?.reverse() : labels, // Invertir las etiquetas si es 'rtl'
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Fuera de Régimen',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        reverse: direction === 'rtl', // Invertir la dirección del eje x si direction es 'rtl'
      },
      y: {
        position: direction === 'rtl' ? 'right' : 'left', // Posicionar etiquetas del eje y al lado derecho si es 'rtl'
      },
    },
  };

  return (
    <div style={{ width: '100%', height: 'auto' }}>
      <Bar data={chartData} options={options} style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default HorizontalBar;
