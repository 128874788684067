import React, { createContext, useContext, useState } from "react";
import FullPageLoader from "../components/fullpage_loader/fullpage_loader";

const FullPageLoaderContext = createContext();

export const FullPageLoaderProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("Cargando, por favor espere...");

    const showFullPageLoader = (message) => {
        setMessage(message);
        setIsLoading(true);
    };

    const hideFullPageLoader = () => {
        setIsLoading(false);
    };

    return (
        <FullPageLoaderContext.Provider value={{ showFullPageLoader, hideFullPageLoader }}>
            {isLoading && <FullPageLoader message={message} />}
            {children}
        </FullPageLoaderContext.Provider>
    );
};

export const useFullPageLoader = () => useContext(FullPageLoaderContext);
