// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullpage_loader_fullpageLoader__u1v3Q {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.fullpage_loader_loaderContent__mJuIn {
    text-align: center;
}

.fullpage_loader_loaderLogo__Wy5Mz {
    height: 40px;
    margin-bottom: 20px;
    animation: fullpage_loader_pulse__yMb\\+Y 2s infinite;
}

.fullpage_loader_loaderMessage__XBZk2 {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    animation: fullpage_loader_pulse__yMb\\+Y 1s infinite;
}

@keyframes fullpage_loader_pulse__yMb\\+Y {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/fullpage_loader/fullpage_loader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,oDAA4B;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,oDAA4B;AAChC;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".fullpageLoader {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.8);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 9999;\n}\n\n.loaderContent {\n    text-align: center;\n}\n\n.loaderLogo {\n    height: 40px;\n    margin-bottom: 20px;\n    animation: pulse 2s infinite;\n}\n\n.loaderMessage {\n    font-size: 14px;\n    font-weight: 500;\n    color: #ffffff;\n    animation: pulse 1s infinite;\n}\n\n@keyframes pulse {\n    0% {\n        opacity: 0.5;\n    }\n    50% {\n        opacity: 1;\n    }\n    100% {\n        opacity: 0.5;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullpageLoader": `fullpage_loader_fullpageLoader__u1v3Q`,
	"loaderContent": `fullpage_loader_loaderContent__mJuIn`,
	"loaderLogo": `fullpage_loader_loaderLogo__Wy5Mz`,
	"pulse": `fullpage_loader_pulse__yMb+Y`,
	"loaderMessage": `fullpage_loader_loaderMessage__XBZk2`
};
export default ___CSS_LOADER_EXPORT___;
