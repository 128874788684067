import React from "react";
import styles from './button.module.css';

export default function Button({ onSubmit, disabled, className, style, children, variant }) {

    const getClass = () => {
        if (variant === 'secondary') {
            return styles.secondary;
        } else if (variant === 'success') {
            return styles.success;
        } else if (variant === 'danger') {
            return styles.danger;
        } else if (variant === 'warning') {
            return styles.warning;
        } else if (variant === 'info') {
            return styles.info;
        } else if (variant === 'light') {
            return styles.light;
        } else if (variant === 'dark') {
            return styles.dark;
        } else if (variant === 'link') {
            return styles.link;
        } else if (variant === 'roundedDanger') {
            return styles.roundedDanger;
        } else {
            return styles.primary;
        }
    }

    return (
        <div className={className} style={style} onClick={() => {
            if (!disabled) {
                if (onSubmit) onSubmit();
            }
        }}>
            <div className={`${styles.container} ${getClass()} ${disabled ? 'disabled' : ''}`} style={{
                cursor: disabled ? 'not-allowed' : 'pointer'
            }}>
                <div>{children}</div>
            </div>
        </div>
    )
}