import React, { useState } from 'react';

// const data = [
//   { color: '#3CB371', label: 'Trabajo de Día', value: 900 },
//   { color: '#FFD700', label: 'Trabajo de Noche', value: 900 },
//   { color: '#8B4513', label: 'Home Office', value: 900 },
//   { color: '#006400', label: 'Trabajo Subida', value: 900 },
//   { color: '#000080', label: 'Otros', value: 900, options: [100, 100, 100, 100, 100] },
// ];

const ListComponent = ({data = []}) => {
  const [showOptions, setShowOptions] = useState(false);

  if (!data || data.length === 0) return null;

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="table-container">
      <table>
        <tbody>
          {data.map((item, index) => (
            <>
              <tr key={index} className="table-row">
                <td className="color-cell">
                  <span className="color-dot" style={{ backgroundColor: item.color }}></span>
                </td>
                <td className="label-cell">
                  <span className="label">{item.label}</span>
                </td>
                <td className="value-cell">
                  <span className="value">{item.value}</span>
                </td>
                <td className="button-cell">
                  {item.options && (
                    <button className="toggle-button" onClick={handleToggleOptions}>
                      ▼
                    </button>
                  )}
                </td>
              </tr>
              {showOptions && item.options && item.options.map((option, idx) => (
                <tr key={`${index}-${idx}`} className="option-row">
                  <td className="color-cell"></td>
                  <td className="label-cell">Otros</td>
                  <td className="value-cell">{option}</td>
                  <td className="button-cell"></td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
      <style jsx>{`
        .table-container {
          width: 300px;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          padding: 10px;
          overflow: hidden;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        .table-row {
          border-bottom: 1px solid #e0e0e0;
        }
        .color-cell {
          width: 10%;
          text-align: center;
        }
        .label-cell {
          width: 55%;
          text-align: left;
          padding-left: 5px;
        }
        .value-cell {
          width: 25%;
          text-align: right;
          padding-right: 5px;
        }
        .button-cell {
          width: 10%;
          text-align: center;
        }
        .color-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
        }
        .toggle-button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 14px;
          padding: 0;
        }
        .option-row {
          background-color: #f9f9f9;
        }
        .option-row .label-cell {
          padding-left: 15px;
        }
      `}</style>
    </div>
  );
};

export default ListComponent;
