import React, { useState } from "react";
import styles from "./pagination.module.css";

export default function Pagination({ totalPages, onPageChange }) {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        onPageChange(newPage);
    };

    return (
        <div className={styles.paginationContainer}>
            <button 
                className={styles.paginationButton}
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
            >
                Anterior
            </button>
            <span className={styles.paginationInfo}>Página {currentPage} de {totalPages}</span>
            <button 
                className={styles.paginationButton} 
                onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} 
                disabled={currentPage === totalPages}
            >
                Siguiente
            </button>
        </div>
    );
}