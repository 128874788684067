import React, { useRef, useState } from "react";
import styles from './file.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faX } from "@fortawesome/free-solid-svg-icons";

export default function File({ onChange, value, accept, multiple, disabled, style, className, placeholder }) {
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        if (onChange) onChange(files);
    };

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        if (onChange) onChange(files);
    };

    return (
        <div
            className={styles.container}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <input
                type="file"
                value={value}
                accept={accept}
                multiple={multiple}
                disabled={disabled}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <div className={styles.placeholder}>
                {selectedFiles.length > 0 ?
                    (
                        <div className={`${styles.file}`}>
                            <div style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '300px'
                            }}>
                                {selectedFiles.map((file, index) => (
                                    <div key={index}>{file.name}</div>
                                ))}
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={() => {
                                setSelectedFiles([]);
                                if (onChange) onChange([]);
                            }}>
                                <FontAwesomeIcon className={'ps-2'} icon={faX} color={'#8FA4D6'} />
                            </div>
                        </div>
                    )
                    :
                    (
                        <div style={{
                            display: "flex",
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <div
                                className={styles.button}
                                onClick={handleFileClick}
                            >Examinar Equipo</div>
                            <div>o</div>
                            <div>
                                <FontAwesomeIcon icon={faCloudUpload} size={'3x'} color={'#D6E0F4'} />
                                <div style={{ color: '#8FA4D6', fontSize: '11px' }}>Arrastra tu archivo aquí</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
