import React, { useRef, useState, useEffect } from "react";
import styles from './modal.module.css';

export default function Modal({children, title, subtitle, close, customHeader}) {
    const modalRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if ( modalRef.current) setIsOverflowing(window.innerHeight < modalRef.current.clientHeight);
        }

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    const addStyleRepositionToTop = () => {
        return isOverflowing ? styles.repositionToTop : '';
    }

    useEffect(() => {
        const overflowValue = isOverflowing ? 'hidden' : 'auto';
        document.body.style.overflow = overflowValue;
        return () => document.body.style.overflow = 'auto';
    }, [isOverflowing]);

    return (
        <div className={styles.container}>
            <div className={styles.backdrop} onClick={close}/>
            <div ref={modalRef} className={`${styles.modal} ${addStyleRepositionToTop()}`}>
                {customHeader && customHeader}
                {!customHeader &&
                    <div className={styles.header}>
                        <div className={styles.title}>
                            <div style={{color: '#FFFFFF', fontSize: '18px', fontFamily: '500', lineHeight: '22px'}}>{title}</div>
                            <div style={{color: '#8FA4D6', fontSize: '15px', fontFamily: '400', lineHeight: '13px'}}>{subtitle}</div>
                        </div>
                        <div>
                            <img src={'/branding/tracks_dark_long.png'} alt={'tracks'} height={'69px'}/>
                        </div>
                    </div>
                }
                <div style={{
                    color: '#8B8B8B',
                    fontWeight: '400',
                    fontSize: '14px',
                    padding: '24px 32px',
                }}>
                    {children}
                </div>
            </div>
        </div>
    );
}