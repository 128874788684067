import React, {useState, useContext} from "react";
import styles from './login.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import {axiosInstance} from "../../AxiosInstance";
import { Toast } from "bootstrap";
import {toast, ToastTypes} from "../../ajonjolib/toasts/toast/toast";
import RoleManager from "../../RoleManager";
import RosterContext from "../../RosterContext";


export default function Login() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("");
    const { roleManager } = useContext(RosterContext);

    const submit = () => {
        // Validate username and password
        if(username === "" || password === "") {
            toast('Por favor, rellena todos los campos', ToastTypes.ERROR);
            return;
        }

        axiosInstance.post('token/', {
            username: username,
            password: password,
            client_device: 'web'
        }).then((response) => {
            if(response.status !== 200) {
                toast('No se ha podido iniciar sesión', ToastTypes.ERROR);
                return;
            };
            localStorage.clear();
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            localStorage.setItem('role',  JSON.stringify(response.data.roles));
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

            roleManager.roles = response.data.roles;

            const firstRoute = roleManager.determineAccesibleRoute();
            
            /*
            axiosInstance.post(`user/${response.data.id}/role/`, {
                role: 3
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            });
            */

            window.location.replace(firstRoute);
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.loginContainer}>
                <div style={{marginBottom: '5rem'}}>
                    <img src={'/branding/roster_logo.png'} alt={'logo'} width={'362px'}/>
                </div>

                <div className={styles.inputField}>
                    <div className={'d-flex align-items-center mb-2'}>
                        <FontAwesomeIcon className={'pe-2'} icon={faEnvelope} color={'#0B101E'}/>
                        <div style={{fontSize: '13px', color: '#0B101E'}}>E-mail</div>
                    </div>
                    <input value={username} onChange={(e) => {setUsername(e.target.value)}}/>
                </div>

                <div className={styles.inputField}>
                    <div className={'d-flex align-items-center mb-2'}>
                        <FontAwesomeIcon className={'pe-2'} icon={faLock} color={'#0B101E'}/>
                        <div style={{fontSize: '13px', color: '#0B101E'}}>Contraseña</div>
                    </div>
                    <input type={"password"} value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                </div>

                <div className={'mt-4'}>
                    <div className={styles.loginButton} onClick={() => submit()}>
                        <div>Login</div>
                    </div>

                    <div className={styles.forgotPassword} onClick={() => window.location.replace('/password_reset/mail')}>¿Olvidaste tu contraseña?</div>
                </div>
            </div>
        </div>
    );
}