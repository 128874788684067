// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll_top_container__DuSnB {
    position: fixed;
    z-index: 999999;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 390px;
    background-color: #F32735;
    border-radius: 9px 9px 0 0;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/screens/roster/components/scroll_top/scroll_top.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,YAAY;IACZ,yBAAyB;IACzB,0BAA0B;IAC1B,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".container {\n    position: fixed;\n    z-index: 999999;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    width: 390px;\n    background-color: #F32735;\n    border-radius: 9px 9px 0 0;\n    color: #FFFFFF;\n    font-size: 15px;\n    font-weight: 400;\n    cursor: pointer;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `scroll_top_container__DuSnB`
};
export default ___CSS_LOADER_EXPORT___;
