import React, {useState} from "react";
import styles from "./text.module.css";

export default function TextSearcher({ value, onChange, className, style, disabled, placeholder }) {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value);  // Usar estado local para manejar el valor del input

    // Manejador para el evento onKeyDown
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onChange(inputValue);  // Llama a onChange solo cuando se presiona Enter
        }
    }

    return (
        <div className={`${className} ${styles.container}`} style={{
            backgroundColor: disabled ? '#F1F1F1' : '',
        }}>
            <input
                style={{
                    color: disabled ? '#9B9B9B' : '#323232',
                    cursor: disabled ? 'not-allowed' : 'text',
                    ...style  // Asegúrate de propagar el objeto de estilo correctamente
                }}
                placeholder={placeholder}
                disabled={disabled}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}  // Actualiza el estado local, no el global
                onKeyDown={handleKeyDown}  // Añadir el manejo para cuando se presione Enter
            />
        </div>
    );
}
