// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_title_container__fclhG {
    font-family: 'Lexend Variable', sans-serif;
    text-align: left;
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    overflow-y: visible;
}

.page_title_title__N0Zqx {
    padding-left: 52px;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color: #1A2A4D;
}

.page_title_submenu__9fJze {
    font-size: 15px;
    color: #1A2A4D4A;
    font-weight: 600;
    padding-left: 6px;
    line-height: 20px;
}

.page_title_menu__0CSxz {
    padding-left: 52px;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    color: #BBBEC2;
}

.page_title_divider__r2Sqj {
    margin-top: 8px;
    width: 212px;
    height: 3px;
    background: linear-gradient(to right, #F32735 0%, #F7F8FA 100%);
}

`, "",{"version":3,"sources":["webpack://./src/components/page_title/page_title.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,gBAAgB;IAChB,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,+DAA+D;AACnE","sourcesContent":[".container {\n    font-family: 'Lexend Variable', sans-serif;\n    text-align: left;\n    position: absolute;\n    left: 0;\n    top: 40px;\n    width: 100%;\n    overflow-y: visible;\n}\n\n.title {\n    padding-left: 52px;\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 25px;\n    color: #1A2A4D;\n}\n\n.submenu {\n    font-size: 15px;\n    color: #1A2A4D4A;\n    font-weight: 600;\n    padding-left: 6px;\n    line-height: 20px;\n}\n\n.menu {\n    padding-left: 52px;\n    font-weight: 300;\n    font-size: 15px;\n    line-height: 19px;\n    color: #BBBEC2;\n}\n\n.divider {\n    margin-top: 8px;\n    width: 212px;\n    height: 3px;\n    background: linear-gradient(to right, #F32735 0%, #F7F8FA 100%);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `page_title_container__fclhG`,
	"title": `page_title_title__N0Zqx`,
	"submenu": `page_title_submenu__9fJze`,
	"menu": `page_title_menu__0CSxz`,
	"divider": `page_title_divider__r2Sqj`
};
export default ___CSS_LOADER_EXPORT___;
