import React from "react";
import styles from "../../screens/settings/projects/projects.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

export default function ModalBottomDelete({cancel, submit}) {
    return (
        <div className={'d-flex justify-content-between pt-3 align-items-center'}>
            <div style={{flexBasis: '45%'}} className={'d-flex justify-content-begin'}>
                <div onClick={cancel} className={'d-flex align-items-center text-center'} style={{
                    backgroundColor: '#E4E4E4',
                    color: '#000000',
                    borderRadius: '6px',
                    padding: '6px 10px',
                    userSelect: 'none',
                    cursor: 'pointer'
                }}>
                    <div style={{fontSize: '16px', fontWeight: '400'}}>Cancelar</div>
                </div>
            </div>
            <div style={{flexBasis: '45%'}} className={'d-flex justify-content-end'}>
                <div onClick={submit} className={'d-flex align-items-center text-center'} style={{
                    backgroundColor: '#E4E4E4',
                    color: '#000000',
                    borderRadius: '6px',
                    padding: '6px 10px',
                    userSelect: 'none',
                    cursor: 'pointer'
                }}>
                    <FontAwesomeIcon icon={faTrashCan} color={'#000000'} size={'lg'} className={'pe-2'}/>
                    <div style={{fontSize: '16px', fontWeight: '400'}}>Eliminar</div>
                </div>
            </div>
        </div>
    )
}