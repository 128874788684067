import React, {useEffect, useState} from "react";
import Modal from "../../../../components/modal/modal";
import {eventBus, toast, ToastTypes} from "../../../../ajonjolib/toasts/toast/toast";
import {Select} from "../../../../ajonjolib/inputs/ajonjolinput";
import event_types from "../../../../utils/event_types";
import ModalBottom from "../../../../components/modal_bottom/modal_bottom";
import styles from './create_event.module.css';
import {axiosInstance} from "../../../../AxiosInstance";

export default function CreateEventModal({query, triggerRefresh}) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [projectId, setProjectId] = useState(null);

    useEffect(() => {
        eventBus.subscribe('open_create_event', (data) => {
            setProjectId(data.project_id);
            setIsOpen(true);
            setForm({
                user_id: data.user_id,
                start_date: data.date,
                end_date: adjustEndDate(data.date)
            });
        });
        eventBus.subscribe('close_create_event', (data) => {setIsOpen(false)});
    }, []);

    const adjustEndDate = (startDateStr) => {
        let endDateStr = form['end_date'];
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        if (endDateStr === undefined || startDate > endDate) endDateStr = startDateStr;

        return endDateStr;
    }

    const handleStartDateChange = (event) => {
        const startDateStr = event.target.value;
        setForm({...form, start_date: startDateStr, end_date: adjustEndDate(startDateStr)});
    }

    const submit = () => {
        axiosInstance.post(`project/${projectId}/roster/batch/`, form).then((response) => {
            console.log("RESPONSE FOR INDIVIDUAL EVENT", response);
            if(response.status === 200) {
                toast('El roster está siendo procesado, por favor espere.', ToastTypes.SUCCESS);
                setIsOpen(false);
                triggerRefresh(query);
            } else {
                console.log(response);
                console.log(response.response.data.message);
                if (response?.response?.data?.message) {
                    toast(response.response.data.message, ToastTypes.ERROR);
                }
                else {
                    toast('Hubo un error al procesar el roster, por favor intente de nuevo.', ToastTypes.ERROR);
                }
            }
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal close={() => setIsOpen(false)} customHeader={[]}>
                    <div style={{textAlign: 'left'}} className={'py-4 px-2'}>
                        <div className={'pb-5'} style={{color: '#1A2A4D', fontSize: '28px', fontWeight: 500}}>Agregar Evento</div>
                        <Select value={form['assignation']} options={event_types.map((x) => {
                            return {value: x.slug, name: x.name + ` (${x.slug})`}
                        })} onChange={(val) => {setForm({...form, assignation: val[0]})}}/>
                        <div className={'pt-5 pb-1'} style={{color: '#666666'}}>Rango de Fechas</div>
                        <div className={styles.date}>
                        <div>
                            <input type={'date'} placeholder={'dd/mm/aaaa'} value={form['start_date']} onChange={handleStartDateChange}/>
                            <input type={'date'} placeholder={'dd/mm/aaaa'} value={form['end_date']} onChange={(event) => {
                                setForm({...form, end_date: event.target.value})
                            }}/>
                        </div>
                        </div>
                    </div>

                    <ModalBottom submit={submit}/>
                </Modal>
            }
        </div>
    )
}