// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_password_container__n7qMC {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    font-family: "Lexend Variable", sans-serif;
}

.modal_password_backdrop__SJSYy {
    position: fixed;
    background-color: #000000;
    opacity: 16%;
    width: 100%;
    height: 100%;
}

.modal_password_modal__e6llX {
    width: 590px;
    background-color: #1B294A;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 24px 0 #00000040;
    font-family: "Lexend Variable", sans-serif;
}

.modal_password_header__\\+nB8P {
    display: flex;
    height: 106px;
    overflow-x: hidden;
}

.modal_password_title__NivRS {
    min-width: 150px;
    width: 300px;
    text-align: left;
    font-size: 28px;
    border-radius: 16px 0 0 0;
    padding: 24px 0 0 32px;
    color: #FFFFFF;
    font-weight: 600;
}

.modal_password_mail__5v3Ur {
    background-color: #375395;
    padding: 2px 10px;
    border-radius: 9px;
}`, "",{"version":3,"sources":["webpack://./src/components/modal_password/modal_password.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,aAAa;IACb,0CAA0C;AAC9C;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kCAAkC;IAClC,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;IACtB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 1000;\n    font-family: \"Lexend Variable\", sans-serif;\n}\n\n.backdrop {\n    position: fixed;\n    background-color: #000000;\n    opacity: 16%;\n    width: 100%;\n    height: 100%;\n}\n\n.modal {\n    width: 590px;\n    background-color: #1B294A;\n    border-radius: 16px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    box-shadow: 0 4px 24px 0 #00000040;\n    font-family: \"Lexend Variable\", sans-serif;\n}\n\n.header {\n    display: flex;\n    height: 106px;\n    overflow-x: hidden;\n}\n\n.title {\n    min-width: 150px;\n    width: 300px;\n    text-align: left;\n    font-size: 28px;\n    border-radius: 16px 0 0 0;\n    padding: 24px 0 0 32px;\n    color: #FFFFFF;\n    font-weight: 600;\n}\n\n.mail {\n    background-color: #375395;\n    padding: 2px 10px;\n    border-radius: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `modal_password_container__n7qMC`,
	"backdrop": `modal_password_backdrop__SJSYy`,
	"modal": `modal_password_modal__e6llX`,
	"header": `modal_password_header__+nB8P`,
	"title": `modal_password_title__NivRS`,
	"mail": `modal_password_mail__5v3Ur`
};
export default ___CSS_LOADER_EXPORT___;
