import React, {useEffect, useState, useContext} from "react";
import RosterContext from "../../RosterContext";
import { PermissionType } from "../../PermissionType";
import Sidebar from "../../components/sidebar/sidebar";
import Modal from "../../components/modal/modal";
import PageTitle from "../../components/page_title/page_title";
import Table from "../../components/table/table";
import MasterUpload from "./components/upload/upload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faPenToSquare, faSave, faUserLock, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Select, Text} from "../../ajonjolib/inputs/ajonjolinput";
import {axiosInstance} from "../../AxiosInstance";
import ModalPassword from "../../components/modal_password/modal_password";
import axios, {create} from "axios";
import {Button, File} from "../../ajonjolib/inputs/ajonjolinput";
import ModalBottom from "../../components/modal_bottom/modal_bottom";
import ModalBottomDelete from "../../components/modal_bottom/modal_bottom_delete";
import styles from "../settings/projects/projects.module.css";
import {toast, ToastTypes} from "../../ajonjolib/toasts/toast/toast";
import { ScreenType } from "../../ScreenType";
import { useFullPageLoader } from "../../contexts/fullpage_loader_context";

export default function Users() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [groupProjects, setGroupProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [fronts, setFronts] = useState([]);
    const [boardingAreas, setBoardingAreas] = useState([]);
    const [enterprises, setEnterprises] = useState([]);
    const [areas, setAreas] = useState([]);
    const [positions, setPositions] = useState([]);
    const [classifiers, setClassifiers] = useState([]);
    const [subClassifiers, setSubClassifiers] = useState([]);
    const [agrupadores, setAgrupadores] = useState([]);
    const [personalGroups, setPersonalGroups] = useState([]);
    const [allAreas, setAllAreas] = useState([]);
    const [allGuards, setAllGuards] = useState([]);
    const [allFronts, setAllFronts] = useState([]);
    const [allBoardingAreas, setAllBoardingAreas] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [allClassifiers, setAllClassifiers] = useState([]);
    const [allSubclassifiers, setAllSubclassifiers] = useState([]);
    const [allAgrupadores, setAllAgrupadores] = useState([]);
    const [allPersonalGroups, setAllPersonalGroups] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [form, setForm] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [create, setCreate] = useState(false);
    const [showCloudModal, setShowCloudModal] = useState(false);
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [groupProjectId, setGroupProjectId] = useState(null);
    const { showFullPageLoader, hideFullPageLoader } = useFullPageLoader();

    useEffect(() => {
        document.title = 'Master de Personal | Roster';

        axiosInstance.get('position').then((response) => {
            setPositions(response.data);
            setAllPositions(response.data);
        });

        axiosInstance.get('classifier').then((response) => {
            setClassifiers(response.data);
            setAllClassifiers(response.data);
        });

        axiosInstance.get('subclassifier').then((response) => {
            setSubClassifiers(response.data);
            setAllSubclassifiers(response.data);
        });

        axiosInstance.get('agrupador').then((response) => {
            setAgrupadores(response.data);
            setAllAgrupadores(response.data);
        });

        axiosInstance.get('personal_group').then((response) => {
            setPersonalGroups(response.data);
            setAllPersonalGroups(response.data);
        });

        axiosInstance.get('guard').then((response) => {
            setGuards(response.data.results);
            setAllGuards(response.data.results);
        });

        axiosInstance.get('front').then((response) => {
            setFronts(response.data.results);
            setAllFronts(response.data.results);
        });

        axiosInstance.get('boarding_area').then((response) => {
            setBoardingAreas(response.data.results);
            setAllBoardingAreas(response.data.results);
        });

        axiosInstance.get('project').then((response) => {
            setProjects(response.data.results);
            setAllProjects(response.data.results);
        });

        axiosInstance.get('project_group').then((response) => {
            setGroupProjects(response.data);
        });
        
        axiosInstance.get('enterprise').then((response) => {
            setEnterprises(response.data.results);
        });

        axiosInstance.get('area').then((response) => {
            setAreas(response.data);
            setAllAreas(response.data);
        });

    }, []);


    useEffect(() => {
        if (form['project'] && showEditModal) {
            handleProjectRelatedLoad(form['project']);
        }
        if (!showEditModal) {
            setForm({});
            setProjects(allProjects);
        }
    } , [showEditModal]);

    useEffect(() => {
        setForm({...form, project: null, guard: null, front: null, boarding_area: null});
        if (groupProjectId) {
            const filteredProjects = allProjects.filter(project => project.group === groupProjectId);
            setProjects(filteredProjects);
        } else {
            setProjects(allProjects);
        }
    }, [groupProjectId]);

    const handleProjectRelatedLoad = (selectedProjectId) => {
        const filteredGuards = allGuards.filter(guard => guard.project.id === selectedProjectId);
        setGuards(filteredGuards);
        const filteredFronts = allFronts.filter(front => front.project.id === selectedProjectId);
        setFronts(filteredFronts);
        const filteredBoardingAreas = allBoardingAreas.filter(boardingArea => boardingArea.project.id === selectedProjectId);
        setBoardingAreas(filteredBoardingAreas);
    }

    const handleGroupProjectChange = (selectedGroupProjectId) => {
        const filteredProjects = allProjects.filter(project => project.group === selectedGroupProjectId);
        setProjects(filteredProjects);
        setForm({...form, guard: null, front: null, boarding_area: null, area: null, position: null, classifier: null, subclassifier: null, agrupador: null, personal_group: null});
        setGroupProjectId(selectedGroupProjectId);
    }

    const handleProjectChange = (selectedProjectId) => {
        const filteredGuards = allGuards.filter(guard => guard.project.id === selectedProjectId);
        setGuards(filteredGuards);
        const filteredFronts = allFronts.filter(front => front.project.id === selectedProjectId);
        setFronts(filteredFronts);
        const filteredBoardingAreas = allBoardingAreas.filter(boardingArea => boardingArea.project.id === selectedProjectId);
        setBoardingAreas(filteredBoardingAreas);
        const filteredAreas = allAreas.filter(area => area.project === selectedProjectId);
        setAreas(filteredAreas);
        const filteredPositions = allPositions.filter(position => position.project.id === selectedProjectId);
        setPositions(filteredPositions);
        const filteredClassifiers = allClassifiers.filter(classifier => classifier.project.id === selectedProjectId);
        setClassifiers(filteredClassifiers);
        const filteredSubclassifiers = allSubclassifiers.filter(subclassifier => subclassifier.project.id === selectedProjectId);
        setSubClassifiers(filteredSubclassifiers);
        const filteredAgrupadores = allAgrupadores.filter(agrupador => agrupador.project.id === selectedProjectId);
        setAgrupadores(filteredAgrupadores);
        const filteredPersonalGroups = allPersonalGroups.filter(personalGroup => personalGroup.project.id === selectedProjectId);
        setPersonalGroups(filteredPersonalGroups);
        setForm({...form, guard: null, front: null, boarding_area: null, area: null, position: null, classifier: null, subclassifier: null, agrupador: null, personal_group: null});
    }

    const roles = JSON.parse(localStorage.getItem('role'));

    //Get Context
    const {roleManager} = useContext(RosterContext);

    const shouldShowActions = roleManager.getUsersPermission() == PermissionType.WRITE;

    const cols = [
        {title: 'Estado', internal: 'active', altBackground: true, code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }}/>
                )
            }},
        {title: 'Empresa', internal: 'enterprise.name', altBackground: true},
        {title: 'DNI', internal: 'dni', altBackground: true},
        {title: 'Apellidos y Nombres', internal: 'name', altBackground: true, code: (row) => {
            return row.last_name?.toUpperCase() + " " + row.first_name?.toUpperCase();
            }},
        {title: 'Posición', internal: 'position.name', altBackground: true},
        {title: 'Clasificador', internal: 'classifier.name', altBackground: true},
        {title: 'Subclasificador', internal: 'subclassifier.name', altBackground: true},
        {title: 'Agrupador', internal: 'agrupador.name', altBackground: true},
        {title: 'Grupo de Personal', internal: 'personal_group.name', altBackground: true},
        {title: 'Grupo de Proyecto', internal: 'project_group.name'},
        {title: 'Proyecto', internal: 'project.name'},
        {title: 'Área', internal: 'area.name'},
        {title: 'Guardia', internal: 'guard.name'},
        {title: 'Frente', internal: 'front.name'},
        {title: 'Zona de embarque', internal: 'boarding_area.name'},
    ]

    if (shouldShowActions) {
        cols.push(
            {title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-start'}>
                        <FontAwesomeIcon color={'#6A86C8'} className={'pe-2'} icon={faPenToSquare} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                            setCreate(false);
                            setShowEditModal(true);
                            const roles = row.roles?.map((r) => {
                                return r.id;
                            });
                            console.log("To edit: ", row);
                            setForm({
                                ...row,
                                guard: row.guard?.id,
                                front: row.front?.id,
                                boarding_area: row.boarding_area?.id,
                                project: row.project?.id,
                                enterprise: row.enterprise?.id,
                                area: row.area?.id,
                                position: row.position?.id,
                                classifier: row.classifier?.id,
                                subclassifier: row.subclassifier?.id,
                                agrupador: row.agrupador?.id,
                                personal_group: row.personal_group?.id,
                                roles: roles
                            });
                        }}/>
                        <FontAwesomeIcon color={'#6A86C8'} icon={faUserLock} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                            setShowPassword(true);
                            setCreate(false);
                            setForm(row);
                        }}/>
                        {row.remote_id === null && (
                            <FontAwesomeIcon color={'#6A86C8'} icon={faTrashCan} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                                setShowDeleteModal(true);
                                setForm(row); // Aquí probablemente querrás usar setUserIdToDelete(row.id) basado en la lógica previa
                            }}/>
                        )}
                    </div>
                )
            }
        });
        }

    const submit = () => {
        if(create) {
            if(!form['enterprise'] || !form['dni'] || !form['first_name'] || !form['last_name'] || !form['position'] || !form['subclassifier'] || !form['project'] || !form['personal_group']) {
                toast('Por favor, complete todos los campos obligatorios', ToastTypes.ERROR);
                return;
            }
            // User created as VISUALIZADOR
            axiosInstance.post(`user/`, {
                username: form['dni'],
                password: form['dni'],
                ...form,
            }).then((response) => {
                window.location.reload();
            });
        } else {
            axiosInstance.patch(`user/${form.id}/`, {
                first_name: form['first_name'],
                last_name: form['last_name'],
                position: form['position'],
                classifier: form['classifier'],
                subclassifier: form['subclassifer'],
                agrupador: form['agrupador'],
                project: form['project'],
                guard: form['guard'],
                front: form['front'],
                boarding_area: form['boarding_area'],
                personal_group: form['personal_group'],
                area: form['area'],
                enterprise: form['enterprise'],
            }).then((response) => {
                window.location.reload();
            }).catch((error) => {
                console.error("Users:submit:error", error.response);
            });
        }
        
        setShowEditModal(false);
    }

    const deleteUser = () => {
        const userIdToDelete = form['id'];
        const remoteId = form['remote_id'];
        if (userIdToDelete && userIdToDelete !== 1 && remoteId === null) {
            axiosInstance.delete(`user/${userIdToDelete}/`)
                .then(response => {
                    window.location.reload();
                })
                .catch(error => {
                    console.error("Users:deleteUser:error", error);
                })
                .finally(() => {
                    setShowDeleteModal(false);
                });
        }
    };

    return (
        <div>
            <Sidebar selected={ScreenType.USER_MASTER}/>
            {showPassword &&
                <ModalPassword mail={form.email} close={() => setShowPassword(false)}>
                    <div className={'d-flex justify-content-center pt-4'}>
                        <div style={{
                            backgroundColor: '#F32735', padding: '10px 25px', borderRadius: '43px', cursor: 'pointer',
                            fontSize: '15px',
                            fontWeight: '500'
                        }}>Enviar Enlace</div>
                    </div>
                </ModalPassword>
            }
            {showDeleteModal &&
                <Modal title={'Eliminar Usuario'} subtitle={'Confirmación'} close={() => setShowDeleteModal(false)}>
                    <p>¿Estás seguro de que quieres eliminar este usuario?</p>
                    <ModalBottomDelete
                        cancel={() => setShowDeleteModal(false)}
                        submit={() => {
                            setShowDeleteModal(false);
                            deleteUser();
                        }}
                    />
                </Modal>
            }
            {showEditModal &&
                <Modal title={'Master de Trabajadores'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowEditModal(false)}>
                    <div style={{textAlign: 'left'}}>
                        <div style={{flexBasis: '100%'}}>
                            <div className={styles.requiredInput}>Empresa</div>
                            <Select
                                    value={form['enterprise']} 
                                    options={enterprises?.map((enterprise) => {
                                        return {value: enterprise.id, name: enterprise.name};
                                    })}
                                    onChange={(val) => {
                                        setForm({...form, enterprise: val[0]});
                                    }}
                                />
                        </div>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '22%'}}>
                                <div className={styles.requiredInput}>DNI</div>
                                <Text disabled={!create} value={form['dni']} onChange={(val) => {
                                    setForm({...form, dni: val})
                                }}/>
                            </div>
                            <div style={{flexBasis: '37%'}}>
                                <div className={styles.requiredInput}>Nombres</div>
                                <Text disabled={!create && form['remote_id'] !== null} value={form['first_name']} onChange={(val) => {
                                    setForm({...form, first_name: val})
                                }}/>
                            </div>
                            <div style={{flexBasis: '37%'}}>
                                <div className={styles.requiredInput}>Apellidos</div>
                                <Text disabled={!create && form['remote_id'] !== null} value={form['last_name']} onChange={(val) => {
                                    setForm({...form, last_name: val})
                                }}/>
                            </div>
                        </div>
                        <div style={{flexBasis: '100%'}}>
                                <div className={styles.requiredInput}>Email</div>
                                <Text disabled={!create && form['remote_id'] !== null } value={form['email']} onChange={(val) => {
                                    setForm({...form, email: val})
                                }}/>
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.requiredInput}>Grupo de Proyecto</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text 
                                    disabled={true} 
                                    value={
                                        (() => {
                                            const formProjectId = form['project'];
                                            const project = Array.isArray(projects) ? projects.find(project => project.id === formProjectId) : undefined;
                                            if (project) {
                                                const groupProject = Array.isArray(groupProjects) ? groupProjects.find(groupProject => groupProject.id === project.group) : undefined;
                                                return groupProject ? groupProject.name : '';
                                            }
                                            return '';
                                        })()
                                    }
                                />
                            ) : (
                                <Select 
                                    value={
                                        (() => {
                                            const formProjectId = form['project'];
                                            const project = Array.isArray(projects) ? projects.find(project => project.id === formProjectId) : undefined;
                                            if (project) {
                                                const groupProject = Array.isArray(groupProjects) ? groupProjects.find(groupProject => groupProject.id === project.group) : undefined;
                                                return groupProject ? groupProject.id : '';
                                            }
                                            return '';
                                        })()
                                    }
                                    options={groupProjects?.map((project) => {
                                        return {value: project.id, name: project.name};
                                    })}
                                    onChange={(val) => {
                                        handleGroupProjectChange(val[0]);
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.requiredInput}>Proyecto</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text 
                                    disabled={true} 
                                    value={Array.isArray(projects) ? projects.find(project => project.id === form['project'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={groupProjectId || 'default-key'}
                                    value={form['project']} 
                                    options={projects?.map((project) => {
                                        return {value: project.id, name: project.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected Project", val[0]);
                                        handleProjectChange(val[0]);
                                        setForm({...form, project: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.requiredInput}>Area</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text 
                                    disabled={true} 
                                    value={Array.isArray(areas) ? areas.find(area => area.id === form['area'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={form.project || 'default-key'}
                                    value={form['area']} 
                                    options={areas?.map((area) => {
                                        return {value: area.id, name: area.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected area", val[0]);
                                        setForm({...form, area: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.requiredInput}>Posición</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text
                                    disabled={true}
                                    value={Array.isArray(positions) ? positions.find(position => position.id === form['position'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={form.project || 'default-key'}
                                    value={form['position']}
                                    options={positions?.map((position) => {
                                        return {value: position.id, name: position.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected position", val[0]);
                                        setForm({...form, position: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Clasificador</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text
                                    disabled={true}
                                    value={Array.isArray(classifiers) ? classifiers.find(classifier => classifier.id === form['classifier'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={form.project || 'default-key'}
                                    value={form['classifier']}
                                    options={classifiers?.map((role) => {
                                        return {value: role.id, name: role.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected Classifier", val[0]);
                                        setForm({...form, classifier: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Subclasificador</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text
                                    disabled={true}
                                    value={Array.isArray(subClassifiers) ? subClassifiers.find(subclassifier => subclassifier.id === form['subclassifier'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={form.project || 'default-key'}
                                    value={form['subclassifier']}
                                    options={subClassifiers?.map((role) => {
                                        return {value: role.id, name: role.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected Subclassifier", val[0]);
                                        setForm({...form, subclassifier: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Agrupador</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text
                                    disabled={true}
                                    value={Array.isArray(agrupadores) ? agrupadores.find(agrupador => agrupador.id === form['agrupador'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={form.project || 'default-key'}
                                    value={form['agrupador']}
                                    options={agrupadores?.map((role) => {
                                        return {value: role.id, name: role.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected Agrupador", val[0]);
                                        setForm({...form, agrupador: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Grupo de Personal</div>
                            {(!create && form['remote_id'] !== null) ? (
                                <Text
                                    disabled={true}
                                    value={Array.isArray(personalGroups) ? personalGroups.find(personalGroup => personalGroup.id === form['personal_group'])?.name || '' : ''}
                                />
                            ) : (
                                <Select
                                    key={form.project || 'default-key'}
                                    value={form['personal_group']}
                                    options={personalGroups?.map((role) => {
                                        return {value: role.id, name: role.name};
                                    })}
                                    onChange={(val) => {
                                        console.log("Selected Personal Group", val[0]);
                                        setForm({...form, personal_group: val[0]});
                                    }}
                                />
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Guardia</div>
                            <Select key={form.project || 'default-key'} value={form['guard']} options={guards?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                console.log("Selected Guard", val[0]);
                                setForm({...form, guard: val[0]})
                            }}/>
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Frente</div>
                            <Select key={form.project || 'default-key'} value={form['front']} options={fronts?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                console.log("Selected Front", val[0]);
                                setForm({...form, front: val[0]})
                            }}/>
                        </div>

                        <div className={'mb-3'}>
                            <div className={styles.inputName}>Zona de Embarque</div>
                            <Select key={form.project || 'default-key'} value={form['boarding_area']} options={boardingAreas?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                setForm({...form, boarding_area: val[0]})
                            }}/>
                        </div>

                        <ModalBottom submit={() => submit()}/>
                    </div>
                </Modal>
            }

            {showExcelModal &&
                <Modal title={'Importar'} subtitle={'Master'} close={() => setShowExcelModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between'}>
                            <div style={{textAlign: 'left', flexBasis: '45%'}}>
                                <div style={{fontSize: '13px', color: '#666666'}}>Descargar Plantilla</div>
                                <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                    Descargar plantilla excel de Master de Personal.
                                </div>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div
                                    className={styles.templateButton} 
                                    onClick={() => {
                                        showFullPageLoader('Descargando plantilla, espere unos momentos');
                                        axiosInstance.get(`master/?template=true`, {
                                            responseType: 'blob'
                                        }).then((response) => {
                                            hideFullPageLoader();
                                            if (response.status === 200) {
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', 'master_personal_plantilla.xlsx');
                                                document.body.appendChild(link);
                                                link.click();
                                            }
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    <span style={{ marginLeft: '10px' }}>Descargar Plantilla</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={'mt-3'}/>
                        <div style={{fontSize: '11px', color: '#666666', textAlign: 'left'}}>Importar Plantilla</div>
                        <File onChange={(files) => {
                            setForm({...form, file: files[0]});
                        }}/>
                        <div className={'d-flex mt-3'} style={{justifyContent: 'flex-end'}}>
                            <Button variant={'success'} onSubmit={() => {
                                showFullPageLoader('Importando master de personal, espere unos momentos');
                                let formData = new FormData();
                                formData.append('file', form['file'])
                                axiosInstance.post('master/', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then((response) => {
                                    hideFullPageLoader();
                                    if(response.status === 200) {
                                        setShowExcelModal(false);
                                        toast('Master de personal subido exitosamente', ToastTypes.SUCCESS);
                                    } else {
                                        setShowExcelModal(false);
                                        toast('No se ha procesado el master de personal, recuerde respetar todos los campos obligatorios (*)', ToastTypes.ERROR);
                                        console.log('User:import:error', response.data);
                                    }
                                });
                            }}>
                                <div className={'d-flex align-items-center'}>
                                    <FontAwesomeIcon className={'pe-2'} icon={faSave} color={'#FFF'}/>
                                    <div>Guardar</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            {showCloudModal &&
                <Modal title={'Exportar'} subtitle={'Master'} close={() => setShowCloudModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between'}>
                            <div style={{textAlign: 'left', flexBasis: '45%'}}>
                                <div style={{fontSize: '13px', color: '#666666'}}>Descargar Master de Personal</div>
                                <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                    Descargar excel de Master de Personal.
                                </div>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.templateButton} onClick={() => {
                                    showFullPageLoader('Descargando master de personal, espere unos momentos');
                                    axiosInstance.get(`master/`, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        hideFullPageLoader();
                                        if (response.status === 200) {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'master_personal.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    });
                                }}>
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    <span style={{ marginLeft: '10px' }}>Descargar Master de Personal</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Master de Personal'} menu={''}/>
                <div>
                    <div className="d-flex flex-column">
                    {shouldShowActions &&
                        <MasterUpload setShowCloudModal={setShowCloudModal} setShowExcelModal={setShowExcelModal}/>
                    }

                <Table
                    model={'user'}
                    cols={cols}
                    create={() => {
                        setCreate(true);
                        setShowEditModal(true);
                        setForm({});
                    }}
                    delete={() => {
                        setShowDeleteModal(true);
                    }}
                    shouldShowActions={shouldShowActions}
                />
                </div>
                </div>
            </div>
        </div>
    )
}