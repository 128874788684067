import React, { useEffect, useState, useRef } from "react";
import styles from "./home.module.css";
import Sidebar from "../../components/sidebar/sidebar";
import Pie from "./components/pie/pie";
import ListComponent from "./components/pie/list";
import Histogram from "./components/histogram";
import HorizontalBar from "./components/horizontal_bar";
import MirroredBar from "./components/mirrored_bar";
import { ScreenType } from "../../ScreenType";
import PageHeader from "./components/page_header/page_header";
import DashboardService from "./services/dashboard_service";
import Table from "../../components/table/table";
import { axiosInstance } from "../../AxiosInstance";
import { formatDate, parseDateFromString } from "../../utils/format_date";
import { staffValues, rccAndRcoValues } from "../../utils/out_regime_values";
import Pagination from "../../components/pagination/pagination";
import stats from "../../utils/event_types"
import html2canvas from "html2canvas";
import { useFullPageLoader } from "../../contexts/fullpage_loader_context";
import { toast, ToastTypes } from "../../ajonjolib/toasts/toast/toast";

export default function Home() {
  const [query, setQuery] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [horizontalBarData, setHorizontalBarData] = useState({});
  const [horizontalBarDayOffData, setHorizontalBarDayOffData] = useState({});
  const [histogramLabels, setHistogramLabels] = useState([]);
  const [histogramDataHiredStaff, setHistogramDataHiredStaff] = useState([]);
  const [histogramDataOnSiteStaff, setHistogramDataOnSiteStaff] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [pieColor, setPieColors] = useState([]);
  const [mirroedBarMobDesmobData, setMirroedBarMobDesmobData] = useState([]);
  const [listComponentData, setListComponentData] = useState([]);
  const [tableUsersWithoutRosterCustomBehavior, setTableUsersWithoutRosterCustomBehavior] = useState(null);
  const divHistogramRef = useRef(null);
  const divOutOfRegimeRef = useRef(null);
  const divPendingDaysRef = useRef(null);
  const divMobDemobRef = useRef(null);
  const { showFullPageLoader, hideFullPageLoader } = useFullPageLoader();

  const cols = [
    {
      title: 'Apellidos y Nombres', internal: 'name', altBackground: true, code: (row) => {
        return row.last_name?.toUpperCase() + " " + row.first_name?.toUpperCase();
      }
    },
    {
      title: 'Grupo de Personal', internal: 'personal_group', altBackground: true, code: (row) => {
        return row.personal_group?.name;
      }
    },
    {
      title: 'Puesto', internal: 'subclassifier', altBackground: true, code: (row) => {
        return row.subclassifier?.name;
      }
    },
  ]

  const isTableUsersWithoutRosterEnabled = () => {
    if (tableUsersWithoutRosterCustomBehavior) {
      const { startDate, endDate, projectIds } = tableUsersWithoutRosterCustomBehavior.filters;
      if (startDate && endDate && projectIds) return true;
    }
    return false;
  }

  const validateFilters = (filters) => {
    const { from_date, to_date, project_ids } = filters;
    if (!from_date || !to_date || !project_ids) {
      toast('Debe seleccionar un rango de fechas y un proyecto', ToastTypes.ERROR);
      return false;
    }
    return true;
  }

  const exportDivAsImage = (divRef) => {
    const div = divRef.current;
    if (div) {
      html2canvas(div).then((canvas) => {
        const url = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = url;
        link.download = 'chart.png';
        link.click();
      });
    }
  };

  const handleExportToExcel = ({ endpoint, fileBaseName }) => {
    const projectIds = query['project'] || [];
    const areaIds = query['area'] || [];
    const startDate = query['start_date'];
    const endDate = query['end_date'];
    const filters = {
      'from_date': startDate,
      'to_date': endDate,
      'project_ids': projectIds.join(','),
      'area_ids': areaIds.join(','),
      'export': true
    }

    if (!validateFilters(filters)) return;

    showFullPageLoader('Exportando datos...');

    axiosInstance.get(endpoint, { params: filters, responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileBaseName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => console.error('Home:handleExportToExcel():', error))
      .finally(() => hideFullPageLoader());
  };

  const fetchOutOfRegimeData = (filters) => {
    axiosInstance.get('dashboard/out_of_regime/', { params: filters })
      .then((response) => {
        const results = response.data.results;
        console.log('results', results);
        const resultsStaff = results.filter(result => staffValues.includes(result.personal_group_name));
        console.log('resultsStaff', resultsStaff);
        const resultsRcoAndRcc = results.filter(result => rccAndRcoValues.includes(result.personal_group_name));
        console.log('resultsRcoAndRcc', resultsRcoAndRcc);
        const lstEmployeesNamesStaff = resultsStaff.map(employee => `${employee.first_name} ${employee.last_name}`);
        const lstEmployeesWorkDaysStaff = resultsStaff.map(employee => employee.total);
        const lstEmployeesNamesRcoAndRcc = resultsRcoAndRcc.map(employee => `${employee.first_name} ${employee.last_name}`);
        const lstEmployeesWorkDaysRcoAndRcc = resultsRcoAndRcc.map(employee => employee.total);

        const totalPages = Math.ceil(response.data.count / filters.page_size);

        setHorizontalBarData({
          totalPages: totalPages,
          staff: {
            employeesNames: lstEmployeesNamesStaff || [],
            count: lstEmployeesWorkDaysStaff || []
          },
          rcoAndRcc: {
            employeesNames: lstEmployeesNamesRcoAndRcc || [],
            count: lstEmployeesWorkDaysRcoAndRcc || []
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fetchMobDemobData = (filters) => {
    axiosInstance.get('dashboard/mobiliz_demobiliz_days/', { params: filters })
      .then((response) => {
        const data = response.data.results || [];
        const lstLabelsSet = new Set(), lstValuesMob = [], lstValuesDemob = [];
        data.forEach(item => {
          lstLabelsSet.add(formatDate(parseDateFromString(item.date)));
          item.group_code == 'demobilization' ?
            lstValuesDemob.push(item.total) :
            lstValuesMob.push(item.total);
        });

        const lstLabels = Array.from(lstLabelsSet);
        setMirroedBarMobDesmobData({
          totalPages: Math.ceil(response.data.count / filters.page_size),
          labels: lstLabels,
          valuesMob: lstValuesMob,
          valuesDemob: lstValuesDemob
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fetchPendingDaysData = (filters) => {
    axiosInstance.get('dashboard/pending_days/', { params: filters })
      .then((response) => {
        const data = response.data.results;
        const lstDayOffPositive = data.filter(user => user.days_off_total >= 0)
        const lstDayOffNegative = data.filter(user => user.days_off_total < 0)

        const mapEmployeeNames = (employees) => employees.map(employee => `${employee.first_name} ${employee.last_name}`);
        const mapEmployeeDaysOff = (employees) => employees.map(employee => employee.days_off_total);

        const lstEmployeesNamesPositive = mapEmployeeNames(lstDayOffPositive);
        const lstEmployeesNamesNegative = mapEmployeeNames(lstDayOffNegative);
        const lstEmployeesDayOffPositive = mapEmployeeDaysOff(lstDayOffPositive);
        const lstEmployeesDayOffNegative = mapEmployeeDaysOff(lstDayOffNegative);

        setHorizontalBarDayOffData({
          totalPages: Math.ceil(response.data.count / filters.page_size),
          positive: {
            employeesNames: lstEmployeesNamesPositive || [],
            value: lstEmployeesDayOffPositive || []
          },
          negative: {
            employeesNames: lstEmployeesNamesNegative || [],
            value: lstEmployeesDayOffNegative || []
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const projectIds = query['project'] || [];
    const areaIds = query['area'] || [];
    const startDate = query['start_date'];
    const endDate = query['end_date'];
    const page = query['page'] || 1;
    const pageSize = query['page_size'] || 10;

    if (projectIds.length > 0 && startDate && endDate) {
      const filters = {
        'from_date': startDate,
        'to_date': endDate,
        'project_ids': projectIds.join(','),
        'area_ids': areaIds.join(','),
        'page': page,
        'page_size': pageSize
      }

      axiosInstance.get('dashboard/on_site_staff/', { params: filters })
        .then((response) => {
          if (response.data) {
            const data = response.data;
            const lstDates = data.map(item => formatDate(parseDateFromString(item.date)));
            const lstTotalByDate = data.map(item => item.count);
            setHistogramLabels(lstDates);
            setHistogramDataOnSiteStaff(lstTotalByDate);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      fetchOutOfRegimeData(filters);
      fetchMobDemobData(filters);
      setTableUsersWithoutRosterCustomBehavior({
        name: 'users_without_roster',
        filters: {
          startDate,
          endDate,
          projectIds
        }
      });
    }

    if (projectIds.length > 0) {
      const filters = {
        'project_ids': projectIds.join(','),
        'area_ids': areaIds.join(','),
        'page': page,
        'page_size': pageSize
      }

      axiosInstance.get('dashboard/projects_roster_today/', { params: filters })
        .then((response) => {
          if (response.data) {
            const data = response.data;
            // const data = [
            //   {assignation: 'N', count: getRandomInt(10, 50)},
            //   {assignation: 'D', count: getRandomInt(50, 80)},
            //   {assignation: 'BD', count: getRandomInt(10, 50)},
            //   {assignation: 'BN', count: getRandomInt(10, 100)},
            // ];

            const lstTotalByEvent = [], lstEventsColor = [], lstItems = [];

            data.forEach(item => {
              const eventDetail = stats.find(stat => stat.slug == item.assignation)
              const count = item.count;
              const { color, name } = eventDetail;
              lstTotalByEvent.push(count);
              lstEventsColor.push(color);
              lstItems.push({
                color: color,
                label: name,
                value: count
              });
            });

            setPieData(lstTotalByEvent);
            setPieColors(lstEventsColor);
            setListComponentData(lstItems);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      fetchPendingDaysData(filters);
    }
  }, [query])

  return (
    <div>
      <Sidebar selected={ScreenType.DASHBOARD} />
      <div className="mainContainer">
        <PageHeader query={query} setQuery={setQuery} setShowFilterModal={setShowFilterModal} />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 7, marginRight: '32px' }}>
            {/* @start Container Histogram */}
            <div className={styles.graphContainer}>
              <div className={styles.title}>Histograma</div>
              <div ref={divHistogramRef} style={{ display: 'flex', width: '100%', height: 'auto' }}>
                <Histogram
                  labels={histogramLabels}
                  dataOnSiteStaff={histogramDataOnSiteStaff}
                  dataHiredStaff={histogramDataHiredStaff} />
              </div>
              {/* <button onClick={() => exportDivAsImage(divHistogramRef)} className={styles.buttonExport}>Exportar</button> */}
              <button
                onClick={() => handleExportToExcel({
                  endpoint: 'dashboard/on_site_staff/',
                  fileBaseName: 'on_site_staff'
                })}
                className={styles.buttonExport}>
                Exportar Excel
              </button>
            </div>
            {/* @end Container Histograma */}

            {/* @start Container Out of regime */}
            <div className={styles.graphContainer}>
              <div className={styles.title}>Fuera de Regimen</div>
              <div ref={divOutOfRegimeRef} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <HorizontalBar
                  label={"Staff"}
                  labels={horizontalBarData.staff?.employeesNames}
                  data={horizontalBarData.staff?.count}
                  backgroundColor={"rgba(106, 134, 200, 0.3)"}
                  borderColor={"rgba(106, 134, 200, 1)"}
                />
                <HorizontalBar
                  label={"RCO y RCC"}
                  labels={horizontalBarData.rcoAndRcc?.employeesNames}
                  data={horizontalBarData.rcoAndRcc?.count}
                  backgroundColor={"rgba(188, 209, 63, 0.3)"}
                  borderColor={"rgba(188, 209, 63, 1)"}
                />
              </div>
              {/* <button onClick={() => exportDivAsImage(divOutOfRegimeRef)} className={styles.buttonExport}>Exportar</button> */}
              <button
                onClick={() => handleExportToExcel({
                  endpoint: 'dashboard/out_of_regime/',
                  fileBaseName: 'out_of_regime'
                })}
                className={styles.buttonExport}>
                Exportar Excel
              </button>
              {
                horizontalBarData.totalPages > 0 &&
                <Pagination
                  totalPages={horizontalBarData.totalPages}
                  onPageChange={(page) => { setQuery({ ...query, page }) }} />
              }
            </div>
            {/* @end Container Out of regime */}

            {/* @start Container Pending days */}
            <div className={styles.graphContainer}>
              <div className={styles.title}>Días pendientes</div>
              <div ref={divPendingDaysRef} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <HorizontalBar
                  label={"Se le deben días"}
                  labels={horizontalBarDayOffData.positive?.employeesNames}
                  data={horizontalBarDayOffData.positive?.value}
                  backgroundColor={"rgba(106, 134, 200, 0.3)"}
                  borderColor={"rgba(106, 134, 200, 1)"}
                />
                <HorizontalBar
                  label={"Debe días"}
                  labels={horizontalBarDayOffData.negative?.employeesNames}
                  data={horizontalBarDayOffData.negative?.value}
                  backgroundColor={"rgba(188, 209, 63, 0.3)"}
                  borderColor={"rgba(188, 209, 63, 1)"}
                  direction="rtl"
                />
              </div>
              {/* <button onClick={() => exportDivAsImage(divPendingDaysRef)} className={styles.buttonExport}>Exportar</button> */}
              <button
                onClick={() => handleExportToExcel({
                  endpoint: 'dashboard/pending_days/',
                  fileBaseName: 'pending_days_data'
                })}
                className={styles.buttonExport}>
                Exportar Excel
              </button>
              {
                horizontalBarDayOffData.totalPages > 0 &&
                <Pagination
                  totalPages={horizontalBarDayOffData.totalPages}
                  onPageChange={(page) => { setQuery({ ...query, page }) }} />
              }
            </div>
            {/* @end Container Pending days */}

            {/* @start Container Mobilization and Demobilization */}
            <div className={styles.graphContainer}>
              <div className={styles.title}>Movilización y Desmovilización</div>
              <div ref={divMobDemobRef} style={{ display: 'flex', width: '100%', height: 'auto' }}>
                <MirroredBar
                  labels={mirroedBarMobDesmobData?.labels}
                  valuesMob={mirroedBarMobDesmobData?.valuesMob}
                  valuesDemob={mirroedBarMobDesmobData?.valuesDemob}
                />
              </div>
              {/* <button onClick={() => exportDivAsImage(divMobDemobRef)} className={styles.buttonExport}>Exportar</button> */}
              <button
                onClick={() => handleExportToExcel({
                  endpoint: 'dashboard/mobiliz_demobiliz_days/',
                  fileBaseName: 'mobiliz_demobiliz_days'
                })}
                className={styles.buttonExport}>
                Exportar Excel
              </button>
              {
                mirroedBarMobDesmobData.totalPages > 0 &&
                <Pagination
                  totalPages={mirroedBarMobDesmobData.totalPages}
                  onPageChange={(page) => { setQuery({ ...query, page }) }} />
              }
            </div>
            {/* @end Container Mobilization and Demobilization */}

            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.graphContainer}>
              <div className={styles.title}>Personal sin Confirmar</div>
                <Table
                  model={'user'}
                  cols={cols}
                  shouldShowActions={false}
                />
              </div> */}

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.graphContainer}>
              <div className={styles.title}>Personal sin roster</div>
              {isTableUsersWithoutRosterEnabled() && (
                <>
                  <button
                    onClick={() => handleExportToExcel({
                      endpoint: 'dashboard/user_without_roster/download/',
                      fileBaseName: 'user_without_roster'
                    })}
                    className={styles.buttonExport}>
                    Exportar Excel
                  </button>
                  <Table
                    model={'user'}
                    cols={cols}
                    shouldShowActions={false}
                    customBehavior={tableUsersWithoutRosterCustomBehavior}
                  />
                </>
              )
              }
            </div>
          </div>
          <div style={{ flex: 3, position: 'sticky', top: 0, alignSelf: 'flex-start' }}>
            <div className={styles.graphContainer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={styles.title}>Proyecto</div>
              <Pie data={pieData} colors={pieColor} />
              <ListComponent data={listComponentData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}