import React, { useEffect, useState, useContext } from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faUserLock, faFileExcel } from "@fortawesome/free-solid-svg-icons";
// import {faFileExcel, faPenToSquare, faPenToSquare, faUserLock } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/modal/modal";
import { Text } from "../../../ajonjolib/inputs/ajonjolinput";
import { axiosInstance } from "../../../AxiosInstance";
import ModalBottom from "../../../components/modal_bottom/modal_bottom";
import RosterUpload from "../../roster/components/upload/upload";
import { eventBus } from "../../../ajonjolib/toasts/toast/toast";
import ExcelModal from "./modals/excel_modal";
import { ScreenType, RosterScreenType } from "../../../ScreenType";
import { formatDateToFiles } from "../../../utils/format_date";
import { formatNumberToTwoDecimals } from "../../../utils/format_number";
import styles from "../projects/projects.module.css";

export default function Balances() {
    const [form, setForm] = useState({});
    const [create, setCreate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCloudModal, setShowCloudModal] = useState(false);
    const { roleManager } = useContext(RosterContext);

    const shouldShowActions = roleManager.getRosterBalancesPermission() == PermissionType.WRITE;

    const cols = [
        { title: 'DNI', internal: 'dni', altBackground: true },
        {
            title: 'Apellidos y Nombres', altBackground: true, internal: 'name', code: (row) => {
                return row.last_name?.toUpperCase() + " " + row.first_name?.toUpperCase();
            }
        },
        {
            title: 'Puesto', internal: 'subclassifier', altBackground: true, code: (row) => {
                return row.subclassifier?.name?.toUpperCase();
            }
        },
        {
            title: 'Saldo de días Total', internal: 'days_off_total', code: (row) => {
                return formatNumberToTwoDecimals(row.current_days_off);
            }
        },
        {
            title: 'Saldo de días al Cierre', internal: 'days_off_estimated', code: (row) => {
                return formatNumberToTwoDecimals(row.days_off_estimated);
            }
        }
    ]

    if (shouldShowActions) {
        cols.push({
            title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-start'}>
                        <FontAwesomeIcon color={'#6A86C8'} className={'pe-2'} icon={faPenToSquare} size={'lg'} style={{ cursor: 'pointer' }} onClick={() => {
                            setShowModal(true);
                            const roles = row.roles?.map((r) => {
                                return r.id;
                            });
                            console.log(row);
                            setForm({
                                ...row,
                                guard: row.guard?.id,
                                front: row.front?.id,
                                boarding_area: row.boarding_area?.id,
                                roles: roles
                            });
                        }} />
                    </div>
                )
            }
        });
    }

    const submit = () => {
        const currentDate = new Date().toISOString().split('T')[0] // Get
        axiosInstance.patch(`user/${form.id}/`, {
            current_days_off: form.current_days_off,
            current_days_off_date: currentDate
        }).then((response) => {
            window.location.reload();
        });
    }

    useEffect(() => {
        document.title = 'Programaciones y Saldos | Roster';
    }, []);

    return (
        <div>
            <Sidebar selected={ScreenType.ROSTER} selectedSubmenu={RosterScreenType.BALANCES} />

            {showModal &&
                <Modal title={'Programación y Saldos'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowModal(false)}>
                    <div style={{ textAlign: 'left' }}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{ flexBasis: '22%' }}>
                                <div>DNI*</div>
                                <Text value={form['dni']} onChange={(val) => {
                                    setForm({ ...form, dni: val })
                                }} />
                            </div>
                            <div style={{ flexBasis: '37%' }}>
                                <div>Nombres*</div>
                                <Text disabled={true} value={form['first_name']} onChange={(val) => {
                                    setForm({ ...form, first_name: val })
                                }} />
                            </div>
                            <div style={{ flexBasis: '37%' }}>
                                <div>Apellidos*</div>
                                <Text disabled={true} value={form['last_name']} onChange={(val) => {
                                    setForm({ ...form, last_name: val })
                                }} />
                            </div>
                        </div>
                        <div style={{ flexBasis: '100%' }} className={'mb-3'}>
                            <div>Puesto*</div>
                            <Text disabled={true} value={form['subclassifier']?.name} onChange={(val) => {
                                setForm({ ...form, subclassifier: val })
                            }} />
                        </div>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{ flexBasis: '100%' }}>
                                <div>Saldo de Días Total*</div>
                                <Text value={form['current_days_off']} onChange={(val) => {
                                    setForm({ ...form, current_days_off: val })
                                }} />
                            </div>
                        </div>
                        <ModalBottom submit={submit} />
                    </div>
                </Modal>
            }

            <ExcelModal />

            {/* @start Balance Modal Export */}
            {showCloudModal &&
                <Modal title={'Exportar'} subtitle={'Programaciones y Saldos'} close={() => setShowCloudModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between'}>
                            <div style={{ textAlign: 'left', flexBasis: '45%' }}>
                                <div style={{ fontSize: '13px', color: '#666666' }}>
                                    Descargar Programaciones y Saldos
                                </div>
                                <div style={{ fontSize: '10px', color: '#9B9B9B' }}>
                                    Descargar excel de Programaciones y Saldos
                                </div>
                            </div>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.templateButton} onClick={() => {
                                    axiosInstance.get(`days_off/`, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        if (response.status === 200) {
                                            const currentDate = new Date();
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `programaciones_saldos_${formatDateToFiles(currentDate)}.xlsx`);
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    });
                                }}>
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    <span style={{ marginLeft: '10px' }}>Descargar Progrmaciones y Saldos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {/* @end Balance Modal Export */}

            <div className={'mainContainer'}>
                <PageTitle title={'Programaciones y Saldos'} menu={'Configuración'} />
                <div className={'d-flex flex-column'}>
                    <RosterUpload
                        setShowCloudModal={() => eventBus.emit('open_balance_excel')}
                        setShowExcelModal={() => setShowCloudModal(true)}
                        shouldShowPlannerActions={shouldShowActions} />

                    <Table model={'user'} cols={cols} />
                </div>
            </div>
        </div>
    )
}