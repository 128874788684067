// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roles_sectionContainer__efZKx {
    background-color: white;
    border: 2px solid #D6E0F4;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column; /* Para que los gráficos se dispongan en columna */
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  }

.roles_sectionTitle__6IycC {
    align-self: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #38405B;
    cursor: pointer; /* Cambia el cursor al pasar sobre el título */
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.roles_toggleIcon__IeolI {
    font-size: 18px;
    line-height: 18px;
    margin-left: 8px;
    transition: transform 0.2s;
}

.roles_modalBody__s49F4 {
    padding: 16px;
    overflow-y: auto !important; /* Forzamos el scroll vertical */
    max-height: 80vh; /* Opcional: limita la altura máxima para que sea más manejable */
  }
  
`, "",{"version":3,"sources":["webpack://./src/screens/settings/roles/roles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,wCAAwC;IACxC,sBAAsB;IACtB,aAAa;IACb,sBAAsB,EAAE,kDAAkD;IAC1E,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW,EAAE,6DAA6D;EAC5E;;AAEF;IACI,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,eAAe,EAAE,8CAA8C;IAC/D,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,2BAA2B,EAAE,gCAAgC;IAC7D,gBAAgB,EAAE,iEAAiE;EACrF","sourcesContent":[".sectionContainer {\n    background-color: white;\n    border: 2px solid #D6E0F4;\n    border-radius: 8px;\n    padding: 16px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column; /* Para que los gráficos se dispongan en columna */\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 16px;\n    width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */\n  }\n\n.sectionTitle {\n    align-self: flex-start;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 15px;\n    color: #38405B;\n    cursor: pointer; /* Cambia el cursor al pasar sobre el título */\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.toggleIcon {\n    font-size: 18px;\n    line-height: 18px;\n    margin-left: 8px;\n    transition: transform 0.2s;\n}\n\n.modalBody {\n    padding: 16px;\n    overflow-y: auto !important; /* Forzamos el scroll vertical */\n    max-height: 80vh; /* Opcional: limita la altura máxima para que sea más manejable */\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionContainer": `roles_sectionContainer__efZKx`,
	"sectionTitle": `roles_sectionTitle__6IycC`,
	"toggleIcon": `roles_toggleIcon__IeolI`,
	"modalBody": `roles_modalBody__s49F4`
};
export default ___CSS_LOADER_EXPORT___;
