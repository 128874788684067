import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrando los componentes necesarios
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

// const data = {
//   labels: [],
//   datasets: [
//     {
//       data: [50, 16, 10, 20, 25],
//       backgroundColor: ['#239290', '#FFAA00', '#6D4C41', '#274E13', '#375395'],
//       hoverBackgroundColor: ['#239290', '#FFAA00', '#6D4C41', '#274E13', '#375395'],
//       borderWidth: 5, // Ancho del borde
//       borderColor: '#FFFFFF', // Color del borde para crear espacio entre los segmentos
//       borderRadius: 15, // Radio del borde para bordes redondeados
//       spacing: 3, // Espacio entre los segmentos
//     },
//   ],
// };

const options = {
  cutout: '85%', // En la versión 3, se usa "cutout" en lugar de "cutoutPercentage"
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const Pie = ({data = [], colors = []}) => {
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 5,
        borderColor: '#FFFFFF',
        borderRadius: 15,
        spacing: 3,
      },
    ],
  });

  const sumData = (data) => {
    return data.reduce((total, value) => total + value, 0);
  };

  useEffect(() => {
    setPieData({
      labels: [],
      datasets: [
        {
          data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
          borderWidth: 5,
          borderColor: '#FFFFFF',
          borderRadius: 15,
          spacing: 3,
        },
      ],
    });
  }, [data, colors]);

  return (
    <div className='wrapper' style={{ position: 'relative', width: '260px', height: '260px' }}>
      <div className='chart-number' style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: '#494B50',
        fontWeight: "500",
        fontSize: '48px',
        lineHeight: '48px', // Ajustado para un mejor centrado vertical
        fontFamily: 'sans-serif'
      }}>
        { sumData(data) }
        <div style={{
          fontSize: '9px',
          fontWeight: '500',
        }}>
          Trabajadores en proyecto hoy
        </div>
      </div>
      <Doughnut data={pieData} options={options} />
    </div>
  );
};

export default Pie;
