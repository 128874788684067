// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date_container__wFYch input[type='date'] {
    background-color: #FFFFFF;
    border: 1px #E3E8F4 solid;
    border-radius: 20px;
    width: 100%;
    font-size: 12px;
    color: #6A86C8;
    line-height: 16px;
    padding: 5px 12px;
}`, "",{"version":3,"sources":["webpack://./src/ajonjolib/inputs/date/date.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".container input[type='date'] {\n    background-color: #FFFFFF;\n    border: 1px #E3E8F4 solid;\n    border-radius: 20px;\n    width: 100%;\n    font-size: 12px;\n    color: #6A86C8;\n    line-height: 16px;\n    padding: 5px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `date_container__wFYch`
};
export default ___CSS_LOADER_EXPORT___;
