import React, {useEffect} from "react";
import styles from './scroll_top.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";

export default function ScrollTop() {
    const [isVisible, setIsVisible] = React.useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        }
    }, []);

    return (
        <div className={styles.container} style={{display: isVisible ? 'block' : 'none'}} onClick={() => window.scrollTo(0, 0)}>
            <div className={'d-flex align-items-center justify-content-around py-2'}>
                <FontAwesomeIcon icon={faChevronUp} color={'#FFF'}/>
                <div>Volver Arriba</div>
                <FontAwesomeIcon icon={faChevronUp} color={'#FFF'}/>
            </div>
        </div>
    )
}