import React from "react";
import styles from './page_title.module.css';

export default function PageTitle({title, menu}) {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.title}>{title}</div>
                <div className={styles.menu}>{menu}</div>
            </div>
            <div className={styles.divider}></div>
        </div>
    );
}