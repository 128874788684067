// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roster_templateButton__pixnO {
    background-color: #217345;
    border-radius: 33px;
    color: #FFFFFF;
    box-shadow: 0 4px 7px -2px #2755764D;
    width: 100%;
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/screens/roster/roster.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,oCAAoC;IACpC,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".templateButton {\n    background-color: #217345;\n    border-radius: 33px;\n    color: #FFFFFF;\n    box-shadow: 0 4px 7px -2px #2755764D;\n    width: 100%;\n    display: flex;\n    padding: 12px;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateButton": `roster_templateButton__pixnO`
};
export default ___CSS_LOADER_EXPORT___;
