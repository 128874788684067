import React, {useEffect, useState, useContext} from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faSave} from "@fortawesome/free-solid-svg-icons";
import styles from "./roles.module.css";
import Modal from "../../../components/modal/modal";
import {Select, Text} from "../../../ajonjolib/inputs/ajonjolinput";
import Switch from "../../../ajonjolib/inputs/switch/switch";
import LabeledSelect from "../../../ajonjolib/inputs/labeled_select/labeled_select";
import {axiosInstance} from "../../../AxiosInstance";
import {toast, ToastTypes} from "../../../ajonjolib/toasts/toast/toast";
import { ScreenType, SecurityScreenType } from "../../../ScreenType";

const Section = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle} onClick={() => setIsOpen(!isOpen)}>
          {title}
          <span className={styles.toggleIcon}>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && children}
      </div>
    );
}

export default function Roles() {
    const [showModal, setShowModal] = useState(false);
    const [create, setCreate] = useState(true);
    const [form, setForm] = useState({});
    
    const {roleManager} = useContext(RosterContext);

    const shouldShowActions = roleManager.getSettingsRolesPermission() == PermissionType.WRITE;

    const cols = [
        {title: 'Nombre', internal: 'name'},
        {title: 'Estado', internal: 'active', code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }}/>
                )
            }}
        ];
    
    if (shouldShowActions) {
        cols.push(
            {title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <FontAwesomeIcon color={'#6A86C8'} icon={faPenToSquare} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                        setForm(row);
                        setShowModal(true);
                        setCreate(false);
                    }}/>
                )
            }}
        );
    }

    const submit = () => {
        if(create) {
            axiosInstance.post('role/', form).then((response) => {
                setShowModal(false);
                setCreate(false);
                if(response.status === 200 || response.status === 201) {
                    toast('Rol creado exitosamente', ToastTypes.SUCCESS);
                    window.location.reload();
                } else {
                    toast('Error al crear el rol', ToastTypes.ERROR);
                }
            });
        } else {
            axiosInstance.put(`role/${form.id}/`, form).then((response) => {
                setShowModal(false);
                if(response.status === 200) {
                    toast('Rol actualizado exitosamente', ToastTypes.SUCCESS);
                    window.location.reload();
                } else {
                    toast('Error al editar el rol', ToastTypes.ERROR);
                }
            });
        }
    }

    return (
        <div>
            <Sidebar selected={ScreenType.SECURITY} selectedSubmenu={SecurityScreenType.ROLES}/>

            {showModal &&
                <Modal title={'Roles'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowModal(false)} >
                    <div style={{textAlign: 'left'}}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div>Nombre*</div>
                                <Text value={form['name']} onChange={(value) => {
                                    setForm({...form, name: value});
                                }}/>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div>Estado*</div>
                                <Switch value={form['active']} onChange={(value) => {
                                    setForm({...form, active: value})
                                }}/>
                            </div>
                        </div>

                        <div className={styles.modalBody}>

                        <Section title="Dashboard">
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Dashboard'} value={form['dashboard_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, dashboard_permission: value[0]});
                        }}/>
                        </Section>

                        <Section title="Master de Personal">
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Master de Personal'} value={form['master_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, master_permission: value[0]});
                        }}/>
                        </Section>

                        <Section title="Roster">
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Roster'} value={form['roster_permission']} options={[{value: 0, name: "Fuera de Fecha"}, {value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, roster_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Aprobar roster'} value={form['aproval_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, aproval_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Programaciones'} value={form['programation_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, programation_permission: value[0]});
                        }}/>
                        </Section>

                        <Section title="Configuración">
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Roster Fecha límite'} value={form['limit_date_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, limit_date_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Guardias'} value={form['guard_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, guard_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Frentes'} value={form['front_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, front_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Zonas de Embarque'} value={form['boarding_area_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, boarding_area_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Empresas Terceras'} value={form['enterprise_premission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, enterprise_premission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Notificaciones'} value={form['notifications_users_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, notifications_users_permission: value[0]});
                        }}/>
                        </Section>

                        <Section title="Seguridad">
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Roles'} value={form['roles_users_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, roles_users_permission: value[0]});
                        }}/>
                        <div className={'mb-3'}/>
                        <LabeledSelect label={'Usuarios y Permisos'} value={form['users_permission']} options={[{value: 1, name: "Escritura"}, {value: 2, name: "Lectura"}, {value: 3, name: "Ninguno"}]} onChange={(value) => {
                            setForm({...form, users_permission: value[0]});
                        }}/>
                        </Section>

                        </div>
                        <div className={'d-flex justify-content-between pt-3 align-items-center'}>
                            <div style={{flexBasis: '45%', color: '#000000', fontWeight: '300', fontSize: '10px'}}>
                                <div>*Campos Obligatorios</div>
                            </div>
                            <div style={{flexBasis: '45%'}} className={'d-flex justify-content-end'}>
                                <div onClick={() => submit()} className={'d-flex align-items-center text-center'} style={{
                                    backgroundColor: '#E4E4E4',
                                    color: '#000000',
                                    borderRadius: '6px',
                                    padding: '6px 10px',
                                    userSelect: 'none',
                                    cursor: 'pointer'
                                }}>
                                    <FontAwesomeIcon icon={faSave} color={'#6A86C8'} size={'lg'} className={'pe-2'}/>
                                    <div style={{fontSize: '16px', fontWeight: '400'}}>Guardar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Roles'} menu={'Configuración'}/>
                <Table model={'role'} cols={cols} withIndex={true} create={() => {
                    setShowModal(true);
                    setCreate(true);
                    setForm({});
                }}
                    shouldShowActions={shouldShowActions}
                />
            </div>
        </div>
    )
}