import React from "react";
import styles from './date.module.css';

export default function Date({className, style, value, onChange, disabled, placeholder}) {
    return (
        <div className={styles.container}>
            <input type={'date'} placeholder={placeholder} disabled={disabled} className={`${className}`} style={style} value={value} onChange={(event) => {
                if(onChange) {
                    onChange(event.target.value);
                }
            }}/>
        </div>
    )
}