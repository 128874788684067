// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_checkbox__clBzy {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}

.checkbox_checkboxWrapper__vOHcs {
    display: flex;
    align-items: center;
}

.checkbox_checkboxInput__Hz6Yg {
    display: flex;
    align-items: center;
}

.checkbox_customCheckbox__gJovP {
    width: 15px;
    height: 15px;
    border: 1px solid #37477E99;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox_checkboxIcon__pE1Mq {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.checkbox_customCheckbox__gJovP.checkbox_checked__e74Bx {
    border: 1px solid #37477E99;
}

.checkbox_customCheckbox__gJovP.checkbox_checked__e74Bx .checkbox_checkboxIcon__pE1Mq {
    opacity: 1;
    background-color: #F32735;
    width: 8px;
    height: 8px;
    border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/ajonjolib/inputs/checkbox/checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".checkbox {\n    display: flex;\n    align-items: center;\n    user-select: none;\n}\n\n.checkboxWrapper {\n    display: flex;\n    align-items: center;\n}\n\n.checkboxInput {\n    display: flex;\n    align-items: center;\n}\n\n.customCheckbox {\n    width: 15px;\n    height: 15px;\n    border: 1px solid #37477E99;\n    border-radius: 2px;\n    background-color: #fff;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.checkboxIcon {\n    opacity: 0;\n    transition: opacity 0.3s ease-in-out;\n}\n\n.customCheckbox.checked {\n    border: 1px solid #37477E99;\n}\n\n.customCheckbox.checked .checkboxIcon {\n    opacity: 1;\n    background-color: #F32735;\n    width: 8px;\n    height: 8px;\n    border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `checkbox_checkbox__clBzy`,
	"checkboxWrapper": `checkbox_checkboxWrapper__vOHcs`,
	"checkboxInput": `checkbox_checkboxInput__Hz6Yg`,
	"customCheckbox": `checkbox_customCheckbox__gJovP`,
	"checkboxIcon": `checkbox_checkboxIcon__pE1Mq`,
	"checked": `checkbox_checked__e74Bx`
};
export default ___CSS_LOADER_EXPORT___;
