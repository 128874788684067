import React from "react";
import PropTypes from "prop-types";
import styles from "./fullpage_loader.module.css";

const FullPageLoader = ({ logo, message }) => {
    return (
        <div className={styles.fullpageLoader}>
            <div className={styles.loaderContent}>
                <img src={logo} alt="Loading..." className={styles.loaderLogo} />
                {message && <p className={styles.loaderMessage}>{message}</p>}
            </div>
        </div>
    );
};

FullPageLoader.propTypes = {
    logo: PropTypes.string.isRequired,
    message: PropTypes.string,
};

FullPageLoader.defaultProps = {
    logo: "/branding/stracon_logo.png",
    message: "Cargando, por favor espere...",
};

export default FullPageLoader;