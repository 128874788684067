import Select from "./select/select";
import Checkbox from "./checkbox/checkbox";
import SearchBox from "./searchbox/searchbox";
import Text from "./text/text";
import TextSearcher from "./text/text_searcher";
import TextPassword from "./text/text_password";
import Button from "./button/button";
import Radio from "./radio/radio";
import File from "./file/file";
import Date from './date/date';
import DNISelect from "./dni_select/dni_select";

export {
    Select,
    Checkbox,
    SearchBox,
    TextPassword,
    TextSearcher,
    Text,
    Button,
    Radio,
    File,
    Date,
    DNISelect,
}