import React, {useEffect, useState} from "react";
import styles from './table.module.css';
import {axiosInstance} from "../../AxiosInstance";

export default function Table({cols, withIndex, model, create, shouldShowActions, users_permissions, customBehavior = null}) {
    const [query, setQuery] = useState('');
    const [rows, setRows] = useState([]);
    const [timer, setTimer] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    
    const roles = JSON.parse(localStorage.getItem('role'));

    const getValueFromString = (object, accessString) => {
        const accessParts = accessString.split(".");
        let value = object;
    
        for (let part of accessParts) {
            if (!value) {
                return "";
            }
    
            if (part.endsWith('[0]')) {
                part = part.replace('[0]', '');
                value = value[part][0];
            } else if (part.endsWith('[]')) {
                part = part.replace('[]', '');
                value = value[part].map((item) => item.name).join(', ');
                value = value.length > 10 ? value.substring(0, 10) + '...' : value;
            }
            else {
                value = value[part];
            }
        }
        return value ? value.toString().toUpperCase() : "";
    }

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }


        const newTimer = setTimeout(() => {
            let url = `${model}/?name=${query}&page=${currentPage}&page_size=${pageSize}`;
            // TODO Improve this
            if (model === 'enterprise') {
                url += '&remote=false';
            }
            if (users_permissions) {
                url += `&users_permissions_roles=${users_permissions}`;
            }
            if (model === 'user' && customBehavior) {
                if (customBehavior?.name === 'users_without_roster') {
                    url += `&users_without_roster=true`;
                    url += `&from_date=${customBehavior.filters.startDate}`;
                    url += `&to_date=${customBehavior.filters.endDate}`;
                    url += `&project_ids=${customBehavior.filters.projectIds.join(',')}`;
                }
                if (customBehavior?.name === 'users_with_notifications')  url += '&users_with_notifications=true';
            }

            axiosInstance.get(url).then((response) => {
                let results = response.data.results ? response.data.results : response.data;
                const newTotalPages = Math.ceil(response.data.count / pageSize);
                setRows(results);
                setTotalPages(newTotalPages);
    
                // Si la página actual es mayor que el nuevo total de páginas, ajustar currentPage
                if (currentPage > newTotalPages) {
                    setCurrentPage(newTotalPages > 0 ? newTotalPages : 1);
                }
            }).catch(error => {
                // Manejar error, por ejemplo, ajustando a la página 1 si se recibe un 404
                console.error("Table:getResults:error", error);
                if (error.response && error.response.status === 404) {
                    setCurrentPage(1);
                }
            });
        }, 400);

        setTimer(newTimer);
    
        return () => {
            clearTimeout(newTimer);
        };
    
    }, [model, query, currentPage, pageSize, customBehavior]);

    useEffect(() => {
        setCurrentPage(1);
    }, [query, pageSize]);
    

    return (
        <div className={styles.container}>
            <div className={styles.head}>
                <div className={styles.searchbar}>
                    <input
                        placeholder={'Buscar'}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                </div>
                <div className={styles.pageSizeSelector}>
                    <label htmlFor="pageSize">Items por página:</label>
                    <select
                        id="pageSize"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                {shouldShowActions && create && <div className={styles.addContainer} onClick={create}>+ Nuevo</div>}
            </div>

            <div style={{
                height: 'calc(100% - 100px)', position: 'relative'
            }}>

                <div className={styles.body}>
                    <table className={`${styles.table} table table-hover`}>
                        <thead>
                        <tr>
                            {withIndex && <th style={{paddingLeft: '20px', color: '#BBBEC2'}}>N°</th>}
                            {cols?.map((col, index) => (
                                <th key={index}>
                                    <div className={'d-flex justify-content-between'}>
                                        <div className={styles.fakeMargin}/>
                                        <div style={{textAlign: 'left', width: '100%', whiteSpace: 'nowrap', color: '#BBBEC2'}}>{col.title}</div>
                                        <div className={styles.fakeMargin}/>
                                    </div>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {rows?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {withIndex && <td style={{color: '#F32735', fontWeight: '600', paddingLeft: '20px'}}>
                                    <div style={{height: '100%'}} className={'d-flex align-items-center'}>{rowIndex + 1}</div>
                                </td>}
                                {cols?.map((col, colIndex) => (
                                    <td key={colIndex} style={{backgroundColor: col.altBackground ? '#F4F6FB' : '#FFF', color: col.altBackground ? '#707D9B': '#000'}}>
                                        <div className={styles.customtd}>
                                            <div className={styles.fakeMargin}/>

                                            <div  style={{width: '100%', color: '#1A2A4D'}}>
                                            {col.image ?
                                                <img src={getValueFromString(row,col.image.url)} alt={col.title} width={"50px"} height={"50px"} style={{borderRadius: "5px", objectFit: "contain", backgroundColor: "#F7F8F9"}}/>
                                                :
                                                <>
                                                    { col.code ? <>{col.code(row, getValueFromString(row, col.internal))}</>
                                                        :
                                                        col.internal === 'dni' ?
                                                        <div style={{color: row.remote_id !== null ? '#707D9B' : '#1A2A4D', opacity: row.remote_id !== null ? 0.5 : 1}}>
                                                            {getValueFromString(row, col.internal)}
                                                        </div>
                                                        :
                                                        <div>{getValueFromString(row, col.internal)}</div>
                                                    }
                                                </>
                                            }
                                            </div>
                                            <div className={styles.fakeMargin}/>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={styles.paginationContainer}>
                <button className={styles.paginationButton} onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className={styles.paginationInfo}>Página {currentPage} de {totalPages}</span>
                <button className={styles.paginationButton} onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages}>
                    Siguiente
                </button>
            </div>
        </div>
    );
}