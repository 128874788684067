// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload_container__bnKmO {
    margin-top: 50px;
}

.upload_sleeve__XgR0P {
    min-width: 32px;
    min-height: 63px;
    width: 32px;
    height: 63px;
    margin-bottom: 30px;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.upload_sleeveUp__jZGme {
    min-width: 63px;
    min-height: 32px;
    width: 90px;
    height: 30px;
    margin: 30px 0 0 30px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    color: white;
    font-size: 1rem;
    padding-top: 8px; /* Ajuste para centrar verticalmente el texto */
}
`, "",{"version":3,"sources":["webpack://./src/screens/roster/components/upload/upload.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,4BAA4B;IAC5B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,eAAe;IACf,gBAAgB,EAAE,+CAA+C;AACrE","sourcesContent":[".container {\n    margin-top: 50px;\n}\n\n.sleeve {\n    min-width: 32px;\n    min-height: 63px;\n    width: 32px;\n    height: 63px;\n    margin-bottom: 30px;\n    border-radius: 0 10px 10px 0;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    user-select: none;\n    cursor: pointer;\n}\n\n.sleeveUp {\n    min-width: 63px;\n    min-height: 32px;\n    width: 90px;\n    height: 30px;\n    margin: 30px 0 0 30px;\n    border-radius: 10px 10px 0 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    user-select: none;\n    cursor: pointer;\n    color: white;\n    font-size: 1rem;\n    padding-top: 8px; /* Ajuste para centrar verticalmente el texto */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `upload_container__bnKmO`,
	"sleeve": `upload_sleeve__XgR0P`,
	"sleeveUp": `upload_sleeveUp__jZGme`
};
export default ___CSS_LOADER_EXPORT___;
