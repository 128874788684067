const sortItems = (items, sortBy) => {
    return items.sort((a, b) => {
        if (a[sortBy] < b[sortBy])
        return -1;

        if (a[sortBy] > b[sortBy])
        return 1;
    });
}

export default sortItems;