// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.home_graphContainer__EiWvl {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Para que los gráficos se dispongan en columna */
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  position: relative
}

.home_title__652VX {
  align-self: flex-start;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1A2A4D;
}

.home_buttonExport__0bidw {
  background: none;
  border: 1px solid green;
  border-radius: 10px;
  padding: 4px 10px;
  color: green;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/home.module.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,sBAAsB;EACtB,aAAa;EACb,sBAAsB,EAAE,kDAAkD;EAC1E,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW,EAAE,6DAA6D;EAC1E;AACF;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;AACjB","sourcesContent":["\n\n.graphContainer {\n  background-color: white;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 16px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column; /* Para que los gráficos se dispongan en columna */\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 16px;\n  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */\n  position: relative\n}\n\n.title {\n  align-self: flex-start;\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 25px;\n  color: #1A2A4D;\n}\n\n.buttonExport {\n  background: none;\n  border: 1px solid green;\n  border-radius: 10px;\n  padding: 4px 10px;\n  color: green;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphContainer": `home_graphContainer__EiWvl`,
	"title": `home_title__652VX`,
	"buttonExport": `home_buttonExport__0bidw`
};
export default ___CSS_LOADER_EXPORT___;
