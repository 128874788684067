import React, {useEffect, useState, useContext} from "react";
import RosterContext from "../../RosterContext";
import {PermissionType} from "../../PermissionType";
import Sidebar from "../../components/sidebar/sidebar";
import PageHeader from "./components/page_header/page_header";
import StatBox from "./components/stat_box/stat_box";
import RosterBox from "./components/roster_box/roster_box";
import Modal from "../../components/modal/modal";
import stats from "../../utils/event_types";
import LabeledSelect from "../../ajonjolib/inputs/labeled_select/labeled_select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faFilter, faSave} from "@fortawesome/free-solid-svg-icons";
import {toast, ToastTypes} from "../../ajonjolib/toasts/toast/toast";
import {useLocation} from "react-router-dom";
import RosterUpload from "./components/upload/upload";
import styles from './roster.module.css';
import {Button, File, Select, Date as AjonjoDate} from "../../ajonjolib/inputs/ajonjolinput";
import {axiosInstance} from "../../AxiosInstance";
import CreateEventModal from "./components/create_event/create_event";
import ScrollTop from "./components/scroll_top/scroll_top";
import axios from 'axios';
import { ScreenType } from "../../ScreenType";
import { RosterScreenType } from "../../ScreenType";
import { useFullPageLoader } from "../../contexts/fullpage_loader_context";


export default function Roster() {
    const [query, setQuery] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showCloudModal, setShowCloudModal] = useState(false);
    const [cloudModalErrors, setCloudModalErrors] = useState([]);
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [form, setForm] = useState({});
    const [localFilters, setLocalFilters] = useState([]);
    const [eventFilters, setEventFilters] = useState([]);
    const [countByAssignation, setCountByAssignation] = useState({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const location = useLocation();
    const approvalView = location.pathname.includes('/approve');

    const [guards, setGuards] = useState([]);
    const [boardingAreas, setBoardingAreas] = useState([]);
    const [positions, setPositions] = useState([]);
    const [areas, setAreas] = useState([]);
    const [fronts, setFronts] = useState([]);
    const [genders, setGenders] = useState([{id: 0, name: 'Masculino'}, {id: 1, name: 'Femenino'}]);
    const [checkboxes, setCheckboxes] = useState({});

    const [pendingCount, setPendingCount] = useState(0);

    const [rows, setRows] = useState([]);

    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const [eventFiltersComplete, setEventFiltersComplete] = useState(stats);

    const [toApproveCounter, setToApproveCounter] = useState(0);

    const {roleManager} = useContext(RosterContext);
    const shouldShowPlannerActions = roleManager.getRosterPermission() == PermissionType.WRITE;
    const shouldShowApproverActions = roleManager.getRosterApprovePermission() == PermissionType.WRITE;

    const { showFullPageLoader, hideFullPageLoader } = useFullPageLoader();

    const triggerRefresh = () => {
        setRefreshTrigger(current => current + 1);
    };

    useEffect(() => {
        axiosInstance.get('/guard').then((response) => {
            setGuards(response.data.results);
        });

        axiosInstance.get('/boarding_area').then((response) => {
            setBoardingAreas(response.data.results);
        });

        axiosInstance.get('/front').then((response) => {
            setFronts(response.data.results);
        });

        axiosInstance.get('/area').then((response) => {
            setAreas(response.data);
        });

        axiosInstance.get('/position').then((response) => {
            setPositions(response.data);
        });

        let local_filters_value = null;
        let stat_filters_value = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,];
        if (localStorage.getItem('local_filters')) {
            local_filters_value = JSON.parse(localStorage.getItem('local_filters'));
        }

        if (localStorage.getItem('stat_filters')) {
            stat_filters_value = JSON.parse(localStorage.getItem('stat_filters'));
        }

        setLocalFilters(local_filters_value || [0, 1, 2, 3, 4, 5, 6, 7, 8])
        setEventFilters(stat_filters_value || Array.from(Array(stats.length).keys()));
        const filteredStats = stats.filter((stat, index) => stat_filters_value.includes(index));
        setEventFiltersComplete(filteredStats);
        setForm({...form, events: stat_filters_value || Array.from(Array(stats.length).keys()), local_filters: local_filters_value || [0, 1, 2]})
    }, []);

    useEffect(() => {
        console.log("Change in checkboxes", checkboxes);
        const selectedRosterIds = Object.entries(checkboxes)
        .filter(([key, value]) => value)
        .map(([key]) => {
            const index = parseInt(key.split('_')[1], 10);
            // Acceder al objeto roster en la posición correspondiente.
            const roster = rows[index]?.roster;
            if (!roster) {
                return undefined;
            }
            // Obtener las claves del objeto roster (fechas) y iterar sobre ellas.
            return Object.keys(roster).flatMap(date => {
                // Para cada fecha, obtener el primer id de roster si existe.
                const firstRoster = roster[date];
                if (firstRoster && firstRoster.length > 0) {
                    return firstRoster[0].id; // Retorna el id del primer objeto en la lista para esa fecha.
                }
                return [];
            });
        })
        .flat() // Aplanar el arreglo de arreglos resultante en un solo arreglo.
        .filter(id => id !== undefined); // Filtrar cualquier id indefinido.
        
        if ('pending' in checkboxes && checkboxes['pending']) {
            const countTrue = pendingCount;
            setToApproveCounter(countTrue);
        }
        else {
            const countTrue = Object.values(checkboxes).filter(value => value === true).length;
            setToApproveCounter(countTrue);
        }

    }, [checkboxes]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [guardsResponse, boardingAreasResponse, frontsResponse, areasResponse, positionsResponse] = await Promise.all([
                    axiosInstance.get('/guard'),
                    axiosInstance.get('/boarding_area'),
                    axiosInstance.get('/front'),
                    axiosInstance.get('/area'),
                    axiosInstance.get('/position'),
                ]);

                let guards = guardsResponse.data.results;
                let boardingAreas = boardingAreasResponse.data.results;
                let fronts = frontsResponse.data.results;
                let areas = areasResponse.data;
                let positions = positionsResponse.data;

                console.log('Filtering by project:', query['project']);
                if (query['project']) {
                    guards = guards.filter((guard) => query['project'].includes(guard.project.id));
                    boardingAreas = boardingAreas.filter((boardingArea) => query['project'].includes(boardingArea.project.id));
                    fronts = fronts.filter((front) => query['project'].includes(front.project.id));
                    areas = areas.filter((area) => query['project'].includes(area.project));
                    positions = positions.filter((position) => query['project'].includes(position.project.id));
                }

                setGuards(guards);
                setBoardingAreas(boardingAreas);
                setFronts(fronts);
                setAreas(areas);
                setPositions(positions);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [query['project']]);


    const submit = () => {
        localStorage.setItem('local_filters', JSON.stringify(form['local_filters']));
        setLocalFilters(form['local_filters']);

        localStorage.setItem('stat_filters', JSON.stringify(form['events']));
        setEventFilters(form['events']);
        const filteredStats = stats.filter((stat, index) => form['events'].includes(index));
        setEventFiltersComplete(filteredStats);
        triggerRefresh();

        setShowModal(false);
        toast('Configuración guardada', ToastTypes.SUCCESS);
    }

    const downloadErrors = () => {
        axiosInstance.post('download/errors/', cloudModalErrors, {
            responseType: 'blob'
        }).then((response) => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'errors.xlsx');
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    return (
        <div>
            <Sidebar selected={ScreenType.ROSTER} selectedSubmenu={approvalView ? RosterScreenType.ROSTER_APPROVE : RosterScreenType.ROSTER}/>

            <CreateEventModal query={query} triggerRefresh={triggerRefresh}/>

            {showFilterModal &&
                <Modal title={'Filtrar'} subtitle={'Filtros de Búsqueda'} close={() => setShowFilterModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between align-items-center'} style={{ textAlign: 'left', color: '#666666', fontSize: '11px' }}>
                            <div style={{ flexBasis: '45%' }}>
                                <div>Fecha de Ingreso</div>
                                <AjonjoDate value={query['in_date']} onChange={(val) => {
                                    setQuery({ ...query, in_date: val })
                                }} />
                                <div className={'pt-4'}>Áreas</div>
                                <Select value={query['area']} options={[{ value: null, name: "Ninguno" }, ...(Array.isArray(areas) ? areas.map((g) => { return { value: g.id, name: g.name } }) : [])]} onChange={(val) => {
                                    setQuery({ ...query, area: val[0] })
                                }} />
                                <div className={'pt-4'}>Guardias</div>
                                <Select value={query['guard']} options={[{ value: null, name: 'Ninguno' }, ...(Array.isArray(guards) ? guards.map((g) => { return { value: g.id, name: g.name } }) : [])]} onChange={(val) => {
                                    setQuery({ ...query, guard: val[0] })
                                }} />
                                <div className={'pt-4'}>Zonas de Embarque</div>
                                <Select value={query['boarding_zone']} options={[{ value: null, name: 'Ninguno' }, ...(Array.isArray(boardingAreas) ? boardingAreas.map((g) => { return { value: g.id, name: g.name } }) : [])]} onChange={(val) => {
                                    setQuery({ ...query, boarding_zone: val[0] });
                                }} />
                                <div className={'pb-4'} />
                            </div>
                            <div style={{ flexBasis: '45%' }}>
                                <div>Fecha de Transferencia</div>
                                <AjonjoDate value={query['transfer_date']} onChange={(val) => {
                                    setQuery({ ...query, transfer_date: val })
                                }} />
                                <div className={'pt-4'}>Puestos</div>
                                <Select value={query['position']} options={[{ value: null, name: 'Ninguno' }, ...(Array.isArray(positions) ? positions.map((g) => { return { value: g.id, name: g.name } }) : [])]} onChange={(val) => {
                                    setQuery({ ...query, position: val[0] });
                                }} />
                                <div className={'pt-4'}>Frentes</div>
                                <Select value={query['front']} options={[{ value: null, name: 'Ninguno' }, ...(Array.isArray(fronts) ? fronts.map((g) => { return { value: g.id, name: g.name } }) : [])]} onChange={(val) => {
                                    setQuery({ ...query, front: val[0] })
                                }} />
                                <div className={'pt-4'}>Sexo</div>
                                <Select value={query['gender']} options={[{ value: null, name: 'Ninguno' }, ...(Array.isArray(genders) ? genders.map((gender) => { return { value: gender.id, name: gender.name } }) : [])]} onChange={(val) => {
                                    setQuery({ ...query, gender: val ? val[0] : null });
                                }} />
                                <div className={'pb-4'} />
                            </div>
                        </div>
                        <div className={'d-flex justify-content-end'}>
                            <div onClick={() => {
                                setShowFilterModal(false);
                                // Your function to trigger refresh
                            }} className={'d-flex align-items-center'} style={{
                                color: '#FFF', padding: '6px 12px', borderRadius: '6px', cursor: 'pointer',
                                backgroundColor: '#6A86C8'
                            }}>
                                <FontAwesomeIcon className={'pe-2'} icon={faFilter} color={'#FFF'} />
                                <div>Filtrar</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {showModal &&
                <Modal title={'Roster'} subtitle={'Configuración'} close={() => setShowModal(false)}>
                    <LabeledSelect value={form['events']} onChange={(value) => {
                        setForm({...form, events: value});
                        
                    }} showQuantity={true} placeholder={'Eventos'} multi={true} options={stats.map((stat, index) => ({value: index, name: stat.name}))} label={'Stats Box'}/>
                    {form['events']?.length > 0 && <div className={'d-flex flex-wrap'} style={{marginTop: '9px', backgroundColor: '#F2F2F2', borderRadius: '7px', padding: '6px'}}>
                        {form['events']?.map((event, index) => (
                            <div className={'my-1 mx-1'} style={{
                                backgroundColor: '#FFFFFF', border: '#C7C7C7 1px solid',
                                fontSize: '10px', fontWeight: '400', color: '#8B8B8B',
                                borderRadius: '6px', padding: '2px 5px', userSelect: 'none'
                            }}>{stats[event].name}</div>
                            )
                        )}
                    </div>
                    }
                    <div style={{height: '1px', width: '100%', backgroundColor: '#EFEFEF', margin: '20px 0'}}/>
                    <LabeledSelect value={form['local_filters']} onChange={(val) => {
                        setForm({...form, local_filters: val});
                    }} 
                        multi={true}
                        options={
                            [{value: 0, name: 'Status'}, {value: 1, name: 'Puesto'},
                            {value: 2, name: 'Area'}, {value: 3, name: 'Clasificador'},
                            {value: 4, name: 'Subclasificador'}, {value: 5, name: 'Proyecto'},
                            {value: 6, name: 'Grupo de Proyecto'}, {value: 7, name: 'Empresa'},
                            {value: 8, name: 'Saldos'}
                            ]
                        }
                        label={'Cuadro Roster'}
                    />

                    <div style={{flexBasis: '45%'}} className={'d-flex justify-content-end pt-3'}>
                        <div onClick={() => submit()} className={'d-flex align-items-center text-center'} style={{
                            backgroundColor: '#E4E4E4',
                            color: '#000000',
                            borderRadius: '6px',
                            padding: '6px 10px',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <FontAwesomeIcon icon={faSave} color={'#000000'} size={'sm'} className={'pe-2'}/>
                            <div style={{fontSize: '11px', fontWeight: '400'}}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }

            {showCloudModal &&
                <Modal title={'Importar'} subtitle={'Roster'} close={() => {setShowCloudModal(false); setCloudModalErrors([]);}}>
                    <div>
                    <div className={'d-flex justify-content-between'}>
                            <div style={{textAlign: 'left', flexBasis: '45%'}}>
                                <div style={{fontSize: '13px', color: '#666666'}}>Descargar Plantilla</div>
                                <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                    Se descarga la plantilla para el roster.
                                </div>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.templateButton} onClick={() => {
                                    showFullPageLoader('Descargando plantilla, espere unos momentos');
                                    axiosInstance.get(`project/roster/template/?start_date=${query['start_date']}&end_date=${query['end_date']}`, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        hideFullPageLoader();
                                        if(response.status === 200) {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'roster_template.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    });
                                }}>
                                    <FontAwesomeIcon className={'pe-2'} icon={faFileExcel} color={'#FFF'} size={'lg'}/>
                                    <div>Descargar Plantilla</div>
                                </div>
                            </div>
                        </div>
                        <div className={'mt-3'}/>
                        <div style={{fontSize: '11px', color: '#666666', textAlign: 'left'}}>Subir Roster</div>
                        <File onChange={(files) => {
                            setForm({...form, file: files[0]});
                        }}/>
                        {cloudModalErrors.length > 0 && (
                            <div>
                                <div style={{ fontSize: '11px', color: '#F32735', textAlign: 'left', marginTop: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontWeight: '500', marginRight: '5px' }}>ERROR AL SUBIR ARCHIVO</div>
                                        <div style={{ fontWeight: '300' }}>con los siguientes trabajadores</div>
                                    </div>
                                </div>
                                <div className={styles.container} style={{ backgroundColor: "#FFD5D8", borderRadius: "10px", padding: "10px" }}>
                                    <ul>
                                        {cloudModalErrors?.slice(0, 4).map((error, index) => (
                                            <li key={index} style={{ position: 'relative', listStyle: 'disc', fontSize: '11px', color: '#F32735' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ fontWeight: '400', fontSize: '11px', color: "#494B50" }}>{error.dni}</div>
                                                    <div style={{ marginRight: '5px', marginLeft: '5px' }}> - </div>
                                                    <div style={{ fontWeight: '400', fontSize: '11px', color: "#494B50" }}>{error.name}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center'}}>
                                    <div className={styles.templateButton} onClick={downloadErrors} style={{backgroundColor: "#E72D39", width: "258px", height: "44px"}}>
                                        <FontAwesomeIcon className={'pe-2'} icon={faFileExcel} color={'#FFF'} size={'lg'} />
                                        <div>Descargar Observaciones</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                        <div className={'d-flex mt-3'} style={{justifyContent: 'flex-end'}}>
                            <Button variant={'success'} onSubmit={() => {
                                showFullPageLoader('Subiendo roster, espere unos momentos');
                                let formData = new FormData();
                                formData.append('file', form['file'])
                                axiosInstance.post(`roster/upload/`, formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then((response) => {
                                    hideFullPageLoader();
                                    console.log('Response:', response)
                                    if (response.data?.errors?.length > 0) {
                                        toast('Error al subir el roster', ToastTypes.ERROR);
                                        setCloudModalErrors(response.data.errors);
                                    } else {
                                        if (response.response?.data?.message && response.response.data.message.includes('Fecha')) {
                                            toast(response.response.data.message, ToastTypes.ERROR);
                                        } else {
                                            setShowCloudModal(false);
                                            toast('Roster subido exitosamente', ToastTypes.SUCCESS);
                                            setCloudModalErrors([]);
                                            triggerRefresh();
                                        }
                                    }
                                });
                            }}>
                                <div className={'d-flex align-items-center'}>
                                    <FontAwesomeIcon className={'pe-2'} icon={faSave} color={'#FFF'}/>
                                    <div>Guardar</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            {showExcelModal &&
                <Modal title={'Exportar'} subtitle={'Roster'} close={() => setShowExcelModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between'}>
                            <div style={{textAlign: 'left', flexBasis: '45%'}}>
                                <div style={{fontSize: '13px', color: '#666666'}}>Descargar Roster</div>
                                <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                    Se descarga el roster con los datos aprobados actuales.
                                </div>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.templateButton} onClick={() => {
                                    showFullPageLoader('Descargando roster, espere unos momentos');
                                    axiosInstance.get(`projects/${query['project']}/roster/format/?start_date=${query['start_date']}&end_date=${query['end_date']}`, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        hideFullPageLoader();
                                        if(response.status === 200) {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'roster.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    });
                                }}>
                                    <FontAwesomeIcon className={'pe-2'} icon={faFileExcel} color={'#FFF'} size={'lg'}/>
                                    <div>Descargar Roster</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {showSuccessModal &&
                <Modal customHeader={[
                        <div>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <div style={{
                                    color: '#2C3555', fontWeight: '600', paddingLeft: '20px',
                                    fontSize: '32px', textAlign: 'left', letterSpacing: '8%'
                                }}>
                                    <div>Aprobación</div>
                                    <div>de Roster</div>
                                </div>
                                <img src={'/branding/tracks_small.png'} width={'230px'}/>
                            </div>
                        </div>
                    ]
                } title={'Aprobar'} subtitle={'Roster'} close={() => setShowSuccessModal(false)}>
                    <div>
                        <div style={{
                            color: '#2C3555', fontSize: '18px',
                            display: 'flex', alignItems: 'center'
                        }}>
                            <div>Se encuentra por aprobar</div>
                            <div style={{
                                backgroundColor: '#EAFFE2', borderRadius: '8px', padding: '0 10px', margin: '0 4px'
                            }}>{toApproveCounter}</div>
                            <div>rosters.</div>
                        </div>
                        <div style={{
                            color: '#588919', textAlign: 'left', fontSize: '15px'
                        }}>Confirme su aprobación.</div>
                    </div>
                    <div className={'mt-5 d-flex justify-content-center'}>
                    <div
                        style={{
                            backgroundColor: '#5A8F47', color: '#FFF', padding: '10px', borderRadius: '43px',
                            width: '180px', cursor: 'pointer', userSelect: 'none'
                        }}
                        onClick={() => {
                            if ('pending' in checkboxes && checkboxes['pending']) {
                                showFullPageLoader('Aprobando roster, espere unos momentos');
                                delete checkboxes['pending'];
                                axiosInstance.post('roster/validate/', {
                                    all_rosters: true,
                                    start_date: query['start_date'],
                                    end_date: query['end_date'],
                                    project: query['project'],
                                    value: true
                                }).then((response) => {
                                    if(response.status === 200) {
                                        setShowSuccessModal(false);
                                        setShowRejectModal(false);
                                        toast('Roster aprobado exitosamente', ToastTypes.SUCCESS);
                                        triggerRefresh();
                                    }
                                }).catch((error) => {
                                    setShowSuccessModal(false);
                                    console.error('Error al aprobar el roster:', error);
                                    toast('Error al aprobar el roster', ToastTypes.ERROR);
                                });
                            } else {
                            const selectedRosterIds = Object.entries(checkboxes)
                                .filter(([key, value]) => value)
                                .map(([key]) => {
                                    const index = parseInt(key.split('_')[1], 10);
                                    // Acceder al objeto roster en la posición correspondiente.
                                    const roster = rows[index]?.roster;
                                    if (!roster) {
                                        return undefined;
                                    }
                                    // Obtener las claves del objeto roster (fechas) y iterar sobre ellas.
                                    return Object.keys(roster).flatMap(date => {
                                        // Para cada fecha, obtener el primer id de roster si existe.
                                        const firstRoster = roster[date];
                                        if (firstRoster && firstRoster.length > 0) {
                                            return firstRoster[0].id; // Retorna el id del primer objeto en la lista para esa fecha.
                                        }
                                        return [];
                                    });
                                })
                                .flat() // Aplanar el arreglo de arreglos resultante en un solo arreglo.
                                .filter(id => id !== undefined); // Filtrar cualquier id indefinido.
                            
                            showFullPageLoader('Aprobando roster, espere unos momentos');
                            axiosInstance.post('roster/validate/', {
                                start_date: query['start_date'],
                                end_date: query['end_date'],
                                project: query['project'],
                                rosters: selectedRosterIds,
                                value: true
                            }).then((response) => {
                                hideFullPageLoader();
                                if(response.status === 200) {
                                    setShowSuccessModal(false);
                                    toast('Roster aprobado exitosamente', ToastTypes.SUCCESS);
                                    triggerRefresh();
                                }
                            }).catch((error) => {
                                hideFullPageLoader();
                                console.error('Error al aprobar el roster:', error);
                                toast('Error al aprobar el roster', ToastTypes.ERROR);
                            });
                        }}}

                    >
                        Aprobar
                    </div>
                </div>
                </Modal>
            }

            {showRejectModal &&
                <Modal customHeader={[
                    <div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div style={{
                                color: '#2C3555', fontWeight: '600', paddingLeft: '20px',
                                fontSize: '32px', textAlign: 'left', letterSpacing: '8%'
                            }}>
                                <div>Rechazo</div>
                                <div>de Roster</div>
                            </div>
                            <img src={'/branding/tracks_small.png'} width={'230px'}/>
                        </div>
                    </div>
                ]
                } title={'Rechazar'} subtitle={'Roster'} close={() => setShowRejectModal(false)}>
                    <div>
                        <div style={{
                            color: '#2C3555', fontSize: '18px',
                            display: 'flex', alignItems: 'center'
                        }}>
                            <div>Se encuentra por rechazar</div>
                            <div style={{
                                backgroundColor: '#FFD5D8', borderRadius: '8px', padding: '0 10px', margin: '0 4px'
                            }}>{toApproveCounter}</div>
                            <div>rosters.</div>
                        </div>
                        <div style={{
                            color: '#BA242E', textAlign: 'left', fontSize: '15px'
                        }}>Confirme su rechazo.</div>
                    </div>
                    <div className={'mt-5 d-flex justify-content-center'}>
                        <div style={{
                            backgroundColor: '#F32735', color: '#FFF', padding: '10px', borderRadius: '43px', width: '180px',
                            cursor: 'pointer', userSelect: 'none'
                        }}
                        onClick={() => {
                            if ('pending' in checkboxes && checkboxes['pending']) {
                                showFullPageLoader('Rechazando roster, espere unos momentos');
                                delete checkboxes['pending'];
                                axiosInstance.post('roster/validate/', {
                                    all_rosters: true,
                                    start_date: query['start_date'],
                                    end_date: query['end_date'],
                                    project: query['project'],
                                    value: false
                                }).then((response) => {
                                    hideFullPageLoader();
                                    if(response.status === 200) {
                                        setShowRejectModal(false);
                                        toast('Roster denegado exitosamente', ToastTypes.SUCCESS);
                                        triggerRefresh();
                                    }
                                }).catch((error) => {
                                    hideFullPageLoader();
                                    console.error('Error al rechazar el roster:', error);
                                    toast('Error al rechazar el roster', ToastTypes.ERROR);
                                });
                            } else {
                            const selectedRosterIds = Object.entries(checkboxes)
                                .filter(([key, value]) => value)
                                .map(([key]) => {
                                    const index = parseInt(key.split('_')[1], 10);
                                    // Acceder al objeto roster en la posición correspondiente.
                                    const roster = rows[index]?.roster;
                                    if (!roster) {
                                        return undefined;
                                    }
                                    // Obtener las claves del objeto roster (fechas) y iterar sobre ellas.
                                    return Object.keys(roster).flatMap(date => {
                                        // Para cada fecha, obtener el primer id de roster si existe.
                                        const firstRoster = roster[date];
                                        if (firstRoster && firstRoster.length > 0) {
                                            return firstRoster[0].id; // Retorna el id del primer objeto en la lista para esa fecha.
                                        }
                                        return [];
                                    });
                                })
                                .flat() // Aplanar el arreglo de arreglos resultante en un solo arreglo.
                                .filter(id => id !== undefined); // Filtrar cualquier id indefinido.
                            
                            showFullPageLoader('Rechazando roster, espere unos momentos');
                            axiosInstance.post('roster/validate/', {
                                start_date: query['start_date'],
                                end_date: query['end_date'],
                                project: query['project'],
                                rosters: selectedRosterIds,
                                value: false
                            }).then((response) => {
                                hideFullPageLoader();
                                if(response.status === 200) {
                                    setShowRejectModal(false);
                                    toast('Roster denegado exitosamente', ToastTypes.SUCCESS);
                                    triggerRefresh();
                                }
                            }).catch((error) => {
                                hideFullPageLoader();
                                console.error('Error al rechazar el roster:', error);
                                toast('Error al rechazar el roster', ToastTypes.ERROR);
                            });
                        }}}
                    >Rechazar</div>
                    </div>
                </Modal>
            }

            <ScrollTop/>

            <div className={'mainContainer'}>
                <PageHeader approvalView={approvalView} query={query} setQuery={setQuery} setShowModal={setShowModal} setShowFilterModal={setShowFilterModal}/>
                <StatBox events={form['events']} eventFiltersComplete={eventFiltersComplete} countByAssignation={countByAssignation}/>
                <div className={'d-flex flex-column'}>
                    <RosterUpload setShowCloudModal={setShowCloudModal} setShowExcelModal={setShowExcelModal} shouldShowPlannerActions={shouldShowPlannerActions}/>
                    <RosterBox setQuery={setQuery} setShowApprove={() => setShowSuccessModal(true)} setShowReject={() => setShowRejectModal(true)}
                        localFilters={localFilters} approve={approvalView} query={query} checkboxes={checkboxes} setCheckboxes={setCheckboxes}
                        rows={rows} setRows={setRows} refreshTrigger={refreshTrigger}
                        shouldShowApproverActions={shouldShowApproverActions} shouldShowPlannerActions={shouldShowPlannerActions}
                        setCountByAssignation={setCountByAssignation}
                        pendingCount={pendingCount} setPendingCount={setPendingCount}
                    />
                </div>
            </div>
        </div>
    )
}