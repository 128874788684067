// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_event_date__t31fR {
    display: flex;
    align-items: center;
    color: #C1C1C1;
    font-weight: 400;
    font-size: 13px;
    flex-wrap: nowrap;
}

.create_event_date__t31fR div {
    display: flex;
    flex-wrap: nowrap;
}

.create_event_date__t31fR input[type='date'] {
    color: #6A86C8;
    border: #D6E0F4 solid 1px;
    padding: 8px 28px;
}

.create_event_date__t31fR div:last-child {
    border-radius: 10px;
}

.create_event_date__t31fR input[type='date']:focus {
    border: none !important;
    outline: none !important;
}

.create_event_date__t31fR input[type='date']:first-of-type {
    border-radius: 10px 0 0 10px;
    background-color: #FFFFFF;
}

.create_event_date__t31fR input[type='date']:last-of-type {
    background-color: #EDF2FE;
    border-radius: 0 10px 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/screens/roster/components/create_event/create_event.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;AAChC","sourcesContent":[".date {\n    display: flex;\n    align-items: center;\n    color: #C1C1C1;\n    font-weight: 400;\n    font-size: 13px;\n    flex-wrap: nowrap;\n}\n\n.date div {\n    display: flex;\n    flex-wrap: nowrap;\n}\n\n.date input[type='date'] {\n    color: #6A86C8;\n    border: #D6E0F4 solid 1px;\n    padding: 8px 28px;\n}\n\n.date div:last-child {\n    border-radius: 10px;\n}\n\n.date input[type='date']:focus {\n    border: none !important;\n    outline: none !important;\n}\n\n.date input[type='date']:first-of-type {\n    border-radius: 10px 0 0 10px;\n    background-color: #FFFFFF;\n}\n\n.date input[type='date']:last-of-type {\n    background-color: #EDF2FE;\n    border-radius: 0 10px 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `create_event_date__t31fR`
};
export default ___CSS_LOADER_EXPORT___;
