import React, {useEffect, useState, useContext} from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import styles from "../projects/projects.module.css";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faUserLock} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/modal/modal";
import {Select, Text} from "../../../ajonjolib/inputs/ajonjolinput";
import Switch from "../../../ajonjolib/inputs/switch/switch";
import ModalBottom from "../../../components/modal_bottom/modal_bottom";
import {axiosInstance} from "../../../AxiosInstance";
import {toast, ToastTypes} from "../../../ajonjolib/toasts/toast/toast";
import { ScreenType, SettingsScreenType } from "../../../ScreenType";

export default function Guards() {
    const [showModal, setShowModal] = useState(false);
    const [create, setCreate] = useState(true);
    const [form, setForm] = useState({});
    const [groupProjects, setGroupProjects] = useState([]);
    const [groupProjectId, setGroupProjectId] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    
    const {roleManager} = useContext(RosterContext);

    const shouldShowActions = roleManager.getSettingsGuardsPermission() == PermissionType.WRITE;
    const cols = [
        {title: 'Estado', internal: 'active', code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }}/>
                )
            }},
        {title: 'Proyecto', internal: 'project.name', altBackground: true, code: (row, data) => {
                return <div style={{padding:'0 40px'}}>
                    <div style={{minWidth: '170px', maxWidth: '170px', textWrap: 'wrap'}}>{data}</div>
                </div>
        }
        },
        {title: 'Guardia', internal: 'name'},
    ]

    if (shouldShowActions) {
        cols.push(
            {title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-start'}>
                        <FontAwesomeIcon color={'#6A86C8'} icon={faPenToSquare} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                            let copy = {...row};
                            copy['project'] = row['project']?.id;
                            setShowModal(true);
                            setCreate(false);
                            setForm(copy);
                        }}/>
                    </div>
                )
            }}
        )
    }

    useEffect(() => {
        document.title = "Guardias | Roster";

        axiosInstance.get('project_group/').then((response) => {
            setGroupProjects(response.data);
        });
        axiosInstance.get('project/').then((response) => {
            setAllProjects(response.data.results);
            setProjects(response.data.results);
        });
    }, []);

    useEffect(() => {
        if (!showModal) {
            setForm({});
            setProjects(allProjects);
        }
        console.log("new values", form['guard'], form['front']);
    } , [showModal]);

    useEffect(() => {
        setForm({...form, project: null, guard: null, front: null, boarding_area: null});
        if (groupProjectId) {
            const filteredProjects = allProjects.filter(project => project.group === groupProjectId);
            setProjects(filteredProjects);
        } else {
            setProjects(allProjects);
        }
    }, [groupProjectId]);

    const handleGroupProjectChange = (selectedGroupProjectId) => {
        const filteredProjects = allProjects.filter(project => project.group === selectedGroupProjectId);
        setProjects(filteredProjects);
        setForm({...form, project: null});
        setGroupProjectId(selectedGroupProjectId);
    }

    const submit = () => {
        const handleResponse = (response) => {
            console.log(response);
            if (response.response) {
                response = response.response;
            }
            if (response.status == 400) {
                console.log(response.data);
                let message = 'Ocurrió un error';
                if (response.data.non_field_errors?.length > 0) {
                    message = response.data.non_field_errors[0];
                }
                toast(message, ToastTypes.ERROR);
            } else {
                setShowModal(false);
                window.location.reload();
            }
        };
        
        const saveGuard = (form, create) => {
            const request = create ? axiosInstance.post('guard/', form) : axiosInstance.put(`guard/${form.id}/`, form);
            request.then(handleResponse);
        };

        if(!form['name'] || !form['project']) {
            toast('Por favor, complete todos los campos obligatorios', ToastTypes.ERROR);
            return;
        } else {
            saveGuard(form, create);
        }
    }

    return (
        <div>
            <Sidebar selected={ScreenType.SETTINGS} selectedSubmenu={SettingsScreenType.GUARDS}/>

            {showModal &&
                <Modal title={'Guardias'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowModal(false)}>
                    <div style={{textAlign: 'left'}}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Nombre de Guardia</div>
                                <Text value={form['name']} onChange={(val) => setForm({...form, name: val})}/>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Estado</div>
                                <Switch value={form['status']} onChange={(value) => {
                                    setForm({...form, status: value});
                                }}/>
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '100%'}}>
                                <div className={styles.requiredInput}>Grupo de Proyecto</div>
                                <Select 
                                    value={
                                        (() => {
                                            const formProjectId = form['project'];
                                            const project = projects.find(project => project.id === formProjectId);
                                            if (project) {
                                                const groupProject = groupProjects.find(groupProject => groupProject.id === project.group);
                                                return groupProject ? groupProject.id : '';
                                            }
                                            return '';
                                        })()
                                    }
                                    options={groupProjects.map((project) => {
                                        return {value: project.id, name: project.name};
                                    })}
                                    onChange={(val) => {
                                        handleGroupProjectChange(val[0]);
                                    }}
                                />
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '100%'}}>
                                <div className={styles.requiredInput}>Proyecto</div>
                                <Select key={groupProjectId || 'default-key'} value={form['project']} options={projects.map((project) => {return {value: project.id, name: project.name}})} onChange={(val) => {
                                    setForm({...form, project: val[0]});
                                }}/>
                            </div>
                        </div>

                        <ModalBottom submit={submit}/>
                    </div>
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Guardias'} menu={'Configuración'}/>
                <Table model={'guard'} cols={cols} withIndex={true} create={() => {
                    setShowModal(true);
                    setCreate(true);
                    setForm({});
                }}
                    shouldShowActions={shouldShowActions}    
                />
            </div>
        </div>
    )
}