import React, {useContext, useEffect, useState} from "react";
import styles from './sidebar.module.css';
import {faCalendar, faChevronDown, faChevronUp, faCog, faHome, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import SidebarButton from "../sidebar_button/sidebar_button";
import RosterContext from "../../RosterContext";
import LogoutButton from "../logout_button/logout_button";
import { PermissionType } from "../../PermissionType";
import { ScreenType, RosterScreenType, SettingsScreenType, SecurityScreenType } from "../../ScreenType";
import { axiosInstance } from "../../AxiosInstance";

export default function Sidebar({selected, selectedSubmenu}) {
    const {setSidebarOpen, sidebarOpen, roleManager} = useContext(RosterContext);
    const navigate = useNavigate();
    const [menus, setMenus] = useState([]);
    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        let finalMenus = [];

        if(!roleManager.roles || roleManager.roles.length === 0) {
            navigate('/login');
            return;
        }
        if (roleManager.getDashboardPermission() != PermissionType.NOT_ALLOWED) {
            finalMenus.push({name: ScreenType.DASHBOARD, url: '/', iconA: '/misc/dashboard_a.png', iconB: '/misc/dashboard_b.png', iconWidth: '28px'});
        }
        if (roleManager.getUsersPermission() != PermissionType.NOT_ALLOWED) {
            finalMenus.push({name: ScreenType.USER_MASTER, url: '/settings/users', iconA: '/misc/users_a.png', iconWidth: '32px', iconB: '/misc/users_b.png'});
        }
        let rosterSubmenus = [];
        if (roleManager.getRosterPermission() != PermissionType.NOT_ALLOWED) {
            rosterSubmenus.push({name: RosterScreenType.ROSTER, url: '/roster'});
        }

        if (roleManager.getRosterApprovePermission() != PermissionType.NOT_ALLOWED) {
            rosterSubmenus.push({name: RosterScreenType.ROSTER_APPROVE, url: '/roster/approve'});
        }

        if (roleManager.getRosterBalancesPermission() != PermissionType.NOT_ALLOWED) {
            rosterSubmenus.push({name: RosterScreenType.BALANCES, url: '/settings/balances'});
        }

        if (rosterSubmenus.length > 0) {
            finalMenus.push({name: ScreenType.ROSTER, iconB: '/misc/roster_b.png', iconA: '/misc/roster_a.png', iconWidth: '28px', submenus: rosterSubmenus});
        }

        let settingsSubmenus = [];

        if (roleManager.getSettingsRosterDateLimitPermission() != PermissionType.NOT_ALLOWED) {
            settingsSubmenus.push({name: SettingsScreenType.LIMIT_DATES, url: '/settings/projects'});
        }

        if (roleManager.getSettingsGuardsPermission() != PermissionType.NOT_ALLOWED) {
            settingsSubmenus.push({name: SettingsScreenType.GUARDS, url: '/settings/guards'});
        }

        if (roleManager.getSettingsFrontsPermission() != PermissionType.NOT_ALLOWED) {
            settingsSubmenus.push({name: SettingsScreenType.FRONTS, url: '/settings/fronts'});
        }

        if (roleManager.getSettingsBoardingZonesPermission() != PermissionType.NOT_ALLOWED) {
            settingsSubmenus.push({name: SettingsScreenType.BOARDING_ZONES, url: '/settings/boarding-zones'});
        }

        if (roleManager.getSettingsEnterprisesPermission() != PermissionType.NOT_ALLOWED) {
            settingsSubmenus.push({name: SettingsScreenType.ENTERPRISES, url: '/settings/enterprises'});
        }

        if (roleManager.getSettingsNotificationsPermission() != PermissionType.NOT_ALLOWED) {
            settingsSubmenus.push({name: SettingsScreenType.NOTIFICATIONS, url: '/settings/notifications'});
        }

        if (settingsSubmenus.length > 0) {
            finalMenus.push({name: ScreenType.SETTINGS, iconA: '/misc/settings_b.png', iconB: '/misc/settings_b.png', iconWidth: '28px', submenus: settingsSubmenus});
        }
        
        let securitySubmenus = [];

        if (roleManager.getSettingsRolesPermission() != PermissionType.NOT_ALLOWED) {
            securitySubmenus.push({name: SecurityScreenType.ROLES, url: '/settings/roles'});
        }

        if (roleManager.getSettingsUsersRolesPermission() != PermissionType.NOT_ALLOWED) {
            securitySubmenus.push({name: SecurityScreenType.USERS, url: '/settings/users-and-permissions'});
        }

        if (securitySubmenus.length > 0) {
            finalMenus.push({name: ScreenType.SECURITY, iconA: '/misc/settings_c.png', iconB: '/misc/settings_c.png', iconWidth: '28px', submenus: securitySubmenus});
        }

        setMenus(finalMenus);

    }, []);

    useEffect(() => {
        axiosInstance.get('/user/me').then((response) => {
            setCurrentUser(response.data);
        })
        .catch(error => {
            console.error("Sidebar:getUserMe:error", error);
        })
    }, []);

    useEffect(() => {
        const root = document.documentElement;

        root.style.setProperty('--sidebar-width', (sidebarOpen ? '250px' : '80px'));
    }, [sidebarOpen]);

    return (
        <div className={styles.container}>
            <div className={styles.sidebarContainer}>
                <div style={{
                    position: 'relative', height: '34px',
                    overflowX: 'clip', display: 'flex', justifyContent: 'start',
                    paddingLeft: '23px'
                }} onClick={() => setSidebarOpen((prev) => !prev)}>
                    {sidebarOpen ?
                        <img src={'/branding/stracon_logo.png'} height={'36px'} alt={'stracon'} width={'174px'}/>
                        :
                        <img src={'/branding/stracon_small.png'} height={'36px'} alt={'stracon'} width={'36px'}/>
                    }
                </div>

                <div className={styles.menus}>
                    {menus.map((item, index) => (
                        <div key={index} className={`${styles.menuItem}`} style={{
                            justifyContent: sidebarOpen ? 'flex-start' : 'center',
                            position: 'relative'
                        }} onClick={() => {
                            if (sidebarOpen) {
                                if(item.submenus?.length > 0) {
                                    setMenus((prev) => {
                                        const copy = [...prev];
                                        for (let i = 0; i < copy.length; i++) {
                                            if (i !== index) {
                                                copy[i].isOpen = false;
                                            }
                                        }
                                        copy[index].isOpen = !copy[index].isOpen;
                                        return copy;
                                    });
                                }
                            }
                            setSidebarOpen(true);
                            if(item.url) navigate(item.url);
                        }}>
                            <div style={{width: '20px', position: 'absolute', left: 0, top: 0}}>
                                {selected === item.name && <img src={'/misc/sidebar_selected.png'} alt={'-'} height={'43px'}/>}
                            </div>
                            <div className={selected === item.name ? styles.menuItemSelected : styles.menuItemNormal} style={!sidebarOpen ? {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxWidth: '47px'
                            } : {
                                marginLeft: '20px',
                                paddingBottom: item.isOpen ? '15px' : ''
                            }}>
                                <div className={'d-flex justify-content-between align-items-center'} style={{minHeight: '47px', padding: sidebarOpen ? '0 10px' : ''}}>
                                    <div className={sidebarOpen ? 'd-flex justify-content-start align-items-center' : 'd-flex justify-content-center align-items-center'}>
                                        <div>
                                            <div style={{width: '22px'}} className={'d-flex justify-content-center'}>
                                                <img src={item.name === selected ? item.iconA : item.iconB} alt={'-'} width={item.iconWidth}/>
                                            </div>
                                            {!sidebarOpen && selected === item.name &&
                                                <div style={{height: '4px', backgroundColor: '#F00', width: '22px', borderRadius: '8px', position: 'absolute', bottom: 0}}/>
                                            }
                                        </div>
                                        {sidebarOpen && <div className={'ps-3'} style={{whiteSpace: 'nowrap'}}>{item.name}</div>}
                                    </div>
                                    {sidebarOpen && item.submenus?.length > 0 &&
                                        <FontAwesomeIcon icon={item.isOpen ? faChevronUp : faChevronDown}/>
                                    }
                                </div>
                                {sidebarOpen && item.isOpen && item.submenus?.map((submenu, subIndex) => (
                                    <div key={subIndex} style={{paddingTop: subIndex === 0 ? '5px' : '', paddingRight: '15px', marginLeft: '25px'}} className={'d-flex align-items-center'} onClick={() => navigate(submenu.url)}>
                                        <div style={{width: '19px', height: '1px'}}/>
                                        <div className={`${styles.submenu} ${(selected === item.name && selectedSubmenu === submenu.name) ? styles.submenuSelected : ''} ps-2`}>{submenu.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${styles.footer} ${sidebarOpen ? styles.footerOpen : ''}`}>
                <div className={styles.pp} style={{
                    borderRadius: sidebarOpen ? '30px' : '',
                    width: sidebarOpen ? '230px' : ''
                }}>
                    <img src={'/misc/pp.png'} alt={'pp'} width={'49px'} height={'49px'}/>
                    {sidebarOpen &&
                    <div className={'ps-2 d-flex flex-column justify-content-center'} style={{lineHeight: '15px'}}>
                        <div style={{fontWeight: '500'}}>{currentUser?.fullname}</div>
                        <div style={{fontWeight: '400', color: '#C3D1EF', fontSize: '12px'}}>{currentUser?.subclassifier?.name}</div>
                        <div style={{fontWeight: '500', color: '#C3D1EF', fontSize: '12px'}}>{roleManager.getHighestRole()}</div>
                    </div>
                    }
                </div>
                {sidebarOpen &&
                    <div style={{paddingTop: '10px'}}>
                        <LogoutButton/>
                    </div>
                }
            </div>
            </div>
        </div>
    )
}
