import { axiosInstance } from "../../../AxiosInstance";

class DashboardService {
    async getHistogramData(project, area, startDate, endDate) {
        return {
            'allEmployees': [500, 900, 600, 800, 700, 1000, 400],
            'onsiteEmployees': [300, 500, 400, 700, 500, 600, 300],
        };
          const response = await axiosInstance.get('user/').then((response) => {
            console.log(response);
            return {
                'allEmployees': [500, 900, 600, 800, 700, 1000, 400],
                'onsiteEmployees': [300, 500, 400, 700, 500, 600, 300],
            };
          }).catch((error) => {
            console.error(error);
            return null;
          });
    }
    async getOutOfRegimeData(project, area, startDate, endDate) {
        return {
            'staff': {
                'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                'count': [300, 500, 400, 700, 500, 600, 300],
            },
            'rco_and_rcc': { 
                'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                'count': [300, 500, 400, 700, 500, 600, 300],
            },
        };
        const response = await axiosInstance.get('user/').then((response) => {
            console.log(response);
            return {
                'staff': {
                    'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                    'count': [300, 500, 400, 700, 500, 600, 300],
                },
                'rco_and_rcc': { 
                    'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                    'count': [300, 500, 400, 700, 500, 600, 300],
                },
            };
            }).catch((error) => {
            console.error(error);
            return null;
            });
    }
    async getPendingDatesData(project, area, startDate, endDate) {
        return {
            "employees": {
                'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                'count': [300, 500, 400, 700, 500, 600, 300],
            },
            "enteprises": {
                'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                'count': [300, 500, 400, 700, 500, 600, 300],
            }
        };
        const response = await axiosInstance.get('user/').then((response) => {
            console.log(response);
            return {
                "employees": {
                    'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                    'count': [300, 500, 400, 700, 500, 600, 300],
                },
                "enteprises": {
                    'employeesNames': ['Juan', 'Pedro', 'Pablo', 'Maria', 'Jose', 'Carlos', 'Luis'],
                    'count': [300, 500, 400, 700, 500, 600, 300],
                }
            };
            }).catch((error) => {
            console.error(error);
            return null;
            });
    }
    async getMovilizationData(project, area, startDate, endDate) {
        return {
            'movilization': [900, 300, 400, 700, 500],
            'demovilization': [-500, -200, -300, -100, -400],
        };
        const response = await axiosInstance.get('user/').then((response) => {
            console.log(response);
            return {
                'movilization': [900, 300, 400, 700, 500],
                'demovilization': [-500, -200, -300, -100, -400],
            };
            }).catch((error) => {
            console.error(error);
            return null;
            });
    }
    async getPieChartData(project, area, startDate, endDate) {
        const response = await axiosInstance.get('user/').then((response) => {
            return {
                'labels': ['Group A', 'Group B', 'Group C', 'Group D'],
                'data': [400, 300, 300, 200],
            };
            console.log(response);
            return {
                'labels': ['Group A', 'Group B', 'Group C', 'Group D'],
                'data': [400, 300, 300, 200],
            };
            }).catch((error) => {
            console.error(error);
            return null;
            });
    }
  }
  
  export default new DashboardService();
  