import React, {useState} from "react";
import styles from './change_password.module.css';
import {TextPassword, Button} from "../../ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams } from "react-router-dom";
import {axiosUnAuthInstance} from "../../AxiosInstance";
import { toast, ToastTypes } from "../../ajonjolib/toasts/toast/toast";

export default function ChangePasswordPassword() {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [sending, setSending] = useState(false);
    const navigate = useNavigate();
    const { uidb64, token } = useParams();

    const submit = () => {
        if(password !== password2) {
            alert('Las contraseñas no coinciden');
            return;
        } else {
            setSending(true);

            axiosUnAuthInstance.post(`password/reset/confirm/${uidb64}/${token}/`, {
                password: password
            }).then((response) => {
                if (response.status !== 200) {
                    toast('Ocurrió un error', ToastTypes.ERROR);
                    return;
                }

                setSending(false);
                navigate('/password_reset/success');
            }).catch(error => {
                console.log('password.js:ChangePasswordPassword:submit', error);
                setSending(false);
                return;
            });
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.straconLogo}>
                <img src={'/branding/stracon_white.png'} alt={'stracon'} height={'43px'}/>
            </div>
            <div className={styles.innerContainer}>
                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>

                <div>Restablecer la contraseña</div>

                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>
            </div>

            <div className={'mt-4 d-flex justify-content-center align-items-center'}>
                <div style={{
                    flexBasis: '25%', textAlign: 'left',
                    color: '#ffffff',
                    fontSize: '16px',
                }}>
                    <div>Ingresa tu nueva contraseña</div>
                    <div className={'mt-4'}/>
                    <div style={{fontSize: '16px', color: '#FFF', textAlign: 'left'}} className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faLock} style={{color: '#FFF', marginRight: '10px'}}/>
                        <div>Nueva Contraseña</div>
                    </div>
                    <TextPassword className={'mt-2'} style={{fontSize: '16px', height: '40px'}} value={password} onChange={(val) => {
                        setPassword(val);
                    }}/>
                    <div className={'mt-4'}/>
                    <div style={{fontSize: '16px', color: '#FFF', textAlign: 'left'}} className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faLock} style={{color: '#FFF', marginRight: '10px'}}/>
                        <div>Confirmar Contraseña</div>
                    </div>
                    <TextPassword className={'mt-2'} style={{fontSize: '16px', height: '40px'}} value={password2} onChange={(val) => {
                        setPassword2(val);
                    }}/>
                    <div className={'pt-4'}>
                        <Button
                            type="button"
                            variant="roundedDanger"
                            onSubmit={() => submit()}>
                            Restablecer contraseña
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}