import React, {useEffect, useRef, useState} from "react";
import styles from './roster_box.module.css';
import stats from "../../../../utils/event_types";
import {Checkbox, Select, Text, TextSearcher} from "../../../../ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSliders, faX} from "@fortawesome/free-solid-svg-icons";
import {axiosInstance} from "../../../../AxiosInstance";
import {eventBus} from "../../../../ajonjolib/toasts/toast/toast";
import RosterLoading from "../roster_loading/roster_loading";

function DayEvent({date, object}) {
    const [year, month, day] = date.split('-');
    return (
        <div style={{
        }}>
            <div className={styles.event} style={{
                backgroundColor: object.color
            }}>
                <div>{object.slug}{object.position}</div>
            </div>
        </div>
    )
}

function parseStatus(row) {
    // Supongamos que cada 'roster' en 'row.rosters' tiene una propiedad 'status'.
    // Verificar si todos los rosters en 'row.rosters' están aprobados (status === 1).

    const roster = row.roster;
    let color, status_string, style_status;

    if (!roster || Object.keys(roster).length === 0){
        color = '#52A136'; // Verde
        status_string = 'No hay rosters';
        style_status = 1;
    } else {
        // Obtener las claves del objeto roster (fechas) y iterar sobre ellas.
        const status_list = Object.keys(roster).flatMap(date => {
            // Para cada fecha, obtener el primer id de roster si existe.
            const firstRoster = roster[date];
            if (firstRoster && firstRoster.length > 0) {
                return firstRoster[0].status; // Retorna el id del primer objeto en la lista para esa fecha.
            }
            return [];
        });
        // Check if all are 1
        const allApproved = status_list.every(status => status === true);

        if (allApproved) {
            // Todos los rosters están aprobados.
            color = '#52A136'; // Verde
            status_string = 'APROBADO';
            style_status = 1;
        } else {
            const allRejected = status_list.every(status => status === false);
            if (allRejected) {
                // Al menos un roster no está aprobado.
                color = '#F32735'; // Rojo
                status_string = 'DESAPROBADO';
                style_status = 0;
            } else{
                // Al menos un roster no está aprobado.
                color = '#FF5E01'; // NARANJA
                status_string = 'PENDIENTE';
                style_status = 2;
            }
        }
    }
    

    return (
        <div className={'d-flex align-items-center justify-content-between pe-1'}>
            <div className={styles.status} style={{ backgroundColor: color }}/>
            <div>{status_string}</div>
        </div>
    );
}

function getDayOfWeek(dayIndex) {
    const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    return daysOfWeek[dayIndex];
}

function RosterTable({localFilters, query, checkboxes, setCheckboxes, markAll, rows, setRows, refreshTrigger, setProjectSubclassifiers, shouldShowApproverActions, shouldShowPlannerActions, setPendingCount, setCountByAssignation}) {
    const [dateArray, setDateArray] = useState([])
    const [lock, setLock] = useState(true);
    const [page, setPage] = useState(1);
    const [isBottom, setIsBottom] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(10);

    const areObligatoryQueryFiltersValid = () => {
        return query['start_date'] && query['end_date'] && query['project'];
    }

    useEffect(() => {
        setCheckboxes({});

        const fetchRoster = async () => {
            let dateObjectArray = [];
            let start_date = new Date(query['start_date']);
            start_date = start_date.setDate(start_date.getDate() + 1);
            let end_date = new Date(query['end_date']);
            end_date = end_date.setDate(end_date.getDate() + 1);

            if (!areObligatoryQueryFiltersValid()) return;

            setLoading(true);
            setRows([]);
    
            for (let currentDate = new Date(start_date); currentDate <= new Date(end_date); currentDate.setDate(currentDate.getDate() + 1)) {
                const dateObject = {
                    day: currentDate.getDate(),
                    month: currentDate.getMonth() + 1,
                    year: currentDate.getFullYear(),
                    dow: getDayOfWeek(currentDate.getDay()),
                };
    
                dateObjectArray.push(dateObject);
            }
            setDateArray(dateObjectArray);

            let queryParams = Object.entries(query)
                .filter(([_, value]) => value !== null && value !== '')
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');

            try {
                const response = await axiosInstance.get(`projects/${query['project']}/roster/?page=${page}&page_size=25&${queryParams}`);
                if (response.status === 200) {
                    const newTotalPages = Math.ceil(response.data.count / 25);
                    setTotalPages(newTotalPages);
                    setRows(response.data.results);
                    setPendingCount(response.data.pending_count? response.data.pending_count: 0);
                    setCountByAssignation(response.data.count_by_assignation? response.data.count_by_assignation: {});
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
            setLoading(false);
        };

        fetchRoster();
    }, [page, query, refreshTrigger]);

    return (
        <div className={styles.table}>
            {loading ? (
                <RosterLoading />
            ) : (
            <>
                {rows.length == 0 ? (
                    <p>
                        {areObligatoryQueryFiltersValid() ? (
                            <b>No se han encontrado datos</b>
                        ) : (
                            <b>Por favor, seleccione un proyecto y un rango de fechas</b>
                        )}
                    </p>
                ) : (
                    <>
                    <table>
                        <thead>
                        <tr>
                            <th style={{minWidth: '42px'}}>
                                <Checkbox className={'d-flex justify-content-center'} value={checkboxes['all']} onChange={(val) => {
                                    markAll(val);
                                }}/>
                            </th>
                            {localFilters?.includes(0) && <th className={styles.data} style={{minWidth: '70px'}}>Status</th>}
                            <th className={styles.data} style={{minWidth: '70px'}}>DNI</th>
                            <th className={styles.data} style={{minWidth: '150px'}}>Apellidos y Nombres</th>
                            {localFilters?.includes(1) && <th className={styles.data} style={{minWidth: '100px'}}>Puesto</th>}
                            {localFilters?.includes(2) && <th className={styles.data} style={{minWidth: '100px'}}>Area</th>}
                            {localFilters?.includes(3) && <th className={styles.data} style={{minWidth: '100px'}}>Clasificador</th>}
                            {localFilters?.includes(4) && <th className={styles.data} style={{minWidth: '100px'}}>Subclasificador</th>}
                            {localFilters?.includes(5) && <th className={styles.data} style={{minWidth: '100px'}}>Proyecto</th>}
                            {localFilters?.includes(6) && <th className={styles.data} style={{minWidth: '100px'}}>Grupo de Proyecto</th>}
                            {localFilters?.includes(7) && <th className={styles.data} style={{minWidth: '100px'}}>Empresa</th>}
                            {localFilters?.includes(8) && <th colSpan={2} className={styles.data} style={{minWidth: '100px'}}>
                                <div style={{textAlign: 'left'}}>Saldo de días</div>
                                <div className={'d-flex justify-content-between'} style={{
                                    fontSize: '10px', fontWeight: '500', color: '#BFBFBF'
                                }}>
                                    <div style={{flexBasis: '50%', textAlign: 'left'}}>Total</div>
                                    <div style={{flexBasis: '50%', textAlign: 'left'}}>Cierre</div>
                                </div>
                            </th>}
                            {dateArray.map((date, index) => {
                                return (
                                    <th key={index} className={styles.date}>
                                        <div style={{backgroundColor: '#6A86C8', fontSize: '11px', fontWeight: '600', color: '#FFFFFF'}}>{date.year}</div>
                                        <div style={{backgroundColor: '#C3D1EF', fontSize: '11px', fontWeight: '600', color: '#6A86C8'}}>{date.dow}</div>
                                        <div style={{backgroundColor: '#ECF1FB', padding: '7px 0'}}>
                                            <div style={{fontSize: '14px', color: '#375395', lineHeight: '15px'}}>{date.day < 10 ? "0" + date.day : date.day}</div>
                                            <div style={{fontSize: '12px', color: '#6A86C8', lineHeight: '12px'}}>/{date.month < 10 ? "0" + date.month : date.month}</div>
                                        </div>
                                    </th>
                                )})
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                        rows?.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <Checkbox className={'d-flex justify-content-center'} value={checkboxes[`row_${index}`]} onChange={(val) => {
                                        
                                        setCheckboxes({...checkboxes, [`row_${index}`]: val})
                                    }}/>
                                </td>
                                {localFilters?.includes(0) && <td className={styles.data}>{parseStatus(row)}</td>}
                                <td className={styles.data}>{row.dni}</td>
                                <td className={`${styles.data}`}>
                                    <div className={'d-flex align-items-center'}>
                                        <img alt={'user'} src={'/misc/user_image.png'} width={'25px'}/>
                                        <div className={'ps-2'}>
                                            <div>{row.last_name?.toUpperCase()}</div>
                                            <div>{row.first_name?.toUpperCase()}</div>
                                        </div>
                                    </div>
                                </td>
                                {localFilters?.includes(1) && <td className={styles.data}>{row.position?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(2) && <td className={styles.data}>{row.area?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(3) && <td className={styles.data}>{row.classifier?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(4) && <td className={styles.data}>{row.subclassifier?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(5) && <td className={styles.data}>{row.project?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(6) && <td className={styles.data}>{row.project_group?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(7) && <td className={styles.data}>{row.enterprise?.name?.toUpperCase()}</td>}
                                {localFilters?.includes(8) && <td className={styles.data}>{row.days_off_total}</td>}
                                {localFilters?.includes(8) && <td className={styles.data}>{row.days_off_estimated}</td>}
                                {Object.entries(row?.roster || {}).map(([date, entries]) => (
                                        <td key={date} className={`${styles.date}`} onContextMenu={(event) => {
                                            if (shouldShowPlannerActions) {
                                                event.preventDefault();
                                                eventBus.emit('open_create_event', {
                                                    user_id: row.id,
                                                    date: date,
                                                    project_id: row.project.id
                                                });
                                            }
                                        }}>
                                            {/* Accessing the roster entry for each date */}
                                            {entries.map((entry, index) => (
                                                <DayEvent key={index} date={date} object={{color: entry.color, slug: entry.assignation, position: entry.position}}/>
                                            ))}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className={styles.paginationContainer}>
                        <button className={styles.paginationButton} onClick={() => setPage(1)} disabled={page === 1}>
                            Primera
                        </button>
                        <button className={styles.paginationButton} onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))} disabled={page === 1}>
                            Anterior
                        </button>
                        <span className={styles.paginationInfo}>Página {page} de {totalPages}</span>
                        <button className={styles.paginationButton} onClick={() => setPage(prevPage => Math.min(prevPage + 1, totalPages))} disabled={page === totalPages}>
                            Siguiente
                        </button>
                        <button className={styles.paginationButton} onClick={() => setPage(totalPages)} disabled={page === totalPages}>
                            Última
                        </button>
                    </div>
                    </>
                )}
            </>
            )}
        </div>
    )
}

export default function RosterBox({localFilters, approve, query, setQuery, setShowApprove, setShowReject, checkboxes, setCheckboxes, rows, setRows, refreshTrigger, shouldShowApproverActions, shouldShowPlannerActions, setCountByAssignation, pendingCount, setPendingCount}) {
    
    
    const default_rows = [
        {status: 1, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 0, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 0, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 0, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 2, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 1, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 0, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 0, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 2, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
        {status: 0, dni: "79841221", first_name: "José Antonio", last_name: "Mayorga Tello ", position: "Operador de Lorem Ipsum", days: '-000'},
    ]
    
    const markAllPending = (val) => {
        let newCheckboxes = {...checkboxes};
        
        console.log("Rows", rows);
        rows.forEach((row, index) => {
            if (row.has_pending) {
                newCheckboxes[`row_${index}`] = val;
            }
        });

        // Key for the 'all' checkbox
        
        newCheckboxes['pending'] = val;
        setCheckboxes(newCheckboxes);
        
        
    }

    const markAll = (val) => {
        let newCheckboxes = {...checkboxes};
        rows.forEach((row, index) => {
            newCheckboxes[`row_${index}`] = val;
        });
        setCheckboxes(newCheckboxes);
    }

    return (
        <div className={styles.container}>
            <div className='d-flex align-items-center justify-content-between'>
                {approve ?
                    <div className='d-flex align-items-center justify-content-start' style={{
                    backgroundColor: '#F7F8FA',
                    borderBottomRightRadius: '21px', width: '500px'
                    }}>
                        <div className={styles.pendingStatus}>
                            <Checkbox className='d-flex' value={checkboxes['pending']} onChange={(val) => {
                                console.log("Marking pending", val);
                                markAllPending(val);
                            }}/>
                            <div>{pendingCount}</div>
                            <div>
                                <div>Pendientes</div>
                                <div>de Aprobación</div>
                            </div>
                        </div>
                        {shouldShowApproverActions &&
                        <div>
                            <div className={styles.approveButton} onClick={setShowApprove}>
                                <FontAwesomeIcon icon={faCheck} className='pe-2' size='lg'/>
                                <div>Aprobar</div>
                            </div>
                            <div className={styles.rejectButton} onClick={setShowReject}>
                                <FontAwesomeIcon icon={faX} className='pe-2' size='lg'/>
                                <div>Rechazar</div>
                            </div>
                        </div>
                        }
                    </div>
                    :
                    <div style={{paddingTop: '20px'}}/>
                }

            </div>
            <div style={{height: '100%'}}
                 className='pt-3 d-flex justify-content-center'>
                <RosterTable markAll={markAll} localFilters={localFilters} query={query} checkboxes={checkboxes} setCheckboxes={setCheckboxes}
                    rows={rows} setRows={setRows} refreshTrigger={refreshTrigger}
                    shouldShowApproverActions={shouldShowApproverActions} shouldShowPlannerActions={shouldShowPlannerActions}
                    setPendingCount={setPendingCount}
                    setCountByAssignation={setCountByAssignation}
                />
            </div>
        </div>
    );
}