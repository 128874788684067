import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './logout_button.module.css';

const LogoutButton = () => {
    let navigate = useNavigate();
    
    const handleLogout = () => {
        localStorage.removeItem('access_token');
        navigate('/login');
    };
    
    return (
        <div className={styles.loginButton} onClick={() => handleLogout()}>
            Logout
        </div>

    );
};

export default LogoutButton;