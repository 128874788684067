import React, {useEffect, useState, useContext, useRef} from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faSave, faUserLock, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ModalPassword from "../../../components/modal_password/modal_password";
import Modal from "../../../components/modal/modal";
import {Select, Text, DNISelect} from "../../../ajonjolib/inputs/ajonjolinput";
import {axiosInstance} from "../../../AxiosInstance";
import Switch from "../../../ajonjolib/inputs/switch/switch";
import {toast, ToastTypes} from "../../../ajonjolib/toasts/toast/toast";
import styles from "../projects/projects.module.css";
import UserPermissionsUpload from "./components/upload/upload";
import {Button, File} from "../../../ajonjolib/inputs/ajonjolinput";
import { ScreenType, SecurityScreenType } from "../../../ScreenType";
import sortItems from "../../../utils/sort";

export default function UsersAndPermissions() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCloudModal, setShowCloudModal] = useState(false);
    const [showExcelModal, setShowExcelModal] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    const [searchedUsers, setSearchedUsers] = useState([]);
    
    const [groupProjects, setGroupProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [fronts, setFronts] = useState([]);
    const [areas, setAreas] = useState([]);

    const [filterProjects, setFilterProjects] = useState([]);
    const [filterAreas, setFilterAreas] = useState([]);
    const [filterFronts, setFilterFronts] = useState([]);

    const [showFilterProjects, setShowFilterProjects] = useState(false);
    const [showFilterAreas, setShowFilterAreas] = useState(false);
    const [showFilterFronts, setShowFilterFronts] = useState(false);
    const skipEffectRef = useRef(false);
    
    const [create, setCreate] = useState(false);
    const [roles, setRoles] = useState([]);
    const [form, setForm] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const {roleManager} = useContext(RosterContext);

    const shouldShowActions = roleManager.getSettingsRolesPermission() == PermissionType.WRITE;

    useEffect(() => {
        document.title = 'Usuarios y Permisos | Roster';

        axiosInstance.get('role').then((response) => {
            setRoles(response.data.results);
        })
        
        axiosInstance.get('project_group').then((response) => {
            setGroupProjects(response.data);
            console.log("Group Projects", response.data);
        })

        axiosInstance.get('project').then((response) => {
            setProjects(sortItems(response.data.results, 'name'));
            setFilterProjects(sortItems(response.data.results, 'name'));
            console.log("Projects", response.data.results);
        })

        axiosInstance.get('front').then((response) => {
            setFronts(sortItems(response.data.results));
            setFilterFronts(sortItems(response.data.results));
            console.log("Fronts", response.data.results);
        });

        axiosInstance.get('area').then((response) => {
            setAreas(sortItems(response.data, 'name'));
            setFilterAreas(sortItems(response.data, 'name'));
            console.log("Areas", response.data);
        });

    }, []);

    useEffect(() => {
        console.log("Searching users", searchTerm);
        if (searchTerm.length > 3) {
            axiosInstance.get('user/', {
                params: {
                    dni: searchTerm
                }
            }).then((response) => {
                console.log("Response for searching users", response.data?.results);
                setSearchedUsers(response.data?.results);
            });
        }
    }, [searchTerm]);

    useEffect(() => {
        if (form['dni'] && form['id'] && create) {
            axiosInstance.get(`user/${form['id']}/`).then((response) => {
                //setForm(response.data);
                console.log("Response", response);
                setForm({...form, 
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email, roles: response.data.roles.map((r) => r.id),
                    project_group: response.data.project_groups_managed.map((r) => r.id),
                    project: response.data.projects_managed.map((r) => r.id),
                    area: response.data.areas_managed.map((r) => r.id),
                    front: response.data.fronts_managed.map((r) => r.id)
                });
            });
        }
    }, [form['dni']])

    /*
    Reglas de selección:
    El usuario puede seleccionar proyecto solo si tiene un grupo de proyecto seleccionado.
    El usuario puede seleccionar área o frente solo si tiene un proyecto seleccionado.
    El usuario solo puede seleccionar o áreas o frentes.
    */

    useEffect(() => {
        if (form['project_group'] && form['project_group'].length >= 1) {
            setShowFilterProjects(true);
            // const filterProjects = projects.filter((p) => p.group == form['project_group'][0]);
            const filterProjects = projects.filter((p) => form['project_group'].includes(p.group));
            setFilterProjects(filterProjects);
        } else {
            setShowFilterProjects(false);
            setForm({...form, project: [], area: [], front: []});
            skipEffectRef.current = true;
        }
    }, [form['project_group']]);

    useEffect(() => {
        if (form['project'] && form['project'].length >= 1) {
            setShowFilterAreas(true);
            setShowFilterFronts(true);
            const filteredAreas = areas.filter((a) => form['project'].includes(a.project));
            setFilterAreas(filteredAreas);
            const filteredFronts = fronts.filter((f) => form['project'].includes(f.project?.id));
            setFilterFronts(filteredFronts);
        } else {
            setShowFilterAreas(false);
            setShowFilterFronts(false);
            setForm({...form, area: [], front: []});
            skipEffectRef.current = true;
        }
    }, [form['project']]);

    // Bucle infinito: Buscar solución.
    
    const cols = [
        {title: 'Estado', internal: 'active', code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }}/>
                )
            }},
        {title: 'DNI', internal: 'dni', altBackground: true, code: (row, data) => {
                return (
                    <div style={{color: '#707D9B', fontWeight: 600}}>{data}</div>
                )
            }},
        {title: 'Apellidos y Nombres', internal: 'name', altBackground: true,code: (row) => {
            return(
                <div className={'d-flex align-items-center'}>
                    <img alt={'user'} src={'/misc/user_image.png'} width={'25px'}/>
                    <div className={'ps-2'}>
                        <div>{row.last_name?.toUpperCase()} {row.first_name?.toUpperCase()}</div>
                    </div>
                </div>
            )
            }},
        {title: 'Correo', internal: 'email', altBackground: true},
        {title: 'Rol', internal: 'roles[0].name'},
        {title: 'Grupo Proyecto', internal: 'project_groups_managed[0].name'},
    ]

    if (shouldShowActions) {
        cols.push(
            {title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-start'}>
                        <FontAwesomeIcon color={'#6A86C8'} className={'pe-2'} icon={faUserLock} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                            setShowPassword(true);
                            let copy = {...row};
                            copy['roles'] = row.roles?.map((r) => r.id);
                            setForm(copy);
                        }}/>
                        <FontAwesomeIcon color={'#6A86C8'} icon={faPenToSquare} size={'lg'} style={{cursor: 'pointer'}} onClick={() => {
                            setShowEditModal(true);
                            setCreate(false);
                            const roles = row.roles?.map((r) => {
                                return r.id;
                            });
                            const project_group = row.project_groups_managed?.map((r) => {
                                return r.id;
                            });
                            const project = row.projects_managed?.map((r) => {
                                return r.id;
                            });
                            const area = row.areas_managed?.map((r) => {
                                return r.id;
                            });
                            const front = row.fronts_managed?.map((r) => {
                                return r.id;
                            });
                            let copy = {...row};
                            setForm({
                                ...row,
                                roles: roles,
                                project_group: project_group,
                                project: project,
                                area: area,
                                front: front,
                            });
                        }}/>
                    </div>
                )
            }}
        );
    }

    const submit = () => {
        console.log(form);
        if(create) {
            console.log("Submit in permissions", form['project_group'], form['project'], form['area'], form['front'])
            axiosInstance.patch(`user/${form.id}/`, {
                roles: form['roles'],
                project_groups_managed: form['project_group'],
                projects_managed: form['project'],
                areas_managed: form['area'],
                fronts_managed: form['front'],
            }).then((response) => {
                console.log("Response", response);
                if (response.status !== 200) {
                    toast('Error al editar el usuario', ToastTypes.ERROR);
                    return;
                }
                setShowEditModal(false);
                toast('Usuario actualizado exitosamente', ToastTypes.SUCCESS);
                window.location.reload();
            }).catch((error) => {
                console.log("Error al realizar la petición:", error);
                console.log("Detalles del error:", error.response);
                toast('Error al editar el usuario', ToastTypes.ERROR);
            });
        } else {
            console.log("Submit in permissions", form['project_group'], form['project'], form['area'], form['front'])
            axiosInstance.patch(`user/${form.id}/`, {
                roles: form['roles'],
                project_groups_managed: form['project_group'],
                projects_managed: form['project'],
                areas_managed: form['area'],
                fronts_managed: form['front'],
            }).then((response) => {
                console.log("Response", response);
                if (response.status !== 200) {
                    toast('Error al editar el usuario', ToastTypes.ERROR);
                    return;
                }
                setShowEditModal(false);
                toast('Usuario actualizado exitosamente', ToastTypes.SUCCESS);
                window.location.reload();
            }).catch((error) => {
                console.log("Error al realizar la petición:", error);
                console.log("Detalles del error:", error.response);
                toast('Error al editar el usuario', ToastTypes.ERROR);
            });
        }
    }

    const sendRequestChangePassword = (email) => {
        axiosInstance.post('password/reset/', {
            email: email,
        }).then((response) => {
            if (response.status !== 200) {
                toast('Ocurrió un error', ToastTypes.ERROR);
                return;
            }
            toast('Se envió el correo.', ToastTypes.SUCCESS);
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
    }

    // TODO: Impedir que se pueda editar valores de remotos.

    return (
        <div>
            <Sidebar selected={ScreenType.SECURITY} selectedSubmenu={SecurityScreenType.USERS}/>

            {showPassword &&
                <ModalPassword mail={form.email} close={() => setShowPassword(false)}>
                    <div className={'d-flex justify-content-center pt-4'}>
                        <div
                            onClick={() => sendRequestChangePassword(form.email)}
                            style={{
                            backgroundColor: '#F32735', padding: '10px 25px', borderRadius: '43px', cursor: 'pointer',
                            fontSize: '15px',
                            fontWeight: '500'
                        }}>Enviar Enlace</div>
                    </div>
                </ModalPassword>
            }
            {showEditModal &&
                <Modal title={'Usuarios'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowEditModal(false)}>
                    <div style={{textAlign: 'left'}}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div>DNI</div>
                                { 
                                    create ?
                                    <DNISelect 
                                    value={form['dni']} 
                                    options={searchedUsers?.map((role) => ({ value: role.id, name: role.dni }))}
                                    onChange={(selectedId) => {
                                        const selectedRole = searchedUsers.find(role => role.id === selectedId[0]);
                                        if (selectedRole) {
                                        setForm({
                                            ...form, 
                                            dni: selectedRole.dni,
                                            id: selectedRole.id
                                        });
                                        }
                                    }}
                                    searchTerm={searchTerm} setSearchTerm={setSearchTerm}
                                    />  :
                                    <Text disabled={true} value={form['dni']} onChange={(val) => {
                                        setForm({...form, dni: val})
                                    }}/>
                                }
                                
                            </div>
                            {/*
                            <div style={{flexBasis: '45%'}}>
                                <div>Estado*</div>
                                <Switch value={form['active']} onChange={(val) => {
                                    setForm({...form, active: val})
                                }}/>
                            </div>
                            */}
                        </div>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div>Nombres*</div>
                                <Text disabled={true} value={form['first_name']} onChange={(val) => {
                                    setForm({...form, first_name: val})
                                }}/>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div>Apellidos*</div>
                                <Text disabled={true} value={form['last_name']} onChange={(val) => {
                                    setForm({...form, last_name: val})
                                }}/>
                            </div>
                        </div>

                        <div style={{flexBasis: '100%'}} className={'mb-3'}>
                            <div>Correo*</div>
                            <Text disabled={true} value={form['email']} onChange={(val) => {
                                setForm({...form, email: val})
                            }}/>
                        </div>

                        <div className={' mb-3'}>
                            <div>Roles</div>
                            {
                                form['dni'] || !create?
                                <Select autocomplete={true} multi={true} value={form['roles']} options={roles?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                    console.log("Selected Role", val);
                                    setForm({...form, roles: val})
                                }}/>
                                :
                                <Text disabled={true} value={"Escoge un DNI"}/>
                            }
                            
                        </div>
                        <div className={' mb-3'}>
                            <div>Grupo de Proyecto</div>
                            {
                                form['dni'] || !create?
                                <Select autocomplete={true} reverse={true} multi={true} value={form['project_group']} options={groupProjects?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                    console.log("Selected Group Projects", val);
                                    setForm({...form, project_group: val});
                                }}/>
                                :
                                <Text disabled={true} value={"Escoge un DNI"}/>
                            }
                        </div>
                        <div className={' mb-3'}>
                            <div>Proyecto</div>
                            {
                                form['dni'] || !create?
                                showFilterProjects ?
                                    <Select autocomplete={true} reverse={true} multi={true} value={form['project']} options={filterProjects?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                        console.log("Selected Projects", val);
                                        setForm({...form, project: val});
                                    }}/>
                                    :
                                    <Text disabled={true} value={"Todos"}/>
                                :
                                <Text disabled={true} value={"Escoge un DNI"}/>
                            }
                        </div>
                        <div className={' mb-3'}>
                            <div>Área</div>
                            {
                                form['dni'] || !create?
                                showFilterAreas ?
                                    <Select autocomplete={true} reverse={true} multi={true} value={form['area']} options={filterAreas?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                        console.log("Selected Area", val);
                                        setForm({...form, area: val});
                                    }}/>
                                    :
                                    <Text disabled={true} value={"Todos"}/>
                                :
                                <Text disabled={true} value={"Escoge un DNI"}/>
                            }
                        </div>
                        <div className={' mb-3'}>
                            <div>Frentes</div>
                            {
                                form['dni'] || !create?
                                showFilterFronts ?
                                    <Select autocomplete={true} reverse={true} multi={true} value={form['front']} options={filterFronts?.map((role) => {return {value: role.id, name: role.name}})} onChange={(val) => {
                                        console.log("Selected Front", val);
                                        setForm({...form, front: val});
                                    }}/>
                                    :
                                    <Text disabled={true} value={"Todos"}/>
                                :
                                <Text disabled={true} value={"Escoge un DNI"}/>
                            }
                        </div>

                        <div className={'d-flex justify-content-between pt-3 align-items-center'}>
                            <div style={{flexBasis: '45%', color: '#000000', fontWeight: '300', fontSize: '10px'}}>
                                <div>*Campos Obligatorios</div>
                            </div>
                            <div style={{flexBasis: '45%'}} className={'d-flex justify-content-end'}>
                                <div onClick={() => submit()} className={'d-flex align-items-center text-center'} style={{
                                    backgroundColor: '#E4E4E4',
                                    color: '#000000',
                                    borderRadius: '6px',
                                    padding: '6px 10px',
                                    userSelect: 'none',
                                    cursor: 'pointer'
                                }}>
                                    <FontAwesomeIcon icon={faSave} color={'#000000'} size={'lg'} className={'pe-2'}/>
                                    <div style={{fontSize: '16px', fontWeight: '400'}}>Guardar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {showCloudModal &&
                <Modal title={'Importar'} subtitle={'Usuarios y Permisos'} close={() => setShowCloudModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between'}>
                            <div style={{textAlign: 'left', flexBasis: '45%'}}>
                                <div style={{fontSize: '13px', color: '#666666'}}>Descargar Plantilla</div>
                                <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                    Descargar plantilla excel de Usuarios y Permisos.
                                </div>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.templateButton} onClick={() => {
                                    axiosInstance.get(`user/role/download?template=true`, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        if (response.status === 200) {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'usuarios_permisos_plantilla.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    });
                                }}>
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    <span style={{ marginLeft: '10px' }}>Descargar Plantilla</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={'mt-3'}/>
                        <div style={{fontSize: '11px', color: '#666666', textAlign: 'left'}}>Importar</div>
                        <File onChange={(files) => {
                            setForm({...form, file: files[0]});
                        }}/>
                        <div className={'d-flex mt-3'} style={{justifyContent: 'flex-end'}}>
                            <Button variant={'success'} onSubmit={() => {
                                let formData = new FormData();
                                formData.append('file', form['file'])
                                axiosInstance.post('user/role/upload/', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then((response) => {
                                    if(response.status === 200) {
                                        setShowExcelModal(false);
                                        toast('Roster subido exitosamente', ToastTypes.SUCCESS);
                                        window.location.reload();
                                    }
                                });
                            }}>
                                <div className={'d-flex align-items-center'}>
                                    <FontAwesomeIcon className={'pe-2'} icon={faSave} color={'#FFF'}/>
                                    <div>Guardar</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            {showExcelModal &&
                <Modal title={'Exportar'} subtitle={'Usuarios y Permisos'} close={() => setShowExcelModal(false)}>
                    <div>
                        <div className={'d-flex justify-content-between'}>
                            <div style={{textAlign: 'left', flexBasis: '45%'}}>
                                <div style={{fontSize: '13px', color: '#666666'}}>Descargar Usuarios y Permisos</div>
                                <div style={{fontSize: '10px', color: '#9B9B9B'}}>
                                    Descargar excel de Usuarios y Permisos.
                                </div>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.templateButton} onClick={() => {
                                    axiosInstance.get(`user/role/download/`, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        if (response.status === 200) {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'usuarios_permisos.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    });
                                }}>
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    <span style={{ marginLeft: '10px' }}>Descargar Usuarios y Permisos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Usuarios y Permisos'} menu={'Configuración'}/>
                <div className={'d-flex flex-column'}>
                    <UserPermissionsUpload
                        setShowCloudModal={setShowCloudModal}
                        setShowExcelModal={setShowExcelModal}
                        shouldShowActions={shouldShowActions}
                    />
                    <Table
                        model={'user'}
                        cols={cols}
                        create={() => {
                            setShowEditModal(true);
                            setCreate(true);
                            setForm({});
                        }}
                        shouldShowActions={shouldShowActions}
                        users_permissions={true}
                    />
                </div>
            </div>
        </div>
    )
}