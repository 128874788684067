import {createContext, useState} from "react";
import RoleManager from "./RoleManager";
const RosterContext = createContext("");

export function ContextProvider({children}) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const roleManager = new RoleManager();

    return (
        <RosterContext.Provider value={{setSidebarOpen, sidebarOpen, roleManager}}>
            {children}
        </RosterContext.Provider>
    )
}

export default RosterContext;