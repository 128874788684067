export const ScreenType = Object.freeze({
    DASHBOARD: 'Dashboard',
    USER_MASTER: 'Master de Personal',
    ROSTER: 'Roster',
    SETTINGS: 'Configuración',
    SECURITY: 'Seguridad',
});

export const RosterScreenType = Object.freeze({
    ROSTER: 'Roster',
    ROSTER_APPROVE: 'Aprobar Roster',
    BALANCES: 'Programaciones y Saldos',
});

export const SettingsScreenType = Object.freeze({
    LIMIT_DATES: 'Fecha Límite de Roster',
    GUARDS: 'Guardias',
    FRONTS: 'Frentes',
    BOARDING_ZONES: 'Zonas de Embarque',
    ENTERPRISES: 'Empresas terceras',
    NOTIFICATIONS: 'Notificaciones',
});

export const SecurityScreenType = Object.freeze({
    ROLES: 'Roles',
    USERS: 'Usuarios y Permisos',
});