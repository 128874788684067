// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects_pill__KFgFj {
    border-radius: 12px;
    background-color: #88D36E;
    color: #2A880A;
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins", sans-serif;
    width: 96px;
    text-align: center;
    padding: 2px 0;
}

.projects_inputName__Vawv\\+,
.projects_requiredInput__zY\\+we {
    color: #666666;
    font-weight: 400;
    font-size: 11px;
}

.projects_requiredInput__zY\\+we::before {
    content: '* ';
    color: red;
}

.projects_templateButton__bKq2S {
    background-color: #217345;
    border-radius: 33px;
    color: #FFFFFF;
    box-shadow: 0 4px 7px -2px #2755764D;
    width: 100%;
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/screens/settings/projects/projects.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kCAAkC;IAClC,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;;IAEI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,oCAAoC;IACpC,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".pill {\n    border-radius: 12px;\n    background-color: #88D36E;\n    color: #2A880A;\n    font-size: 14px;\n    line-height: 21px;\n    font-family: \"Poppins\", sans-serif;\n    width: 96px;\n    text-align: center;\n    padding: 2px 0;\n}\n\n.inputName,\n.requiredInput {\n    color: #666666;\n    font-weight: 400;\n    font-size: 11px;\n}\n\n.requiredInput::before {\n    content: '* ';\n    color: red;\n}\n\n.templateButton {\n    background-color: #217345;\n    border-radius: 33px;\n    color: #FFFFFF;\n    box-shadow: 0 4px 7px -2px #2755764D;\n    width: 100%;\n    display: flex;\n    padding: 12px;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": `projects_pill__KFgFj`,
	"inputName": `projects_inputName__Vawv+`,
	"requiredInput": `projects_requiredInput__zY+we`,
	"templateButton": `projects_templateButton__bKq2S`
};
export default ___CSS_LOADER_EXPORT___;
