import React, { useEffect, useState, useContext } from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/modal/modal";
import styles from "../projects/projects.module.css";
import { Select, Text, DNISelect } from "../../../ajonjolib/inputs/ajonjolinput";
import Switch from "../../../ajonjolib/inputs/switch/switch";
import { axiosInstance } from "../../../AxiosInstance";
import ModalBottom from "../../../components/modal_bottom/modal_bottom";
import ModalBottomDelete from "../../../components/modal_bottom/modal_bottom_delete";
import { toast, ToastTypes } from "../../../ajonjolib/toasts/toast/toast";
import { ScreenType, SettingsScreenType } from "../../../ScreenType";
import { valuesArrayToString } from "../../../utils/values_arr_to_string";

export default function Notifications() {
    const [showModal, setShowModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [create, setCreate] = useState(true);
    const [form, setForm] = useState({});
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [typeNotifications, setTypeNotifications] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { roleManager } = useContext(RosterContext);

    const shouldShowActions = roleManager.getSettingsNotificationsPermission() == PermissionType.WRITE;

    const cols = [
        {
            title: 'Estado', internal: 'type_notifications[0].active', code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }} />
                )
            }
        },
        { title: 'Grupo Proyecto', internal: 'project_groups_managed[0].name' },
        {
            title: 'DNI', internal: 'dni', altBackground: true, code: (row, data) => {
                return (
                    <div style={{ color: '#707D9B', fontWeight: 600 }}>{data}</div>
                )
            }
        },
        {
            title: 'Apellidos y Nombres', internal: 'name', altBackground: true, code: (row) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <img alt={'user'} src={'/misc/user_image.png'} width={'25px'} />
                        <div className={'ps-2'}>
                            <div>{row.last_name?.toUpperCase()} {row.first_name?.toUpperCase()}</div>
                        </div>
                    </div>
                )
            }
        },
        { title: 'Correo', internal: 'email', altBackground: true },
        { title: 'Notificación', internal: 'type_notifications[0].type_notification.name', altBackground: true }
    ]

    const handleClickEdit = (row) => {
        let copy = { ...row };
        setForm({
            user_id: copy.id,
            dni: copy.dni,
            fullname: copy.fullname,
            email: copy.email,
            project_groups: copy.project_groups_managed,
            projects: copy.projects_managed,
            status: copy.type_notifications[0].active || false,
            type_notifications: [copy.type_notifications[0].type_notification.id] || [],
        });
        setShowModal(true);
        setCreate(false);
    }

    const handleClickRemove = (row) => {
        console.log('row.id', row.id)
        setSelectedUserId(row.id);
        setShowModalDelete(true)
    }

    if (shouldShowActions) {
        cols.push({
            title: 'Acciones', internal: '', code: (row, data) => {
                return (
                    <>
                        <FontAwesomeIcon color={'#6A86C8'} icon={faPenToSquare} size={'lg'} style={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)} />
                        <FontAwesomeIcon color={'#6A86C8'} icon={faTrash} size={'lg'} style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={() => handleClickRemove(row)} />
                    </>
                )
            }
        })
    }

    useEffect(() => {
        document.title = 'Notificaciones | Roster';

        axiosInstance.get('typenotifications/').then((response) => {
            setTypeNotifications(response.data);
        });
    }, []);

    useEffect(() => {
        if (searchTerm.length > 3) {
            const params = { dni: searchTerm, users_permissions_roles: true };
            axiosInstance.get('user/', { params }).then((response) => {
                setSearchedUsers(response.data?.results);
            });
        }
    }, [searchTerm])

    useEffect(() => {
        if (!showModal) {
            setForm({});
        }
    }, [showModal]);

    const submit = () => {
        if (!form['user_id'] || !form['type_notifications']) {
            toast('Por favor, complete todos los campos obligatorios', ToastTypes.ERROR);
            return;
        }

        axiosInstance.patch(`user/${form.user_id}/`, {
            type_notifications: [{
                type_notification: form.type_notifications[0],
                active: form.status,
            }],
        }).then((response) => {
            if (response.status !== 200) {
                toast(`Error al ${create ? 'crear' : 'editar'} el usuario`, ToastTypes.ERROR);
                return;
            }
            setShowModal(false);
            toast(`Usuario ${create ? 'crear' : 'editar'} exitosamente`, ToastTypes.SUCCESS);
            window.location.reload();
        }).catch((error) => {
            console.log('error:notification.js:submit()', error);
        });
    }

    const deleteNotification = () => {
        if (!selectedUserId) {
            toast('Por favor, debe seleccionar un usuario', ToastTypes.ERROR);
            return;
        }

        axiosInstance.patch(`user/${selectedUserId}/`, {
            type_notifications: [],
        }).then((response) => {
            if (response.status !== 200) {
                toast('Error al eliminar la notificación', ToastTypes.ERROR);
                return;
            }
            setShowModalDelete(false);
            setSelectedUserId(null);
            toast('Notificación eliminada correctamente', ToastTypes.SUCCESS);
            window.location.reload();
        }).catch((error) => {
            console.log('error:notification.js:deleteNotification()', error);
        });
    };

    return (
        <div>
            <Sidebar selected={ScreenType.SETTINGS} selectedSubmenu={SettingsScreenType.NOTIFICATIONS} />

            {showModal &&
                <Modal title={'Notificación'} subtitle={create ? 'Nuevo' : 'Editar'} close={() => setShowModal(false)}>
                    {create &&
                        <p>
                            Asigne un tipo de notificación a un usuario del sistema, puede ver la lista de usuarios en el modulo de
                            Usuarios y Permisos
                        </p>
                    }
                    <div style={{ textAlign: 'left' }}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.requiredInput}>DNI</div>
                                {
                                    create ?
                                        <DNISelect
                                            value={form['user_id']}
                                            options={searchedUsers?.map((user) => ({ value: user.id, name: user.dni }))}
                                            onChange={(selectedId) => {
                                                const selectedUser = searchedUsers.find(user => user.id === selectedId[0]);
                                                if (selectedUser) {
                                                    setForm({
                                                        ...form,
                                                        dni: selectedUser.dni,
                                                        user_id: selectedUser.id,
                                                        fullname: selectedUser.fullname,
                                                        email: selectedUser.email,
                                                        project_groups: selectedUser.project_groups_managed,
                                                        projects: selectedUser.projects_managed,
                                                    });

                                                }
                                            }}
                                            searchTerm={searchTerm}
                                            setSearchTerm={setSearchTerm}
                                        /> :
                                        <Text
                                            disabled={true}
                                            value={form['dni']}
                                            onChange={(val) =>
                                                setForm({ ...form, dni: val })
                                            }
                                        />
                                }
                            </div>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.requiredInput}>Estado</div>
                                <Switch value={form['status']} onChange={(value) => {
                                    setForm({ ...form, status: value });
                                }} />
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.inputName}>Apellidos y nombres</div>
                                <Text value={form['fullname']} disabled={true} />
                            </div>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.inputName}>Correo</div>
                                <Text value={form['email']} disabled={true} />
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.inputName}>Grupo de proyecto(s)</div>
                                <Text value={valuesArrayToString(form['project_groups'])} disabled={true} hasTitle={true} />
                            </div>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.inputName}>Proyecto(s)</div>
                                <Text value={valuesArrayToString(form['projects'])} disabled={true} hasTitle={true} />
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{ flexBasis: '45%' }}>
                                <div className={styles.requiredInput}>Tipo Notificación</div>
                                <Select
                                    value={form['type_notifications']}
                                    options={typeNotifications.map((type) => {
                                        return { value: type.id, name: type.name };
                                    })}
                                    onChange={(val) => {
                                        setForm({ ...form, type_notifications: val });
                                    }}
                                />
                            </div>
                        </div>
                        <ModalBottom submit={submit} />
                    </div>
                </Modal>
            }

            {showModalDelete &&
                <Modal title={'Eliminar notificación'} subtitle={'Confirmación'} close={() => setShowModalDelete(false)}>
                    <p>¿Estás seguro que quieres remover de las notificaciones a este usuario?</p>
                    <ModalBottomDelete
                        cancel={() => setShowModalDelete(false)}
                        submit={deleteNotification}
                    />
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Notificaciones'} menu={'Configuración'} />
                <Table
                    model={'user'}
                    cols={cols}
                    create={() => {
                        setShowModal(true);
                        setCreate(true);
                        setForm({});
                    }}
                    shouldShowActions={shouldShowActions}
                    customBehavior={{ name: 'users_with_notifications' }}
                />
            </div>
        </div>
    )
}