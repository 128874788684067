import React, {useContext, useEffect, useState} from "react";
import styles from './sidebar_button.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import RosterContext from "../../RosterContext";

export default function SidebarButton() {
    const {setSidebarOpen, sidebarOpen} = useContext(RosterContext);
    const [canShow, setCanShow] = useState(false);

    useEffect(() => {
        const { pathname } = window.location;
        setCanShow(pathname !== '/login' && pathname !== '/password_reset/mail' && pathname !== '/password_reset');
    }, []);

    return (
        <div className={styles.container} style={{
            left: sidebarOpen ? '250px' : '80px',
            display: canShow ? 'block' : 'none'
        }} onClick={() => {
            setSidebarOpen((prev) => !prev);
        }}>
            <FontAwesomeIcon icon={sidebarOpen ? faChevronLeft : faChevronRight} color={'#FFF'} size={'xs'}/>
        </div>
    )
}