// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_button_container__vL6kH {
    position: fixed;
    background-color: #1A2A4D;
    top: 40px;
    left: 80px;
    z-index: 999999999;
    justify-content: center;
    display: flex;
    padding: 21px 8px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    flex-direction: column;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar_button/sidebar_button.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;IACzB,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,6BAA6B;IAC7B,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,iCAAiC;IACjC,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".container {\n    position: fixed;\n    background-color: #1A2A4D;\n    top: 40px;\n    left: 80px;\n    z-index: 999999999;\n    justify-content: center;\n    display: flex;\n    padding: 21px 8px;\n    border-top-right-radius: 18px;\n    border-bottom-right-radius: 18px;\n    user-select: none;\n    cursor: pointer;\n    transition: left 0.3s ease-in-out;\n    flex-direction: column;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sidebar_button_container__vL6kH`
};
export default ___CSS_LOADER_EXPORT___;
