import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrando los componentes necesarios
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MirroredBar = ({ labels = [], valuesMob = [], valuesDemob = [] }) => {
  const negativeValuesDemob = valuesDemob.map((value) => value * -1);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Movilización',
        data: valuesMob,
        backgroundColor: 'rgba(106, 134, 200, 0.5)',
        borderColor: 'rgba(106, 134, 200, 1)',
        borderWidth: 1,
      },
      {
        label: 'Desmovilización',
        data: negativeValuesDemob,
        backgroundColor: 'rgba(188, 209, 63, 0.5)',
        borderColor: 'rgba(188, 209, 63, 1)',
        borderWidth: 1,
      },
    ],
  };

  const allValues = [...valuesMob, ...valuesDemob];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: 'Fecha',
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: 'Cantidad',
        },
        min: Math.max(...allValues) * 2 * -1,
        max: Math.max(...allValues) * 2
      },
    },
  };

  return (
    <div style={{ width: '100%', height: 'auto' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default MirroredBar;
