import React from "react";
import styles from './roster_loading.module.css';

export default function RosterLoading() {
    const [stage, setStage] = React.useState(1);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setStage(stage => stage === 4 ? 1 : stage + 1);
        }, 200);

        return () => {
            clearInterval(interval);
        }
    });

    return (
        <div className={styles.container}>
            <div className={'d-flex align-items-center justify-content-center py-3'}>
                <img src={`/misc/loading/stage_${stage}.png`} alt={'loading'} width={'45px'} height={'45px'}/>
            </div>
        </div>
    )
}