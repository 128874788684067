import React from "react";
import styles from "../../screens/settings/projects/projects.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

export default function ModalBottom({submit}) {
    return (
        <div className={'d-flex justify-content-between pt-3 align-items-center'}>
            <div className={styles.inputName}>* Campos Obligatorios</div>
            <div style={{flexBasis: '45%'}} className={'d-flex justify-content-end'}>
                <div onClick={submit} className={'d-flex align-items-center text-center'} style={{
                    backgroundColor: '#E4E4E4',
                    color: '#000000',
                    borderRadius: '6px',
                    padding: '6px 10px',
                    userSelect: 'none',
                    cursor: 'pointer'
                }}>
                    <FontAwesomeIcon icon={faSave} color={'#000000'} size={'lg'} className={'pe-2'}/>
                    <div style={{fontSize: '16px', fontWeight: '400'}}>Guardar</div>
                </div>
            </div>
        </div>
    )
}