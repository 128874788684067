import React from "react";
import styles from './change_password.module.css';
import {useNavigate} from "react-router-dom";

export default function ChangePasswordSuccess() {
    const navigate = useNavigate();

    const submit = () => {
        navigate('/login');
    }
    return (
        <div className={styles.container}>
            <div className={styles.straconLogo}>
                <img src={'/branding/stracon_white.png'} alt={'stracon'} height={'43px'}/>
            </div>
            <div className={styles.innerContainer}>
                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>

                <div>Contraseña reestablecida correctamente</div>

                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>
            </div>

            <div className={'mt-4 d-flex justify-content-center align-items-center'}>
                <div style={{
                    flexBasis: '25%', textAlign: 'left',
                    color: '#ffffff',
                    fontSize: '16px',
                }}>
                    <div>Tu contraseña ha sido reestablecida correctamente.</div>
                    <div className={'mt-4'}/>

                    <div style={{color: 'rgba(255,255,255,0.7)'}}>Retorna  a la pantalla de Login para acceder a tu cuenta con tu nueva contraseña.</div>
                    <div className={'mt-4'}/>

                    <div className={'pt-4'}>
                        <div style={{
                            backgroundColor: '#F32735', padding: '10px 25px', borderRadius: '43px', cursor: 'pointer',
                            fontSize: '16px', textAlign: 'center',
                            fontWeight: '500'
                        }} onClick={() => submit()}>Volver</div>
                    </div>
                </div>
            </div>
        </div>
    )
}