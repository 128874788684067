import React, {useState} from "react";
import styles from "./text.module.css";

export default function TextPassword({ value, onChange, className, style, disabled, placeholder }) {
    const [focused, setFocused] = useState(false);


    return (
        <div className={`${className} ${styles.container}`} style={{
            backgroundColor: disabled ? '#F1F1F1' : '',
        }}>
            <input style={{
                color: disabled ? '#9B9B9B' : '#323232',
                cursor: disabled ? 'not-allowed' : 'text',
                style
            }} type="password" placeholder={placeholder} disabled={disabled} value={value} onChange={(event) => onChange(event.target.value)}/>
        </div>
    )
}