import React, {useEffect, useState, useContext} from "react";
import RosterContext from "../../../RosterContext";
import { PermissionType } from "../../../PermissionType";
import Sidebar from "../../../components/sidebar/sidebar";
import PageTitle from "../../../components/page_title/page_title";
import Table from "../../../components/table/table";
import styles from './projects.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faSave} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/modal/modal";
import {Select} from "../../../ajonjolib/inputs/ajonjolinput";
import {axiosInstance} from "../../../AxiosInstance";
import Switch from "../../../ajonjolib/inputs/switch/switch";
import { ScreenType, SettingsScreenType } from "../../../ScreenType";

export default function Projects() {
    const [showModal, setShowModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [form, setForm] = useState({});
    const {roleManager} = useContext(RosterContext);

    const shouldShowActions = roleManager.getSettingsRosterDateLimitPermission() == PermissionType.WRITE;
    
    const cols = [
        {title: 'Estado', internal: 'active', code: (row, data) => {
                return (
                    <div style={{
                        backgroundColor: data ? '#88D36E' : '#bdbdbd',
                        borderRadius: '50%', width: '15px', height: '15px',
                    }}/>
                )
            }},
        {title: 'Proyecto', internal: 'name', altBackground: true},
        {title: 'Fecha Límite de Carga', internal: 'day_limit', code: (row, data) => {
            return data + "/mes";
            }},
    ];

    if (shouldShowActions) {
        cols.push(
            {title: 'Acciones', internal: 'roster_limit_date', code: (row, data) => {
                return (
                    <FontAwesomeIcon icon={faPenToSquare} size={'lg'} color={'#6A86C8'} style={{cursor: 'pointer'}} onClick={() => {
                        setShowModal(true);
                        setForm(row);
                    }}/>
                )
                }
            });
        }
        
    useEffect(() => {
        document.title = "Proyectos";

        axiosInstance.get('project').then((response) => {
            setProjects(response.data.results);
        });

    }, [])

    const submit = () => {
        axiosInstance.put(`project/${form.id}/`, form).then((response) => {
            setShowModal(false);
            window.location.reload();
        });
    }

    return (
        <div>
            <Sidebar selected={ScreenType.SETTINGS} selectedSubmenu={SettingsScreenType.LIMIT_DATES}/>
            {showModal &&
                <Modal title={'Proyectos'} subtitle={'Editar'} close={() => setShowModal(false)}>
                    <div style={{textAlign: 'left'}}>
                        <div className={'d-flex justify-content-between mb-3'}>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Nombre de Proyecto</div>
                                <Switch value={form['active']} onChange={(val) => {
                                    setForm({...form, active: val});
                                }}/>
                            </div>
                            <div style={{flexBasis: '45%'}}>
                                <div className={styles.requiredInput}>Fecha de Carga</div>
                                <Select value={form['day_limit']} options={Array.from(Array(31).keys()).map((value) => {return {value: value + 1, name: (value + 1).toString()}})} onChange={(value) => {
                                    setForm({...form, day_limit: value[0]});
                                }}/>
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between pt-3 align-items-center'}>
                            <div className={styles.requiredInput}>Campos Obligatorios</div>
                            <div style={{flexBasis: '45%'}} className={'d-flex justify-content-end'}>
                                <div onClick={() => submit()} className={'d-flex align-items-center text-center'} style={{
                                    backgroundColor: '#E4E4E4',
                                    color: '#000000',
                                    borderRadius: '6px',
                                    padding: '6px 10px',
                                    userSelect: 'none',
                                    cursor: 'pointer'
                                }}>
                                    <FontAwesomeIcon icon={faSave} color={'#000000'} size={'lg'} className={'pe-2'}/>
                                    <div style={{fontSize: '16px', fontWeight: '400'}}>Guardar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            <div className={'mainContainer'}>
                <PageTitle title={'Fecha Límite de Roster'} menu={'Configuración'}/>
                <Table model={'project'} cols={cols} withIndex={true}/>
            </div>
        </div>
    )
}